import NewAccount from "../../teleporthq/src/views/new-account";
import { Helmet } from 'react-helmet'
import { Button, Image, TextField, SelectField, CheckboxField } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import { Column, Img, Line, List, Row, Stack, Text } from "components2";
import logo from "../../assets/images/logo.svg";

const inputStyle = {
  backgroundColor: '#F8F8F9',
  borderRadius: '10px',
  border: '0px solid',
};

const NewAccountPage = () => {
const HeaderView=()=>{
  return(
  <header className="sticky top-0 z-50 bg-gray_900 sm:w-[100%] w-[100%]">
        <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%] sm:flex-col md:flex-col p-5">
                <Stack className="bg-cover bg-no-repeat ">
                  <img src={logo} alt="Logo"className="h-[60px] "   />
                </Stack>
     
       
          <Row className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-start justify-center sm:mx-[0] sm:px-[0] sm:w-[100%] md:mx-[0] md:px-[0] md:w-[100%] w-[56%]">
            <Text className="font-medium mb-[1px] text-white_A700 w-[auto] bold cursor-pointer "
              variant="body6">
               Basic
            </Text>
            <Text className="font-small cursor-pointer mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto] "
              variant="body6"
            >
              Connectivity
            </Text>
            <Text className="font-small cursor-pointer mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]  "
              variant="body6">
              Data
            </Text>
            <Text className="font-small  cursor-pointer mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto] "
              variant="body6"
            >
             Financial
            </Text>
            <Text className="font-small cursor-pointer mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto] "
              variant="body6"
            >
             Summary
            </Text>
          </Row>
        </Row>
      </header>
)
}
const TextView=()=>{
  return(
      <div className="flex w-[50%] md:w-[100%] sm:w-[100%] flex-col p-10" >
              <span className="font-semibold text-xl pb-4 font-poppins">
             Empower your organization to thrive
              </span>
              <p className="font-light text-[18px] pb-4 font-poppins">
             Unlock your potential with our AI-powered cloud platform. Elevate your success as our cutting-edge technology streamlines tasks, predicts trends, and delivers actionable insights. Experience innovation that propels your goals forward, effortlessly. Your journey to success, empowered by AI, starts here. Help your organization reach its full potential by relying on an integrated and open cloud platform that spans four critical areas—security, infrastructure and data insights.
              </p>
          
            {/* </div>
          </div> */}

            <span className="font-semibold text-xl pb-4 font-poppins">
             Choose your partner:
              </span>
      
              <p className="font-light text-[18px] pb-4 font-poppins">
              Selecting the ideal cloud solution is a pivotal decision. Opt for the preferred hosting partner to ensure your platform's success, and conveniently choose your preferred region for an optimized experience.
              </p>


   <span className="font-semibold text-xl pb-4 font-poppins">
              Instance name
              </span>
      
              <p className="font-light text-[18px] pb-4 font-poppins">
       Please provide a unique instance name to personalize your experience. Remember, once set, the instance name remains unchanged.
              </p>


   <span className="font-semibold text-xl pb-4 font-poppins">
        Terms & Conditions
              </span>
      
              <p className="font-light text-[18px] pb-0 font-poppins">
     Your acceptance of our standard Terms and Conditions is appreciated. Feel free to review them by clicking here.
              </p>

      
          
          </div>
  )
}

const ImageAndOptions=()=>{
  return(
    <div className="flex w-[50%] md:w-[100%] sm:w-[100%] flex-col   p-10">
      <img     src="/Group 1000001253.svg"
      
      className="w-[85%] h-[85%] sm:w-[100%] sm:h-[100%]  md:w-[100%] md:h-[100%]"
      />

         <div >
          
                <div className="mb-4">
                  <strong>Choose your partner:</strong>
                </div>
                <div className="">
                  <SelectField

                    inputStyles={inputStyle} >
                    <option value="Amazon AWS" selected>Amazon AWS</option>
                    <option value="Microsoft Azure">Microsoft Azure</option>
                    <option value="Google">Google</option>
                  </SelectField>
                </div>
                <div className="flex mt-4 ml-0 mb-4">
                  <TextField
                    placeholder="Instance name"
                    label={<strong>Instance name:</strong>}
                    errorMessage="There is an error"
                    inputStyles={inputStyle}
                    className="w-full"
                  />
                </div>
                <CheckboxField
                  label="I accept terms and conditions of data simpl"
                  name="subscribe"
                  inputStyles={inputStyle}
                  value="yes"
                />


                <div className="flex mt-4 ml-0">
                  <Link to="/newaccount">
                    <Button
                      variation="secondary"
                      id="back"
                      type="button"
                      className="mr-2"
                    >
                      Back
                    </Button>
                  </Link>
                  <Link to="/financial">
                    <Button
                      variation="secondary"
                      id="next"
                      type="button"
                    >
                      Next
                    </Button>
                  </Link>
                </div>

              </div>
   
    </div>
  )
}
  return (

<div className="">
 {HeaderView()}
 <div 
 className="flex flex-row sm:flex-col-reverse md:flex-col-reverse "
 >
  {ImageAndOptions()}
 {TextView()}
 </div>
</div> 

  );

};
export default NewAccountPage;