import React, { useState } from "react";
import { Expander, ExpanderItem } from "@aws-amplify/ui-react";
import logo from "../../assets/images/logo.svg";
import { Button, Column, Img, Line, List, Row, Stack, Text } from "components2";
import SpecialComponent from "components/SpecialComponent/SpecialComponent";
import BusinessDetailComponent from "components/BusinessDetailComponent/BusinessDetailComponent";
import IntroComponent from "components/IntroComponent/IntroComponent";
import JobsComponent from "components/JobsComponent/JobsComponent";
import TextInput from "components/TextInput/TextInput";
import axios from "axios";
import './Home.css'
const HomePage = () => {
  const [userName,setUserName]=useState('')
  const[userEmail,setUserEmail]=useState('')
    const[userWorking,setUserWorking]=useState('')
        const[userMessage,setUserMessage]=useState('')
    
  function handleNavigate() {
    window.location.href = "/impressum";
  }

  function handleNavigateWorkspace() {
    window.location.href = "/workspace";
  }

  function handleNavigateAmbition() {
    window.location.href = "#ambition";
  }

  function handleNavigateProblem() {
    window.location.href = "#problem";
  }

  function handleNavigateBenefits() {
    window.location.href = "#benefits";
  }

  function handleNavigateCase() {
    window.location.href = "#case";
  }

  function handleNavigateTrust() {
    window.location.href = "#trust";
  }

  function handleNavigateCareer() {
    window.location.href = "#career";
  }

  function handleNavigateStarted() {
    window.location.href = "/newaccount";
  }

  function handleEmailCareer() {
    window.location.href = "mailto:career@datasimpl.cloud";
  }

  function handleEmailSales() {
    window.location.href = "mailto:sales@datasimpl.cloud";
  }
  const ProblemandopportunityButtons=[
    "Re-occurring challenges with data & cloud​",
"Data Architecture","Expensive support","IT (Cloud) architecture","Business complexity"
,"Enterprise integration","Lack of resources"
,"Time 2 Market","Expensive and risky projects","Lack continuous improvement"]

const imageData=["images/astteria.svg",
"images/annoto.svg",
"images/allot.svg",
"images/align.svg",
"images/Verint.svg",
"images/trackerDetect.svg",
"images/supplant.svg",]

var [careers,setCareers]=useState([
  {
  title:"Senior Software Engineer",
description:"Karlsruhe, Berlin, remote The senior software engineer should support the founders to set up the foundation of the future data simpl architecture and develop an MVP. In particular, he/she is responsible to design, build and test different AWS infrastructure components.",
Competenceprofile:[
  "Proven experience in design/building of data-driven infrastructure",
 "Proven experience in cloud technologies (AWS)" ,
 "Proven experience in developing API (e.g. fastAPI), streaming (e.g. Kafka), infrastructure (e.g.Fargate), IAM (Cognito), monitoring",
  "Proven experience in developing Python Proven experience in CICD and testing and debugging of code"],
showCompetence:false
},
  {
  title:"UI Expert",
description:"Karlsruhe, Berlin, remote The UI expert should support the founders to set up the foundation of the future data harmony customer UI and website. In particular, he/she is responsible to design, build and test customer facing components.",
Competenceprofile:["Proven experience in design/building of data-driven infrastructure",
 "Proven experience in cloud technologies (AWS)" ,
 "Proven experience in developing API (e.g. fastAPI), streaming (e.g. Kafka), infrastructure (e.g.Fargate), IAM (Cognito), monitoring",
  "Proven experience in developing Python Proven experience in CICD and testing and debugging of code"],
showCompetence:false
},
  {
  title:"Data Science Engineer",
description:"The UI expert should support the founders to set up the foundation of the future data simpl customer UI and website. In particular, he/she is responsible to design, build and test customer facing components.",
Competenceprofile:["Proven experience in design/building of data-driven infrastructure",
 "Proven experience in cloud technologies (AWS)" ,
 "Proven experience in developing API (e.g. fastAPI), streaming (e.g. Kafka), infrastructure (e.g.Fargate), IAM (Cognito), monitoring",
  "Proven experience in developing Python Proven experience in CICD and testing and debugging of code"],
showCompetence:false
},
  {
  title:"Working Student",
description:"The UI expert should support the founders to set up the foundation of the future data simpl customer UI and website. In particular, he/she is responsible to design, build and test customer facing components.",
Competenceprofile:["Proven experience in design/building of data-driven infrastructure",
 "Proven experience in cloud technologies (AWS)" ,
 "Proven experience in developing API (e.g. fastAPI), streaming (e.g. Kafka), infrastructure (e.g.Fargate), IAM (Cognito), monitoring",
  "Proven experience in developing Python Proven experience in CICD and testing and debugging of code"],
showCompetence:false
},

])

  const handleSubmit = async () => {
//  var formData = {
//     // name:userName,
//     email: userEmail,
//     // workingOn: userWorking,
//     // message: userMessage,
//   }

//     const formUrl = "https://formspree.io/f/mwkdojwg";

//     try {
//       const response = await axios.post(formUrl, formData);
//       console.log("Form submitted successfully:", response);
//       // Optionally, you can show a success message to the user
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       // Optionally, you can show an error message to the user
//     }
  };

const MainView=()=>{
  return(
      <>
      <Column className="bg-white_A700 flex flex-col font-montserrat items-center justify-start mx-[auto] w-[100%]">
        <header className="sticky top-0 z-50 bg-gray_900 sm:w-[100%] w-[100%]">
          <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%]">
            <Stack className="h-[80px] relative w-[15%]">
              <Column className="absolute flex flex-col items-center justify-start w-[100%]">
                <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center w-[100%]">
                  <Stack className="bg-cover bg-no-repeat h-[60px] ml-[150px] sm:p-[1px] md:p-[20px] p-[5px] relative w-[40%] ">
                    <img src={logo} alt="Logo" height="40px" />
                  </Stack>
                </Row>
              </Column>
            </Stack>
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-start justify-center sm:mx-[0] sm:px-[0] sm:w-[100%] w-[56%]">
              <Text className="cursor-pointer font-medium mb-[1px] text-white_A700 w-[auto] hover:font-bold"
                variant="body6"
                onClick={() => {
                  handleNavigateAmbition();
                }}
              >
                Our ambition
              </Text>
              <Text className="cursor-pointer font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]  hover:font-bold"
                variant="body6"
                onClick={() => {
                  handleNavigateProblem();
                }}
              >
                Problem
              </Text>
              <Text className="cursor-pointer font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]  hover:font-bold"
                variant="body6"
                onClick={() => {
                  handleNavigateBenefits();
                }}
              >
                Benefits
              </Text>
              <Text className="cursor-pointer font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]  hover:font-bold"
                variant="body6"
                onClick={() => {
                  handleNavigateCase();
                }}
              >
                Business Case
              </Text>
              <Text className="cursor-pointer font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]  hover:font-bold"
                variant="body6"
                onClick={() => {
                  handleNavigateTrust();
                }}
              >
                Trust
              </Text>
              <Text
                className="cursor-pointer font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]  hover:font-bold"
                variant="body6"
                onClick={() => {
                  handleNavigateCareer();
                }}>
                Career
              </Text>
            </Row>
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-center justify-between sm:mx-[0] sm:px-[0] sm:w-[100%] w-[18%] ">
              <Img
                src="images/img_location.svg"
                className="cursor-pointer sm:h-[13px] md:h-[17px] h-[32px] max-w-[100%] sm:w-[12px] md:w-[16px] w-[32px]  hover:font-bold mr-[20px]"
                alt="location"
                onClick={() => {
                  handleNavigateWorkspace();
                }}
              />
              <Button
                className="cursor-pointer font-medium min-w-[77%] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content] mr-[150px]"
                size="lg"
                variant="OutlineBlue500"
                onClick={() => {
                  handleNavigateStarted();
                }}
              >
                Get started
              </Button>
            </Row>
          </Row>
        </header>
        <Column className="bg-cover bg-no-repeat flex flex-col items-end justify-start sm:p-[15px] md:p-[25px] p-[50px] w-[100%]"
          style={{ backgroundImage: "url('images/img_group4.svg')" }}>
          <Column className="flex flex-col justify-start max-w-[1536px] mb-[116px] sm:mb-[46px] md:mb-[59px] ml-[auto] md:mr-[6px] mr-[auto] sm:mx-[0] sm:pl-[15px] sm:pr-[15px] sm:px-[0] w-[100%]">
            <Stack className="h-[641px] sm:mt-[22px] md:mt-[28px] mt-[56px] relative w-[100%]">
              <Row className="absolute bg-cover bg-no-repeat flex flex-row md:flex-wrap sm:flex-wrap items-start justify-center sm:mx-[0] sm:p-[15px] md:p-[17px] p-[33px] right-[0] sm:w-[100%] w-[72%]"
                style={{ backgroundImage: "url('images/img_group5.svg')" }}>
                <Img
                  src="images/img_group.png"
                  className="max-w-[100%] ml-[149px] sm:ml-[59px] md:ml-[76px] w-[8%]"
                  alt="Group"
                />
                <Stack className="h-[425px] sm:mb-[20px] md:mb-[26px] mb-[52px] sm:ml-[19px] md:ml-[25px] ml-[49px] md:mr-[106px] mr-[206px] sm:mr-[82px] sm:mt-[38px] md:mt-[49px] mt-[96px] relative sm:w-[100%] w-[54%]">
                  <Column className="absolute backdrop-opacity-[0.5] bg-gradient  blur-[123.00px] flex flex-col justify-start md:p-[36px] p-[70px] sm:px-[15px] sm:py-[27px] rounded-radius207 top-[3%] w-[100%]">
                    <div className="backdrop-opacity-[0.5] bg-gradient1  blur-[123.00px] sm:h-[107px] md:h-[138px] h-[267px] sm:mb-[2px] md:mb-[3px] mb-[6px] md:ml-[12px] ml-[25px] sm:ml-[9px] rounded-radius50 sm:w-[106px] md:w-[137px] w-[267px]"></div>
                  </Column>
                  <Img src="images/img_stack.svg"
                    className="absolute h-[391px] left-[9%] max-w-[100%] top-[0] sm:w-[100%] w-[75%]"
                    alt="stack"
                  />
                </Stack>
              </Row>
              <Column className="absolute flex flex-col h-[max-content] inset-y-[0] justify-start left-[0] sm:mx-[0] my-[auto] sm:px-[0] sm:w-[100%] w-[36%]">
                <Text className="font-poppins text-white_A700 tracking-ls09 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  as="h1"
                  variant="h1">
                  data simpl
                </Text>
                <Text className="font-light font-rubik leading-[170.00%] sm:mt-[11px] md:mt-[14px] mt-[28px] text-white_A700 w-[100%]"
                  variant="body6">
                  Enable everyone to integrate their data to offer data products
                  easily and effortlessly based on state-of-the-art AI
                </Text>
                <Button className="cursor-pointer font-medium font-poppins min-w-[32%] sm:mt-[11px] md:mt-[15px] mt-[30px] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                  variant="FillBlue500">
                  Get started
                </Button>
              </Column>
            </Stack>
          </Column>
        </Column>
        <Column className="flex flex-col font-rubik justify-start max-w-[1280px] ml-[auto] mr-[auto] mt-[126px] sm:mt-[50px] md:mt-[65px] sm:pl-[15px] sm:pr-[15px] w-[100%]">
          <Column className="flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[52%]">
            <Text className="font-bold font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              as="h2"
              variant="h2">
              <div id="ambition">
                Our ambition
              </div>
            </Text>
            <Text className="font-light font-rubik mt-[16px] sm:mt-[6px] md:mt-[8px] text-gray_901 w-[auto]"
              variant="body6">
              Become the platform to deliver data as a service without lead time
              and implementation
            </Text>
          </Column>
          <Column className="bg-gray_900 flex flex-col items-center justify-start sm:mt-[25px] md:mt-[33px] mt-[65px] sm:p-[15px] md:p-[25px] p-[50px] rounded-radius32 w-[100%]">
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-start justify-between sm:px-[0] w-[100%]">
              <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[18%]">
                <Text className="text-white_A700 tracking-ls052 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  as="h3"
                  variant="h3">
                  15 min
                </Text>
                <Text className="font-normal mt-[18px] sm:mt-[7px] md:mt-[9px] not-italic text-white_A700 w-[auto]"
                  variant="body4">
                  Integrate your data
                </Text>
              </Column>
              <Line className="bg-gradient2  h-[106px] sm:h-[43px] md:h-[55px] w-[1px]" />
              <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[18%]">
                <Text className="text-white_A700 tracking-ls052 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  as="h3"
                  variant="h3">
                  15 min
                </Text>
                <Text
                  className="font-normal mt-[18px] sm:mt-[7px] md:mt-[9px] not-italic text-white_A700 w-[auto]"
                  variant="body4">
                  Create a data product
                </Text>
              </Column>
              <Line className="bg-gradient2  h-[106px] sm:h-[43px] md:h-[55px] w-[1px]" />
              <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[16%]">
                <Text className="text-white_A700 tracking-ls052 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  as="h3"
                  variant="h3">
                  15 min
                </Text>
                <Text className="font-normal mt-[16px] sm:mt-[6px] md:mt-[8px] not-italic text-white_A700 w-[auto]"
                  variant="body4">
                  Visualize your data
                </Text>
              </Column>
              <Line className="bg-gradient2  h-[106px] sm:h-[43px] md:h-[55px] w-[1px]" />
              <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[18%]">
                <Text className="text-white_A700 tracking-ls052 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  as="h3"
                  variant="h3">
                  15 min
                </Text>
                <Text className="font-normal mt-[18px] sm:mt-[7px] md:mt-[9px] not-italic text-white_A700 w-[auto]"
                  variant="body4"
                >
                  Create an event
                </Text>
              </Column>
            </Row>
          </Column>
        </Column>
        <Column className="flex flex-col font-rubik justify-start max-w-[1280px] ml-[auto] mr-[auto] mt-[160px] sm:mt-[63px] md:mt-[82px] sm:pl-[15px] sm:pr-[15px] w-[100%]">
          <Column className="flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[62%]">
            <Text className="font-bold font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              as="h2"
              variant="h2">
              <div id="problem">
                Problem and opportunity
              </div>
            </Text>
            <Text className="font-light font-rubik mt-[16px] sm:mt-[6px] md:mt-[8px] text-gray_901 w-[auto]"
              variant="body6">
              We are developing the solution to overcome these challanges
            </Text>
          </Column>
          <List className="md:gap-[12px] gap-[25px] sm:gap-[9px] grid min-h-[auto] sm:mt-[25px] md:mt-[33px] mt-[64px] w-[100%]"
            orientation="vertical">
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%]">
              <Button className="cursor-pointer font-medium min-w-[33%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                Re-occurring challenges with data & cloud​
              </Button>
              <Button className="cursor-pointer font-medium min-w-[33%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                Data Architecture
              </Button>
              <Button className="cursor-pointer font-medium min-w-[33%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                Expensive support
              </Button>
            </Row>
            <Line className="self-center w-[100%] justify-between items-center flex-row flex" />
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%]">
              <Button className="cursor-pointer font-medium min-w-[24%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                IT (Cloud) architecture
              </Button>
              <Button className="cursor-pointer font-medium min-w-[24%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                Business complexity
              </Button>
              <Button className="cursor-pointer font-medium min-w-[24%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                Enterprise integration
              </Button>
              <Button className="cursor-pointer font-medium min-w-[24%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                Lack of resources
              </Button>
            </Row>
            <Line className="self-center w-[100%] justify-between items-center flex-row flex" />
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%]">
              <Button className="cursor-pointer font-medium min-w-[33%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                Time 2 Market
              </Button>
              <Button className="cursor-pointer font-medium min-w-[33%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                Expensive and risky projects
              </Button>
              <Button className="cursor-pointer font-medium min-w-[33%] text-[17px] text-bluegray_900 text-center w-[max-content]">
                Lack continuous improvement
              </Button>
            </Row>
          </List>
        </Column>
        <Stack className="font-rubik h-[2154px] mt-[157px] sm:mt-[62px] md:mt-[81px] relative w-[100%]">
          <Img
            src="images/img_rectangle.svg"
            className="absolute h-[1032px] max-w-[100%] right-[5%] rounded-radius403 top-[18%] sm:w-[100%] w-[56%]"
            alt="Rectangle"
          />
          <div className="absolute backdrop-opacity-[0.5] bg-gradient  blur-[456.00px] bottom-[12%] sm:h-[183px] md:h-[236px] h-[456px] left-[10%] rounded-radius50 sm:w-[182px] md:w-[235px] w-[456px]"></div>
          <Stack className="absolute h-[2154px] w-[100%]">
            <Column className="absolute bg-cover bg-no-repeat bottom-[1%] flex flex-col items-center justify-start w-[100%]"
              style={{ backgroundImage: "url('images/img_group4.svg')" }}
            >
              <Column className="bg-cover bg-no-repeat flex flex-col items-center justify-start md:p-[158px] sm:p-[15px] p-[307px] w-[100%]"
                style={{
                  backgroundImage: "url('images/img_group117127486.svg')"
                }}
              >
                <Column className="flex flex-col items-center justify-start sm:mb-[224px] md:mb-[289px] mb-[561px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[99%]">
                  <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[57%]">
                    <Text className="font-bold font-poppins text-white_A700 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                      as="h2"
                      variant="h2">
                      What makes us special
                    </Text>
                    <Text className="font-light font-rubik leading-[170.00%] mt-[13px] sm:mt-[5px] md:mt-[6px] text-center text-white_A700 w-[100%]"
                      variant="body6"
                    >
                      Is in the early founding phase to become the data platform
                      to deliver data as a service without lead time and
                      implementation.
                    </Text>
                  </Column>
                  <List
                    className="sm:gap-[21px] md:gap-[28px] gap-[55px] grid min-h-[auto] sm:mt-[35px] md:mt-[46px] mt-[90px] w-[100%]"
                    orientation="vertical">
                    <Stack className="h-[340px] relative w-[100%]">
                      <Row className="absolute bottom-[4%] flex flex-row md:flex-wrap sm:flex-wrap inset-x-[0] items-start justify-between sm:mx-[0] mx-[auto] sm:px-[0] sm:w-[100%] w-[94%]">
                        <div className="backdrop-opacity-[0.5] bg-teal_A700_75 blur-[45.00px] md:h-[117px] h-[226px] sm:h-[91px] mt-[10px] sm:mt-[3px] md:mt-[5px] rounded-radius113 w-[29%]"></div>
                        <Stack className="h-[226px] mb-[10px] sm:mb-[3px] md:mb-[5px] relative sm:w-[100%] w-[46%]">
                          <div className="absolute backdrop-opacity-[0.5] bg-teal_A700_75 blur-[45.00px] md:h-[117px] h-[226px] sm:h-[91px] left-[0] rounded-radius113 sm:w-[100%] w-[62%]"></div>
                          <div className="absolute backdrop-opacity-[0.5] bg-teal_A700_75 blur-[45.00px] md:h-[117px] h-[226px] sm:h-[91px] right-[0] rounded-radius113 sm:w-[100%] w-[62%]"></div>
                        </Stack>
                      </Row>
                      <div className="absolute backdrop-opacity-[0.5] bg-teal_A700_75 blur-[45.00px] md:h-[117px] h-[226px] sm:h-[91px] inset-y-[0] left-[19%] my-[auto] rounded-radius113 w-[27%]"></div>
                      <Row className="absolute flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%]">
                        <Column className="bg-gray_902 flex flex-col sm:mx-[0] sm:p-[15px] md:p-[37px] p-[72px] rounded-radius5937 sm:w-[100%] w-[49%]">
                          <Column className="flex flex-col justify-start md:ml-[2px] ml-[5px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[81%]">
                            <Button className="cursor-pointer font-normal min-w-[16%] not-italic text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              shape="RoundedBorder29"
                              size="sm"
                              variant="OutlineLightblueA400">
                              01
                            </Button>
                            <Text className="md:mt-[10px] mt-[20px] sm:mt-[7px] text-light_blue_A400 tracking-ls032 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                              variant="body1">
                              No lead-time
                            </Text>
                            <Text className="font-light leading-[170.00%] md:mt-[10px] mt-[20px] sm:mt-[7px] text-white_A700 w-[100%]"
                              variant="body6">
                              Data simpl delivers a full-scale integration, data
                              and dashboarding platform
                            </Text>
                          </Column>
                        </Column>
                        <Column className="bg-gray_902 flex flex-col sm:mx-[0] sm:p-[15px] md:p-[37px] p-[72px] rounded-radius5937 sm:w-[100%] w-[49%]">
                          <Column className="flex flex-col justify-start md:ml-[2px] ml-[5px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[81%]">
                            <Button className="cursor-pointer font-normal min-w-[17%] not-italic text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              shape="RoundedBorder29"
                              size="sm"
                              variant="OutlineTealA700">
                              02
                            </Button>
                            <Text className="md:mt-[10px] mt-[20px] sm:mt-[7px] text-teal_A700 tracking-ls032 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                              variant="body1">
                              Full integration
                            </Text>
                            <Text className="font-light leading-[170.00%] md:mt-[10px] mt-[20px] sm:mt-[7px] text-white_A700 w-[100%]"
                              variant="body6">
                              Data becomes the center of many ecosystems and
                              organizations
                            </Text>
                          </Column>
                        </Column>
                      </Row>
                    </Stack>
                    <Stack className="h-[340px] relative w-[100%]">
                      <div className="absolute backdrop-opacity-[0.5] bg-cyan_700_75 blur-[45.00px] bottom-[11%] md:h-[117px] h-[226px] sm:h-[91px] right-[16%] rounded-radius113 w-[29%]"></div>
                      <div className="absolute backdrop-opacity-[0.5] bg-cyan_700_75 blur-[45.00px] bottom-[11%] md:h-[117px] h-[226px] sm:h-[91px] right-[3%] rounded-radius113 w-[26%]"></div>
                      <div className="absolute backdrop-opacity-[0.5] bg-cyan_700_75 blur-[45.00px] bottom-[8%] md:h-[117px] h-[226px] sm:h-[91px] left-[5%] rounded-radius113 w-[29%]"></div>
                      <Row className="absolute flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%]">
                        <Column className="bg-gray_902 flex flex-col sm:mx-[0] sm:p-[15px] md:p-[37px] p-[72px] rounded-radius5937 sm:w-[100%] w-[49%]">
                          <Column className="flex flex-col justify-start md:ml-[2px] ml-[5px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[81%]">
                            <Button className="cursor-pointer font-normal min-w-[17%] not-italic text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              shape="RoundedBorder29"
                              size="sm"
                              variant="OutlineIndigoA700">
                              03
                            </Button>
                            <Text className="md:mt-[10px] mt-[20px] sm:mt-[7px] text-indigo_A700 tracking-ls032 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                              variant="body1">
                              Immediately available
                            </Text>
                            <Text className="font-light leading-[170.00%] md:mt-[10px] mt-[20px] sm:mt-[7px] text-white_A700 w-[100%]"
                              variant="body6">
                              We become the platform which is made for data
                              meshes and data products
                            </Text>
                          </Column>
                        </Column>
                        <Column className="bg-gray_902 flex flex-col sm:mx-[0] sm:p-[15px] md:p-[37px] p-[72px] rounded-radius5937 sm:w-[100%] w-[49%]">
                          <Column className="flex flex-col justify-start md:ml-[2px] ml-[5px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[81%]">
                            <Button className="cursor-pointer font-normal min-w-[17%] not-italic text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              shape="RoundedBorder29"
                              size="sm"
                              variant="OutlineCyan700">
                              04
                            </Button>
                            <Text className="md:mt-[10px] mt-[20px] sm:mt-[7px] text-cyan_700 tracking-ls032 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                              variant="body1">
                              Full customizability
                            </Text>
                            <Text className="font-light leading-[170.00%] md:mt-[10px] mt-[20px] sm:mt-[7px] text-white_A700 w-[100%]"
                              variant="body6">
                              Our competitors only offer components which
                              requires a development team
                            </Text>
                            <div id="benefits"></div>
                          </Column>
                        </Column>
                      </Row>
                    </Stack>
                  </List>
                </Column>
              </Column>
            </Column>
            <Stack className="absolute h-[724px] inset-x-[0] mx-[auto] rounded-radius5735 shadow-bs2 sm:w-[100%] w-[81%]">
              <div className="absolute backdrop-opacity-[0.5] bg-gradient3  blur-[123.00px] sm:h-[113px] md:h-[146px] h-[282px] left-[7%] rounded-radius141 top-[1300px] w-[25%]"></div>
              <Column className="absolute bg-cyan_600_b2 flex flex-col items-center justify-start sm:p-[15px] md:p-[30px] p-[60px] rounded-radius5735 top-[1300px] w-[100%]">
                <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-start mb-[1px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[89%]">
                  <Column className="flex flex-col justify-start sm:mt-[33px] md:mt-[43px] mt-[84px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[40%]">
                    <Text className="font-bold font-poppins leading-[120.00%] text-white_A700 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[100%]"
                      as="h2"
                      variant="h2">
                      data simpl benefits
                    </Text>
                    <Text className="font-light font-rubik leading-[170.00%] md:mt-[10px] mt-[20px] sm:mt-[7px] sm:mx-[0] text-white_A700 sm:w-[100%] w-[88%]"
                      variant="body4">
                      Our mission is to enable everyone to integrate their data
                      to offer data products easily and effortlessly based on
                      state-of-the-art AI. We are two founders with more than 20
                      years of experience in the energy industry and related
                      data projects.{" "}
                    </Text>
                  </Column>
                  <Img src="images/img_group117127486_603X763.svg"
                    className="max-w-[100%] sm:w-[100%] w-[61%]"
                    alt="Group117127486"
                  />
                </Row>
              </Column>
            </Stack>
          </Stack>
        </Stack>
        <Stack className="font-rubik h-[2301px] mt-[160px] sm:mt-[63px] md:mt-[82px] relative w-[100%]">
          <Column className="absolute bottom-[0] flex flex-col items-center justify-start w-[100%]">
            <Column className="bg-cover bg-no-repeat flex flex-col items-center justify-start w-[100%]"
              style={{ backgroundImage: "url('images/img_group9.svg')" }}>
              <Stack className="bg-cover bg-no-repeat h-[1557px] sm:pr-[15px] pr-[160px] md:pr-[82px] sm:py-[15px] py-[160px] md:py-[82px] relative w-[100%]"
                style={{ backgroundImage: "url('images/img_group10.svg')" }}>
                <Stack className="absolute bottom-[10%] h-[913px] sm:w-[100%] w-[84%]">
                  <Column className="absolute bottom-[0] flex flex-col items-center justify-start w-[100%]">
                    <Column className="flex flex-col justify-start w-[100%]">
                      <Img src="images/img_cubecopy.png"
                        className="max-w-[100%] w-[28%]"
                        alt="cubecopy"
                      />
                      <Stack className="h-[200px] sm:ml-[127px] md:ml-[165px] ml-[320px] sm:mt-[13px] md:mt-[17px] mt-[34px] relative sm:w-[100%] w-[80%]">
                        <div className="absolute backdrop-opacity-[0.5] bg-gradient3  blur-[123.00px] bottom-[20%] sm:h-[35px] md:h-[45px] h-[86px] left-[4%] rounded-radius43 w-[30%]"></div>
                        <Column className="absolute bg-cyan_600_b2 flex flex-col items-center justify-start sm:p-[15px] md:p-[26px] p-[52px] rounded-radius5937 w-[100%]">
                          <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between sm:mx-[0] sm:px-[0] sm:w-[100%] w-[94%]">
                            <Text className="font-poppins leading-[120.00%] sm:mx-[0] text-white_A700 tracking-ls04 md:tracking-ls1 sm:tracking-ls1 sm:w-[100%] w-[54%]"
                              as="h5"
                              variant="h5">
                              <span className="text-white_A700 text-[40px] font-light sm:text-[15px] md:text-[20px]">
                                If you’re ready to succeed,
                                <br />
                              </span>
                              <span className="text-white_A700 text-[40px] font-bold sm:text-[15px] md:text-[20px]">
                                we are ready to help!
                              </span>
                            </Text>
                            <Button
                              className="cursor-pointer font-medium font-rubik min-w-[23%] my-[15px] sm:my-[5px] md:my-[7px] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              variant="OutlineGray9003d">
                              Get free assessment
                            </Button>
                          </Row>
                        </Column>
                      </Stack>
                    </Column>
                  </Column>
                  <Img src="images/img_01chart1.svg"
                    className="absolute h-[542px] max-w-[100%] right-[5%] top-[0] sm:w-[100%] w-[71%]"
                    alt="01chartOne"
                  />
                </Stack>
              </Stack>
            </Column>
          </Column>
          <Column className="absolute flex flex-col inset-x-[0] items-center justify-start sm:mx-[0] mx-[auto] sm:px-[0] top-[0] sm:w-[100%] w-[67%]">
            <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[58%]">
              <Text className="font-bold font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                as="h2"
                variant="h2">
                <div id="case">
                  Business Case example
                </div>
              </Text>
              <Text className="font-light font-rubik sm:mt-[3px] md:mt-[4px] mt-[9px] text-bluegray_900 tracking-ls017 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                variant="body6">
                We offer up to 8 times more value-add without any lead time
              </Text>
            </Column>
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-start justify-between sm:mt-[35px] md:mt-[46px] mt-[90px] w-[100%]">
              <Column className="bg-white_A700 flex flex-col items-center justify-start sm:mx-[0] sm:p-[15px] md:p-[20px] p-[39px] rounded-radius32 shadow-bs3 sm:w-[100%] w-[38%]">
                <Column className="flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[96%]">
                  <Column className="flex flex-col justify-start w-[100%]">
                    <Text className="text-blue_500 tracking-ls024 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                      variant="body3">
                      Investment
                    </Text>
                    <Text className="font-light leading-[170.00%] mt-[10px] sm:mt-[3px] md:mt-[5px] text-gray_901 w-[100%]"
                      variant="body6">
                      Traditional approaches requires high initial investments
                      and lead time
                    </Text>
                  </Column>
                  <Line className="bg-blue_500_6c h-[1px] md:mt-[10px] mt-[20px] sm:mt-[7px] w-[100%]" />
                  <Column
                    className="flex flex-col justify-start mt-[19px] sm:mt-[7px] md:mt-[9px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[99%]">
                    <Text
                      className="text-blue_500 tracking-ls024 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                      variant="body3">
                      Lead time
                    </Text>
                    <Text className="font-light leading-[170.00%] mt-[10px] sm:mt-[3px] md:mt-[5px] text-gray_901 w-[100%]"
                      variant="body6">
                      On average only after 2 years first benefits of platform
                      can be used
                    </Text>
                  </Column>
                  <Line className="bg-blue_500_6c h-[1px] md:mt-[10px] mt-[20px] sm:mt-[7px] w-[100%]" />
                  <Column className="flex flex-col justify-start mt-[19px] sm:mt-[7px] md:mt-[9px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[95%]">
                    <Text className="text-blue_500 tracking-ls024 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                      variant="body3">
                      Break even
                    </Text>
                    <Text className="font-light leading-[170.00%] mt-[10px] sm:mt-[3px] md:mt-[5px] text-gray_901 w-[100%]"
                      variant="body6">
                      The break even is only reached after 5 years with
                      traditional approaches
                    </Text>
                  </Column>
                  <Line className="bg-blue_500_6c h-[1px] md:mt-[10px] mt-[20px] sm:mt-[7px] w-[100%]" />
                  <Column className="flex flex-col justify-start mt-[19px] sm:mt-[7px] md:mt-[9px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[94%]">
                    <Text className="text-blue_500 tracking-ls024 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                      variant="body3">
                      Data as a service
                    </Text>
                    <Text className="font-light leading-[170.00%] mt-[10px] sm:mt-[3px] md:mt-[5px] text-gray_901 w-[100%]"
                      variant="body6">
                      With harmony’s cloud solution advantages can be used
                      immediately
                    </Text>
                  </Column>
                  <Line className="bg-blue_500_6c h-[1px] md:mt-[10px] mt-[20px] sm:mt-[7px] w-[100%]" />
                  <Column className="flex flex-col justify-start mt-[19px] sm:mt-[7px] md:mt-[9px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[94%]">
                    <Text className="text-blue_500 tracking-ls024 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                      variant="body3">
                      Continously extended
                    </Text>
                    <Text className="font-light leading-[170.00%] mt-[10px] sm:mt-[3px] md:mt-[5px] text-gray_901 w-[100%]"
                      variant="body6">
                      After first use case have been configured, further use
                      cases can be added to the platform
                    </Text>
                  </Column>
                </Column>
              </Column>
              <Img src="images/img_group_472X704.svg"
                className="max-w-[100%] sm:mt-[22px] md:mt-[29px] mt-[57px] sm:w-[100%] w-[56%]"
                alt="Group One"
              />
            </Row>
          </Column>
        </Stack>
        <Column className="flex flex-col font-rubik items-center justify-start max-w-[1743px] ml-[auto] mr-[auto] mt-[160px] sm:mt-[63px] md:mt-[82px] sm:pl-[15px] sm:pr-[15px] w-[100%]">
          <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[47%]">
            <Text className="font-bold font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              as="h2"
              variant="h2">
              <div id="trust">
                Companies who trust us
              </div>
            </Text>
            <Text className="font-light font-rubik sm:mt-[3px] md:mt-[4px] mt-[9px] text-bluegray_900 tracking-ls017 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              variant="body6">
              We currently looking for partners who want to try our services as
              early customers with different benefits
            </Text>
          </Column>
        </Column>
        <Stack className="font-poppins h-[2031px] mt-[160px] sm:mt-[63px] md:mt-[82px] relative w-[100%]">
          <Img
            src="images/img_cubecopy_229X306.png"
            className="absolute h-[229px] left-[1%] max-w-[100%] top-[1%] w-[16%]"
            alt="cubecopy One"
          />
          <Column className="absolute bg-gray_900 flex flex-col items-center justify-start w-[100%]">
            <Stack className="h-[2031px] relative w-[100%]">
              <Stack className="absolute h-[2031px] w-[100%]">
                <Img src="images/img_group_1174X1920.png"
                  className="absolute h-[1174px] max-w-[100%] top-[0] w-[100%]"
                  alt="Group Two"
                />
                <Img src="images/img_group_1153X1920.png"
                  className="absolute bottom-[0] h-[1153px] max-w-[100%] w-[100%]"
                  alt="Group Three"
                />
              </Stack>
              <Row className="absolute flex flex-row md:flex-wrap sm:flex-wrap h-[max-content] inset-[0] items-start justify-center m-[auto] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[67%]">
                <Column className="flex flex-col items-center justify-end mt-[2px] sm:mx-[0] pt-[12px] sm:pt-[4px] md:pt-[6px] sm:px-[0] sm:w-[100%] w-[50%]">
                  <Column className="flex flex-col justify-start w-[100%]">
                    <Text className="font-poppins font-semibold text-white_A700 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                      as="h2"
                      variant="h2">
                      <div id="career">
                        Career
                      </div>
                    </Text>
                    <Text className="font-normal font-rubik sm:mt-[10px] md:mt-[13px] mt-[27px] not-italic text-white_A700 w-[auto]"
                      variant="body6">
                      We are looking for roles that are supporting
                      us in our early founding phase
                    </Text>
                  </Column>
                  {/* add career here */}
                  <Column className="flex flex-col items-center justify-start sm:mt-[35px] md:mt-[46px] mt-[90px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[99%]">
                    <Column className="bg-cyan_600_b2 flex flex-col items-center justify-start md:mt-[10px] mt-[20px] sm:mt-[7px] sm:p-[15px] md:p-[20px] p-[40px] rounded-radius42 w-[100%]">
                      <Column className="bg-cyan_600_b2 flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[97%]">
                        <Expander type="single" isCollapsible={true}>
                          <ExpanderItem title="Senior Software Engineer"
                                        className="bg-cyan_600_b2 font-bold sm:text-[24px] md:text-[26px] text-[28px] text-white_A700 tracking-ls028 md:tracking-ls1 sm:tracking-ls1 w-[100%]"
                                        value="item-1"
                                        placeholderClassName="text-white_A700">
                            <Text className="font-light leading-[170.00%] text-white_A700 w-[100%]"
                              variant="body6">
                              Karlsruhe, Berlin, remote <br />
                              The senior software engineer should support the
                              founders to set up the foundation of the future
                              data simpl architecture and develop an MVP. In
                              particular, he/she is responsible to design,
                              build and test different AWS infrastructure
                              components.
                              <br />
                              - Proven experience in design/building of data-driven infrastructure <br />
                              - Proven experience in cloud technologies (AWS) <br />
                              - Proven experience in developing API (e.g. fastAPI), streaming (e.g. Kafka),
                              infrastructure (e.g. Fargate), IAM (Cognito), monitoring <br />
                              - Proven experience in developing Python <br />
                              - Proven experience in CICD and testing and debugging of code <br />
                            </Text>
                            <Button className="cursor-pointer font-medium font-rubik min-w-[33%] md:mt-[10px] mt-[20px] sm:mt-[7px] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              variant="OutlineGray9003d"
                              onClick={() => {
                                handleEmailCareer();
                              }}
                            >
                              Apply now
                            </Button>
                          </ExpanderItem>
                        </Expander>
                      </Column>
                    </Column>
                    <Column className="bg-cyan_600_b2 flex flex-col items-center justify-start md:mt-[10px] mt-[20px] sm:mt-[7px] sm:p-[15px] md:p-[20px] p-[40px] rounded-radius42 w-[100%]">
                      <Column className="bg-cyan_600_b2 flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[97%]">
                        <Expander type="single" isCollapsible={true}>
                          <ExpanderItem title="UI Expert"
                                        className="bg-cyan_600_b2 font-bold sm:text-[24px] md:text-[26px] text-[28px] text-white_A700 tracking-ls028 md:tracking-ls1 sm:tracking-ls1 w-[100%]"
                                        value="item-1"
                                        placeholderClassName="text-white_A700">
                            <Text className="font-light leading-[170.00%] text-white_A700 w-[100%]"
                              variant="body6"
                            >
                              Karlsruhe, Berlin, remote <br />
                              The UI expert should support the founders to set up the foundation of the future data
                              harmony customer UI and website. In particular, he/she is responsible to design, build and
                              test customer facing components.
                              <br />
                              - Proven experience in design/building of UI and webpages <br />
                              - Proven experience in cloud technologies (e.g. AWS amplify) <br />
                              - Proven experience in React, Java Script, Figma <br />
                              - Proven experience in CICD and testing of code <br />
                            </Text>
                            <Button className="cursor-pointer font-medium font-rubik min-w-[33%] md:mt-[10px] mt-[20px] sm:mt-[7px] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              variant="OutlineGray9003d"
                              onClick={() => {
                                handleEmailCareer();
                              }}
                            >
                              Apply now
                            </Button>
                          </ExpanderItem>
                        </Expander>
                      </Column>
                    </Column>
                    <Column className="bg-cyan_600_b2 flex flex-col items-center justify-start md:mt-[10px] mt-[20px] sm:mt-[7px] sm:p-[15px] md:p-[20px] p-[40px] rounded-radius42 w-[100%]">
                      <Column className="bg-cyan_600_b2 flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[97%]">
                        <Expander type="single" isCollapsible={true}>
                          <ExpanderItem title="Data Science Engineer"
                                        className="bg-cyan_600_b2 font-bold sm:text-[24px] md:text-[26px] text-[28px] text-white_A700 tracking-ls028 md:tracking-ls1 sm:tracking-ls1 w-[100%]"
                                        value="item-1"
                                        placeholderClassName="text-white_A700">
                            <Text className="font-light leading-[170.00%] text-white_A700 w-[100%]"
                              variant="body6"
                            >
                              Karlsruhe, Berlin, remote <br />
                              The data science engineer should support the founders to set up the foundation of the data
                              science capability. In particular, he/she is responsible to design, build and test the
                              data driven part of our MVP.
                              <br />
                              - Proven experience in design/building data science solutions <br />
                              - Proven experience in cloud technologies (AWS) <br />
                              - Proven experience in developing of machine learning algorithms (classification, test
                              processing) <br />
                              - Proven experience in components like scikit-learn, Tensorflow, Keras, Pytorch,
                              Knime <br />
                              - Proven experience in developing Python <br />
                              - Proven experience in CICD and testing and debugging of code <br />
                            </Text>
                            <Button
                              className="cursor-pointer font-medium font-rubik min-w-[33%] md:mt-[10px] mt-[20px] sm:mt-[7px] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              variant="OutlineGray9003d"
                              onClick={() => {
                                handleEmailCareer();
                              }}
                            >
                              Apply now
                            </Button>
                          </ExpanderItem>
                        </Expander>
                      </Column>
                    </Column>
                    <Column className="bg-gray_902 flex flex-col items-center justify-start md:mt-[10px] mt-[20px] sm:mt-[7px] sm:p-[15px] md:p-[20px] p-[40px] rounded-radius42 w-[100%]">
                      <Column className="bg-gray_902 flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[97%]">
                        <Expander type="single" isCollapsible={true}>
                          <ExpanderItem title="Working student Software Engineer"
                                        className="bg-gray_902 font-bold sm:text-[24px] md:text-[26px] text-[28px] text-white_A700 tracking-ls028 md:tracking-ls1 sm:tracking-ls1 w-[100%]"
                                        value="item-1"
                                        placeholderClassName="text-white_A700">
                            <Text
                              className="font-light leading-[170.00%] text-white_A700 w-[100%]"
                              variant="body6"
                            >
                              Karlsruhe, Berlin, remote <br />
                              The student should support the founders to set up the foundation of the future
                              data simpl architecture and develop an MVP. We offer you to grow together with our company
                              and develop in a leader position.
                              <br />
                              - First experience in design/building of data-driven infrastructure <br />
                              - First experience in cloud technologies (AWS) is a plus<br />
                              - First experience in developing API (e.g. fastAPI), streaming (e.g. Kafka),
                              infrastructure (e.g. Fargate), IAM (Cognito), monitoring <br />
                              - Knowledge in developing Python <br />
                              - Proven experience in CICD and testing and debugging of code <br />
                            </Text>
                            <Button className="cursor-pointer font-medium font-rubik min-w-[33%] md:mt-[10px] mt-[20px] sm:mt-[7px] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              variant="OutlineGray9003d"
                              onClick={() => {
                                handleEmailCareer();
                              }}
                            >
                              Apply now
                            </Button>
                          </ExpanderItem>
                        </Expander>
                      </Column>
                    </Column>
                    <Column className="bg-gray_902 flex flex-col items-center justify-start md:mt-[10px] mt-[20px] sm:mt-[7px] sm:p-[15px] md:p-[20px] p-[40px] rounded-radius42 w-[100%]">
                      <Column className="bg-gray_902 flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[97%]">
                        <Expander type="single" isCollapsible={true}>
                          <ExpanderItem title="Working student Data Science"
                                        className="bg-gray_902 font-bold sm:text-[24px] md:text-[26px] text-[28px] text-white_A700 tracking-ls028 md:tracking-ls1 sm:tracking-ls1 w-[100%]"
                                        value="item-1"
                                        placeholderClassName="text-white_A700">
                            <Text className="font-light leading-[170.00%] text-white_A700 w-[100%]"
                              variant="body6"
                            >
                              Karlsruhe, Berlin, remote <br />
                              The student should support the founders to set up the foundation of the data science
                              capability. We offer you to grow together with our company and develop in a leader
                              position.
                              <br />
                              - First experience in design/building data science solutions <br />
                              - First experience in cloud technologies (AWS) <br />
                              - First experience in developing of machine learning algorithms (classification, test
                              processing) <br />
                              - First experience in components like scikit-learn, Tensorflow, Keras, Pytorch,
                              Knime <br />
                              - Proven experience in developing Python <br />
                              - First experience in CICD and testing and debugging of code <br />
                            </Text>
                            <Button className="cursor-pointer font-medium font-rubik min-w-[33%] md:mt-[10px] mt-[20px] sm:mt-[7px] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                              variant="OutlineGray9003d"
                              onClick={() => {
                                handleEmailCareer();
                              }}
                            >
                              Apply now
                            </Button>
                          </ExpanderItem>
                        </Expander>
                      </Column>
                    </Column>
                  </Column>
                </Column>
                <Column className="bg-cover bg-no-repeat flex flex-col items-end justify-end md:ml-[19px] ml-[38px] sm:mx-[0] p-[152px] sm:p-[15px] md:p-[78px] sm:w-[100%] w-[48%]"
                  style={{ backgroundImage: "url('images/img_group11.svg')" }}>
                  <div className="backdrop-opacity-[0.5] bg-blue_500_87 blur-[123.00px] md:h-[118px] h-[228px] sm:h-[92px] mr-[11px] sm:mr-[4px] md:mr-[5px] sm:mt-[26px] md:mt-[34px] mt-[67px] rounded-radius114 sm:w-[100%] w-[83%]"></div>
                </Column>
              </Row>
            </Stack>
          </Column>
        </Stack>
        <Row className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-center justify-center max-w-[1282px] ml-[auto] mr-[auto] mt-[160px] sm:mt-[63px] md:mt-[82px] sm:pl-[15px] sm:pr-[15px] w-[100%]">
          <Column className="flex flex-col sm:mx-[0] sm:px-[0] sm:w-[100%] w-[40%]">
            <Column className="flex flex-col items-center justify-start shadow-bs2 w-[100%]">
              <Column className="flex flex-col justify-start w-[100%]">
                <Text className="font-bold font-poppins leading-[120.00%] text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[100%]"
                  as="h2"
                  variant="h2">
                  <div id="started">
                    Let’s Work Together
                  </div>
                </Text>
                <Text className="font-light font-rubik leading-[170.00%] md:mt-[10px] mt-[20px] sm:mt-[7px] sm:mx-[0] text-bluegray_900 sm:w-[100%] w-[88%]"
                  variant="body6">
                  I’d love to meet up with you to discuss your venture, and
                  potential collaborations.
                </Text>
              </Column>
            </Column>
            <Column className="flex flex-col justify-end sm:mt-[19px] md:mt-[25px] mt-[50px] sm:mx-[0] md:pr-[2px] pr-[5px] sm:px-[0] sm:py-[1px] md:py-[2px] py-[5px] sm:w-[100%] w-[87%]">
              <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-start mr-[auto] mt-[2px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[65%]">
                <Img src="images/img_mail.svg"
                  className="flex-shrink-0 max-w-[100%] mb-[1px] w-[8%]"
                  alt="mail"
                />
                <Text className="flex-grow font-normal sm:ml-[17px] md:ml-[23px] ml-[45px] mt-[2px] not-italic text-bluegray_900"
                  variant="body6"
                >
                  sales@datasimpl.cloud
                </Text>
              </Row>
              {/* <Column className="flex flex-col items-center justify-start sm:mt-[20px] md:mt-[26px] mt-[52px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[72%]">
                <Column className="flex flex-col items-center justify-start w-[100%]">
                  <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-start w-[100%]">
                    <Img
                      src="images/img_home.svg"
                      className="flex-shrink-0 max-w-[100%] mt-[1px] w-[8%]"
                      alt="home"
                    />
                    <Text
                      className="flex-grow font-normal sm:ml-[17px] md:ml-[23px] ml-[45px] not-italic text-bluegray_900"
                      variant="body6"
                    >
                      4074 Ebert Summit Suite 375
                    </Text>
                  </Row>
                  <Text
                    className="font-normal sm:mt-[3px] md:mt-[4px] mt-[9px] not-italic text-bluegray_900 w-[auto]"
                    variant="body6"
                  >
                    Lake Leonardchester
                  </Text>
                </Column>
              </Column> */}
              <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center ml-[1px] mr-[auto] sm:mt-[20px] md:mt-[26px] mt-[52px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[50%]">
                {/* <Img
                  src="images/img_volume.svg"
                  className="flex-shrink-0 max-w-[100%] w-[10%]"
                  alt="volume"
                />
                <Text
                  className="flex-grow font-normal sm:ml-[17px] md:ml-[23px] ml-[45px] not-italic text-bluegray_900"
                  variant="body6"
                >
                  +49 162 190 4847
                </Text> */}
              </Row>
            </Column>
          </Column>
          <Column className="flex flex-col md:ml-[26px] ml-[51px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[57%]">
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-center justify-between w-[100%]">
              {/* <Input
                className="font-normal not-italic p-[0] text-[17px] placeholder:text-gray_600 text-gray_600 w-[100%]"
                wrapClassName="flex sm:mx-[0] sm:w-[100%] w-[49%]"
                type="text"
                name="name"
                placeholder="Name"
                suffix={
                  <Img
                    src="images/img_upload.svg"
                    className="ml-[35px] mr-[0] sm:ml-[13px] md:ml-[18px] my-[auto]"
                    alt="upload"
                  />
                }
              ></Input>
              <Input
                className="font-normal not-italic p-[0] text-[17px] placeholder:text-bluegray_900 text-bluegray_900 w-[100%]"
                wrapClassName="flex sm:mx-[0] sm:w-[100%] w-[49%]"
                type="email"
                name="mail One"
                placeholder="dash@ui8.net"
                suffix={
                  <Img
                    src="images/img_edit.svg"
                    className="ml-[35px] mr-[0] sm:ml-[13px] md:ml-[18px] my-[auto]"
                    alt="edit"
                  />
                }
                variant="OutlineBlue500_1"
              ></Input> */}
            </Row>
            {/* <Input
              className="font-normal font-poppins not-italic p-[0] text-[17px] placeholder:text-gray_600 text-gray_600 w-[100%]"
              wrapClassName="flex md:mt-[12px] mt-[24px] sm:mt-[9px] w-[100%]"
              name="dropdown"
              placeholder="What are you working on?"
              suffix={
                <Img
                  src="images/img_forward.svg"
                  className="ml-[35px] mr-[3px] sm:ml-[13px] md:ml-[18px] my-[auto]"
                  alt="forward"
                />
              }
            ></Input> */}
            {/* <TextArea
              className="font-normal font-poppins md:mt-[12px] mt-[24px] sm:mt-[9px] not-italic text-[17px] placeholder:text-gray_600 text-gray_600 w-[100%]"
              name="message"
              placeholder="Message"
            ></TextArea> */}
            <Button className="cursor-pointer font-medium font-rubik min-w-[27%] md:mt-[12px] mt-[24px] sm:mt-[9px] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
              variant="FillBlue500"
            >
              Let’s talk
            </Button>
          </Column>
        </Row>
        <footer className="bg-gray_900 font-poppins mt-[160px] sm:mt-[63px] md:mt-[82px] w-[100%]">
          <Column
            className="flex flex-col justify-start max-w-[1271px] sm:mb-[18px] md:mb-[24px] mb-[47px] md:ml-[166px] ml-[auto] md:mr-[168px] mr-[auto] sm:mt-[19px] md:mt-[25px] mt-[50px] sm:mx-[0] sm:pl-[15px] sm:pr-[15px] sm:px-[0] w-[100%]">
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%]">
              <Stack className="font-montserrat h-[65px] relative w-[13%]">
                <Column className="absolute flex flex-col items-center justify-start w-[100%]">
                  <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-end w-[100%]">
                    <Stack
                      className="bg-cover bg-no-repeat h-[60px] ml-[1px] sm:p-[1px] md:p-[20px] p-[5px] relative w-[75%]"
                    >
                      <img src={logo} alt="Logo" height="40px" />
                    </Stack>
                  </Row>
                </Column>
              </Stack>
              <Row className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-start justify-center sm:mx-[0] sm:px-[0] sm:w-[100%] w-[56%]">
                <Text className="cursor-pointer font-medium mb-[1px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateAmbition();
                  }}
                >
                
                  Our ambition
                </Text>
                <Text className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateProblem();
                  }}
                >
                  Problem
                </Text>
                <Text className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateBenefits();
                  }}
                >
                  Benefits
                </Text>
                <Text className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateCase();
                  }}
                >
                  Business Case
                </Text>
                <Text className="font-medium sm:ml-[17px] md:ml-[23px] ml-[45px] mt-[2px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateTrust();
                  }}
                >
                  Trust
                </Text>
                <Text className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateCareer();
                  }}
                >
                  Career
                </Text>
              </Row>
              <Button className="cursor-pointer font-medium font-poppins min-w-[19%] text-[15px] text-center text-white_A700 tracking-ls03 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                variant="OutlineGray9003d"
                onClick={() => {
                  handleEmailSales();
                }}
              >
                Get free assessment
              </Button>
            </Row>
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center sm:mt-[19px] md:mt-[25px] mt-[50px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[96%]">
              <Text className="not-italic text-white_A700 w-[auto]"
                variant="body8">
                <span className="text-white_A700 text-[15px] font-poppins font-normal">
                  © 2022. Data simpl. All righs reserved.
                </span>
              </Text>
              <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-start justify-between md:ml-[365px] ml-[709px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[19%]">
                <Text className="not-italic text-white_A700 w-[auto]"
                  variant="body8"
                >
                  Privacy policy
                </Text>
                <Text className="not-italic text-white_A700 w-[auto]"
                  variant="body8"
                  onClick={handleNavigate}
                >
                  Impressum
                </Text>
              </Row>
            </Row>
          </Column>
        </footer>
      </Column>
    </>
  )
              }

  const HeaderView=()=>{
    return(
        <header  className="sticky top-0 z-50 bg-gray_900 headerViewCss  px-[261px] py-[50px]  sm:px-[0px]  md:px-[0px]  sm:py-[20px]  md:py-[20px] ">
          <Row className="flex sm:flex-col md:flex-col uiVIew justify-between space-x-9 items-center sm:space-y-5 md:space-y-5">
                    <img src={logo} alt="Logo" className="h-[12%] w-[12%] sm:h-[39.74px] sm:w-[105px]" />

 <Row className="flex flex-wrap  font-poppins space-x-5 md:space-x-2 sm:space-x-2  items-center justify-center">
              <text className="cursor-pointer font-poppins  text-white_A700 hover:font-bold mr-3 text-[24px] font-medium"

                onClick={() => {
                  handleNavigateAmbition();
                }}
              >
                   {/* <div id="ambition"> */}
                Our ambition
{/* </div> */}
              </text>
              <text className="cursor-pointer font-poppins  text-white_A700 hover:font-bold mr-3 text-[24px] font-medium"
              
                onClick={() => {
                  handleNavigateProblem();
                }}
              >
                Problem
              </text>
              <text className="cursor-pointer font-poppins  text-white_A700 hover:font-bold mr-3 text-[24px] font-medium"
       
                onClick={() => {
                  handleNavigateBenefits();
                }}
              >
                Benefits
              </text>
              <text className="cursor-pointer font-poppins  text-white_A700 hover:font-bold mr-3 text-[24px] font-medium"
           
                onClick={() => {
                  handleNavigateCase();
                }}
              >
                Business Case
              </text>
              <text className="cursor-pointer font-poppins  text-white_A700 hover:font-bold mr-3 text-[24px] font-medium"
          
                onClick={() => {
                  handleNavigateTrust();
                }}
              >
                Trust
              </text>
              <text className="cursor-pointer font-poppins  text-white_A700 hover:font-bold mr-3 text-[24px] font-medium"
              
                onClick={() => {
                  handleNavigateCareer();
                }}>
                Career
              </text>
            </Row>
         
              <Row className="flex  items-center">
              <Img
                src="images/img_location.svg"
                className="cursor-pointer  h-[32px] w-[32px]  hover:font-bold mr-[20px]"
                alt="location"
                onClick={() => {
                  handleNavigateWorkspace();
                }}
              />
              <button
               className="cursor-pointer font-poppins font-medium  text-[17px]  text-white_A700  rounded-[30px] border-2 border-[#1b8ff7] py-6 px-[34px] w-[200px]  "
                onClick={() => {
                  handleNavigateStarted();
                }}
              >
                Get started
              </button>
            </Row>
          </Row>
        </header>
    )
  }
  const HeaderBottomView=()=>{
    return(
<div 
  className="w-full bg-cover bg-no-repeat h-screen"   
  style={{
    backgroundImage: 'url("images/img_bg.svg")',
    backgroundPosition: 'center center',
     height: '100vh'
  }}
>
  
      <div className="flex  w-6/12  flex-col p-[120px]  sm:px-9 sm:w-full md:p-0 md:px-9 md:w-full  ">

<p className="text-white font-poppins font-bold text-[90px]">Data simpl</p>
<p className="text-white font-poppins font-thin text-[20px]">Enable everyone to integrate their data to offer data products easily and effortlessly based on state-of-the-art AI</p>
                <button className="cursor-pointer font-poppins w-[150px] text-white mt-4 bg-[#1b8ff7] font-medium rounded-[24px] p-4 text-base text-bluegray-900 border border-blue-500 hover:bg-[#1b8ff7] hover:text-white">

                  Get started
                </button>
      </div>
    </div>
    )
  }
   const AmbitionView=()=>{
    return(
              <Column className="flex flex-col font-rubik px-[120px] sm:px-[50px] md:px-[10px] w-[100%] pt-10 ">
            <Text 
            className="font-bold font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 "
              as="h2"
              variant="h2">
             <div  id="ambition" > 
                Our ambition
           </div>
            </Text>
            <Text className="font-light font-rubik mt-[16px] sm:mt-[6px] md:mt-[8px] text-gray_901 w-[auto]"
              variant="body6">
              Become the platform to deliver data as a service without lead time
              and implementation
            </Text>
        
          <Column className="bg-gray_900 flex flex-col items-center justify-start sm:mt-[25px] md:mt-[33px] mt-[65px] sm:p-[15px] md:p-[25px] p-[50px] rounded-radius32 w-[100%]">
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-start justify-between sm:px-[0] w-[100%]">
              <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[18%]">
                <Text className="text-white_A700 tracking-ls052 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  as="h3"
                  variant="h3">
                  15 min
                </Text>
                <Text className="font-normal mt-[18px] sm:mt-[7px] md:mt-[9px] not-italic text-white_A700 w-[auto]"
                  variant="body4">
                  Integrate your data
                </Text>
              </Column>
              <Line className="bg-gradient2  h-[106px] sm:h-[43px] md:h-[55px] w-[1px]" />
              <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[18%]">
                <Text className="text-white_A700 tracking-ls052 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  as="h3"
                  variant="h3">
                  15 min
                </Text>
                <Text
                  className="font-normal mt-[18px] sm:mt-[7px] md:mt-[9px] not-italic text-white_A700 w-[auto]"
                  variant="body4">
                  Create a data product
                </Text>
              </Column>
              <Line className="bg-gradient2  h-[106px] sm:h-[43px] md:h-[55px] w-[1px]" />
              <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[16%]">
                <Text className="text-white_A700 tracking-ls052 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  as="h3"
                  variant="h3">
                  15 min
                </Text>
                <Text className="font-normal mt-[16px] sm:mt-[6px] md:mt-[8px] not-italic text-white_A700 w-[auto]"
                  variant="body4">
                  Visualize your data
                </Text>
              </Column>
              <Line className="bg-gradient2  h-[106px] sm:h-[43px] md:h-[55px] w-[1px]" />
              <Column className="flex flex-col items-center justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] w-[18%]">
                <Text className="text-white_A700 tracking-ls052 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  as="h3"
                  variant="h3">
                  15 min
                </Text>
                <Text className="font-normal mt-[18px] sm:mt-[7px] md:mt-[9px] not-italic text-white_A700 w-[auto]"
                  variant="body4"
                >
                  Create an event
                </Text>
              </Column>
            </Row>
          </Column>
        </Column>
    )
  }
const ProblemAndOpportunity=()=>{
  return(
        <Column className="flex flex-col font-rubik p-10 px-[120px] sm:px-[50px] md:px-[10px]">
         <Column className="flex flex-col ">
            {/* <Text className="font-bold  font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1"
              as="h2"
              variant="h2">
              <div id="problem">
                Problem and opportunity
              </div>
            </Text> */}
            <p id="problem" className="font-black text-[60px] sm:text-[30px] font-bold font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1"> 
          
                Problem and opportunity
            
                </p>
            <Text className="font-light font-rubik mt-[16px] sm:mt-[6px] md:mt-[8px] text-gray_901 w-[auto]"
              variant="body6">
              We are developing the solution to overcome these challanges
            </Text>
          </Column>
          <div className="flex flex-wrap items-center mt-10  ">
 
       {ProblemandopportunityButtons.map((item)=>{ 
  return   <button className="cursor-pointer mx-2 my-2 font-medium rounded-lg p-4 text-base text-bluegray-900 border border-blue-500 hover:bg-[#1b8ff7] hover:text-white">
  {item}
</button>
})}
   </div>
     </Column>
  )
}
 const MakeUsSpecialView=()=>{
  return(
    
<div className="flex flex-col">
<div
  className="w-full min-h-screen flex flex-col justify-center items-center bg-cover bg-no-repeat"
  style={{
    backgroundImage: 'url("images/BlueBackGround.svg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }}
>
  <div className=" p-[50px]"/>
  <div className="text-center text-white ">
    <p className="font-extrabold text-[40px] font-poppins tracking-ls06">
      What makes us special
    </p>
    <p className="font-normal text-[10px]   font-poppins tracking-ls06">
      Is in the early founding phase to become the data platform to deliver data as a service without lead time and implementation.
    </p>
  </div>
 <div className="flex items-center px-[120px] space-x-8 sm:flex-col sm:space-x-0 sm:px-[0px] md:flex-col md:space-x-0 md:px-[0px] md:space-y-4 sm:space-y-4 mt-10">
<div className="space-y-3">
<SpecialComponent 
circleStyle={'border-[#01A8FF]'}
titleStyle={'text-[#01A8FF]'}
titleText={"Harmony delivers a full-scale integration, data and dashboarding platform"}
title={'No Lead Time'}
count={1}
/>

<SpecialComponent 
circleStyle={'border-[#00C7B6]'}
titleStyle={'text-[#00C7B6]'}
titleText={"Data becomes the center of many ecosystems and organizations"}
title={'Full integration'}
count={2}
/>
</div>
<div className="space-y-3">
<SpecialComponent 
circleStyle={'border-[#4141E2]'}
titleStyle={'text-[#4141E2]'}
titleText={"We become the platform which is made for data meshes and data products"}
title={'Immediately available'}
count={3}
/>

<SpecialComponent 
circleStyle={'border-[#008BB6]'}
titleStyle={'text-[#008BB6]'}
titleText={"Our competitors only offer components which requires a development team"}
title={'Full customizability'}
count={4}
/>

</div>
</div>
<div className="p-[50px]"/>
  </div>
<div
  className="w-11/12 rounded-[20px] self-center sm:mx-[20px] -mt-[90px] px-[40px] sm:px-[0px] py-[20px] "
  style={{
    backgroundImage: 'url("images/RectangleBlur.png")',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  }}
>
  <div className="flex justify-center items-center sm:flex-col sm:py-[0px]">
    <div className="sm:px-[20px]">
      <p className="text-white font-extrabold font-poppins text-[60px]">Simple Data Benefits</p>
      <p className="text-white font-extrabold font-poppins text-[10px]">
        Our mission is to enable everyone to integrate their data to offer data products easily and effortlessly based on state-of-the-art AI. We are two founders with more than 20 years of experience in the energy industry and related data projects.
      </p>
    </div>

    <img src={'images/AwsImages.png'} className="w-6/12 object-contain sm:w-9/12" />
  </div>
</div>


</div>

  )
 }

 const BusinessCaseExample=()=>{
  return(
    <div className="flex  justify-center flex-col items-center bg-[#f9f9fc} mt-[30px]">
      <div id="case">
<p className="text-black font-poppins font-bold text-center text-[40px]">Business Case example</p>
<p className="text-black font-poppins font-light text-center text-[15px]">We offer up to 8 times more value-add without any lead time</p> 
    </div>
 <div className="flex px-[20px]  sm:flex-col sm:items-center md:flex-col md:items-center mt-10">

  <div className="flex flex-col py-[10px] items-center bg-white shadow-md rounded-lg mx-10">
    <BusinessDetailComponent
      title={'Investment'}
      detail={'Traditional approaches require high initial investments and lead time'}
    />
    <BusinessDetailComponent
      title={'Lead time'}
      detail={'On average, only after 2 years can the first benefits of the platform be used'}
    />
    <BusinessDetailComponent
      title={'Break even'}
      detail={'The break even point is only reached after 5 years with traditional approaches'}
    />
    <BusinessDetailComponent
      title={'Data as a service'}
      detail={'With Harmony’s cloud solution, advantages can be used immediately'}
    />
    <BusinessDetailComponent
      title={'Continuously extended'}
      detail={'After the first use case has been configured, further use cases can be added to the platform'}
      hideline
    />
  </div>

  <div className="flex  items-center justify-center w-full px-10  sm:mt-[120px] md:mt-[120px]">
    <img src={'images/BusinessFrame.png'} className="w-6/12 object-contain sm:w-9/12 "  />
  </div>

</div>
    
    </div>


  )
 }
 const FreeAssesmentView=()=>{
  return(
<div
  className="w-full min-h-screen  flex flex-col justify-center items-center bg-cover bg-no-repeat "
  style={{
    backgroundImage: 'url("images/Group-Bg.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }}
>
  <div className=" p-[50px]"/>
<img
src={'images/chart.png'}
className="w-6/12  h-screen object-contain sm:w-9/12"
/>
<div className="bg-[#02808e] p-10 self-center rounded-[60px] w-6/12 mb-11 sm:w-[90%] flex justify-between ">
<div>
<p className="text-white font-extralight text-[25px] sm:text-[15px]  md:text-[15px]"> If you’re ready to succeed,</p>
<p className="text-white font-bold text-[30px] sm:text-[20px]  md:text-[20px]">we are ready to help!</p>
</div>
                            <button
                              className="cursor-pointer font-medium font-rubik bg-[#1b8ff7] text-white rounded-[20px] p-3 "
                              variant="OutlineGray9003d">
                              Get free assessment
                            </button>
</div>
  </div>
  )
 }
const CompaniesView=()=>{
  return(
    <div className="flex flex-col items-center justify-center">
            <Text className="font-bold font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 text-center "
              as="h2"
              variant="h2">
              <div id="trust">
                Companies who trust us
              </div>
            </Text>
            <Text className="font-light font-rubik sm:mt-[3px] md:mt-[4px] mt-[9px] text-bluegray_900 text-center"
              variant="body6">
              We currently looking for partners who want to try our services as
              early customers with different benefits
            </Text>
            {/* <div className="flex flex-wrap space-x-8 sm:space-x-0 sm:flex-col md:space-x-0 md:flex-col">
           {imageData.map((item,index)=>{return <img
            src={item}
            className="w-[150px] h-[160px]" 
            />})}
            </div> */}
<div className="flex space-x-5 justify-center my-5 sm:flex-col sm:space-x-0 sm:items-center">
<IntroComponent 
detail={`"${"Folio Designer is a clean and creative landing page that matches with any freelancer or designer, you can easily to build the page in Sketch or Figma."}"`}
usename={"Tamir Margalit"}
userdetail={'UI8'}
/>
<IntroComponent 
detail={`"${"Folio Designer is a clean and creative landing page that matches with any freelancer or designer, you can easily to build the page in Sketch or Figma."}"`}
usename={"Tamir Margalit"}
userdetail={'UI8'}
/>
<IntroComponent 
detail={`"${"Folio Designer is a clean and creative landing page that matches with any freelancer or designer, you can easily to build the page in Sketch or Figma."}"`}
usename={"Tamir Margalit"}
userdetail={'UI8'}
/>

</div>
    </div>

  )
}

 const CareerView=()=>{
  return(
   <div
  className="w-full min-h-screen flex flex-row  bg-cover bg-no-repeat sm:flex-col md:flex-col "
  style={{
    backgroundImage: 'url("images/career_img.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
    
  }}
>
  <div className="flex flex-col w-6/12 p-20 space-y-5 sm:w-full md:w-full sm:p-10 md:p-10">
<h1 className="text-white text-[40px] font-poppins font-semibold">Career</h1>
<span className="text-white text-[20px] font-poppins">  We are looking for three roles that are supporting us in our early founding phase</span>
 
{careers.map((item,index)=>
{
  return<JobsComponent
  title={item.title}
  description={item.description}
  Competenceprofile={item.Competenceprofile}
onPressArrow={()=>{
  var careerData=[...careers]
  careerData.map((item)=>item.showCompetence=false)

  careerData[index].showCompetence=true
  console.log("showCompetenceshowCompetenceshowCompetence", careerData[index].showCompetence)
setCareers(careerData)

  }}
  style={item.showCompetence?'bg-[#048296] p-7 rounded-[42px]':'bg-[#073350] p-7 rounded-[42px]'}
showCompetence={item.showCompetence}
onButtonPress={()=>     handleEmailCareer()}
/>})}
  </div>


  <img
  src={'images/Ресурс.svg'}
  className="w-[50%]  sm:self-center md:self-center"
  />

  
  </div>
  )
 }
const WorkTogetherView=()=>{
return(
  <div className="flex  sm:flex-col md:flex-col p-10">
    <div className="flex flex-col w-1/2  px-20 sm:px-10 space-y-10 sm:w-full md:w-full " >
      <p className="font-poppins font-bold text-[60px] sm:text-[30px] "><span className="text-[#1b8ff7]">Let's</span> Work Together</p>
 <p className="text-[#050F2B] font-rubik font-light text-[17px] ">I’d love to meet up with you to discuss your venture, and potential collaborations.</p>
 <div className="flex flex-row space-x-5 items-center">
<img src={"images/email.svg"}/>
<p className="font-[12px] font-poppins font-light">career@datasimpl.cloud</p>
 </div>
 
  {/* <div className="flex flex-row space-x-5 items-center">
<img src={"images/property.svg"}/>
<p className="font-[12px] font-rubik font-light">4074 Ebert Summit Suite 375 Lake Leonardchester</p>
 </div> */}

  <div className="flex flex-row space-x-5 items-center">
<img src={"images/mobile.svg"}/>
<p className="font-[12px]  font-poppins  font-light">00491621904847</p>
 </div>
    </div>

<div className=" mt-12 px-3 sm:w-full md:w-full">

 
    <div className="flex">
<TextInput placeHolder={"Name"} 
// image={"images/userIcon.svg"}
value={userName}
getValue={(e)=>setUserName(e)}
/>
<TextInput placeHolder={"Email"}
getValue={(e)=>setUserEmail(e)}
value={userEmail}
/>
</div>
<div className="flex mt-10">
      <input
        type="text"
        className="bg-[#f8f8f9] text-gray-800 p-4 w-full border-0 focus:bg-white hover:bg-white  hover:border-2 hover:border-[#379df8] rounded-full"
        placeholder="What are you working on?"
        value={userWorking}
        onChange={(e)=>setUserWorking(e.target.value)}
      />
</div>

   <textarea
        rows="4"
        cols="50"
        style={{resize: 'none'}}
                className="bg-[#f8f8f9] h-32 text-gray-800 p-4 w-full border-0 focus:bg-white hover:bg-white  hover:border-2 hover:border-[#379df8] rounded-[24px] mt-8 focus:border-2" 

        placeholder="Message"
           value={userMessage}
        onChange={(e)=>setUserMessage(e.target.value)}
      />
<button onClick={()=>handleSubmit()} className="cursor-pointer text-white mx-2 my-2 bg-[#1b8ff7] font-medium rounded-lg p-4 text-base text-bluegray-900 border border-blue-500 hover:bg-[#1b8ff7] hover:text-white">
Let’s talk
</button>
</div>
  </div>
)
}
const FooterView=()=>{
return(
    <footer className="bg-gray_900 flex flex-col font-poppins p-[20px]">
       <div className="flex  sm:justify-center md:justify-center">
<img src={logo} className="w-[90px]"/>
  </div>
     <Row className="flex flex-wrap  font-poppins  items-center justify-center space-x-5">
              <Text className="cursor-pointer font-medium  text-white_A700 hover:font-bold mr-3"
                variant="body6"
                onClick={() => {
                  handleNavigateAmbition();
                }}
              >
                   {/* <div id="ambition"> */}
                Our ambition
{/* </div> */}
              </Text>
              <Text className="cursor-pointer font-medium   text-white_A700  hover:font-bold mr-3"
                variant="body6"
                onClick={() => {
                  handleNavigateProblem();
                }}
              >
                Problem
              </Text>
              <Text className="cursor-pointer font-medium  text-white_A700 hover:font-bold mr-3"
                variant="body6"
                onClick={() => {
                  handleNavigateBenefits();
                }}
              >
                Benefits
              </Text>
              <Text className="cursor-pointer font-medium  text-white_A700  hover:font-bold  mr-3"
                variant="body6"
                onClick={() => {
                  handleNavigateCase();
                }}
              >
                Business Case
              </Text>
              <Text className="cursor-pointer font-medium  text-white_A700   hover:font-bold mr-3 "
                variant="body6"
                onClick={() => {
                  handleNavigateTrust();
                }}
              >
                Trust
              </Text>
              <Text className="cursor-pointer font-medium  text-white_A700 w-[auto]  hover:font-bold mr-3"
                variant="body6"
                onClick={() => {
                  handleNavigateCareer();
                }}>
                Career
              </Text>
              <button className="cursor-pointer text-white mx-2 my-2 bg-[#1b8ff7] font-medium rounded-[24px] p-4 text-base text-bluegray-900 border border-blue-500 hover:bg-[#1b8ff7] hover:text-white">
Get free assessment
</button>
            </Row>

         <Row className={'justify-between mt-5 flex'}>
<p className="text-white text-[15px] font-light">© 2022. Data simpl. All rights reserved.</p>
     
<Row className={'flex space-x-4'}>
  <p className="text-white text-[15px] font-light">Privacy policy</p>
    <p className="text-white text-[15px] font-light">Terms & Conditions</p>
</Row>
         </Row>
        </footer>
)
}
  return (
    <>
    {HeaderView()}
  {HeaderBottomView()}
   
   {AmbitionView()}

    <div className="mt-10"/>
    {ProblemAndOpportunity()} 
     <div id="benefits">
{MakeUsSpecialView()}
 </div>
 
      <div >
 {BusinessCaseExample()}
 </div>
{FreeAssesmentView()}
{/* {CompaniesView()} */}
  <div id="career">
{CareerView()}
</div>
{WorkTogetherView()} 
{FooterView()}
    {/* {MainView()} */}
   
    </>
  );
};
export default HomePage;