import BarChartView from 'components/BarChartView/BarChartView'
import { DoughnutChartView } from 'components/DoughnutChartView/DoughnutChartView'
import LineChartView from 'components/LineChartView/LineChartView'
import SingleBarChartView from 'components/SingleBarChartView/SingleBarChartView'
import React from 'react'

export default function ChartView() {
  return (
    <div 
    className='bg-[#f3f4f6] rounded-[24px] flex sm:flex-col md:flex-col  items-center  justify-between mx-5 p-4 '
    >
   <SingleBarChartView/>
   <LineChartView/>
   <DoughnutChartView/>
      <BarChartView/>



    </div>
  )
}
