import React, { useEffect, useState } from "react";
import { Button, withAuthenticator } from "@aws-amplify/ui-react";
import { BlueNavigationBar } from "../../components/NewBlueNavigationBarLeft/navigationBox";
import Header from "../../components/NewHeader/Header";
import { DataGrid } from "@mui/x-data-grid";
import { prepareHeaderInstance } from "../../components/userAuth";
import { data } from "autoprefixer";
import "@aws-amplify/ui-react/styles.css";
import axios from "axios";
import { getValidToken } from "../../components/userAuth";

const baseurl = process.env.REACT_APP_BASE_URL;


const columns = [
  { field: "UUID", headerName: "UUID", width: 250 },
  { field: "Runtime", headerName: "Runtime", width: 180 },
  { field: "DataObject", headerName: "DataObject", width: 100 },
  { field: "Data", headerName: "Data", width: 600 },
  { field: "Link", headerName: "Link", width: 600 },
];

const UploadData = () => {

  const [tableData2, settableData2] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [dataSet, setDataSet] = useState(null);
  const [dataSetSelected, setDataSetSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        const axiosInstance = await prepareHeaderInstance();
        data = await axiosInstance.get(
          baseurl.concat("allData/")
        );
        settableData2(data.data);
      } catch (error) {
        console.log("Error getting user info: ", error);
      }
    }

    loadData();
  }, []);

  const handleFileSelect = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setSelectedFile(null);
      setFileSelected(false);
      return;
    }
    const file = files[0];
    setSelectedFile(file);
    setFileSelected(true);
    console.log('file', file);
  };


  const handleDataSetSelect = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setDataSet(null);
      setDataSetSelected(false);
      return;
    }
    const file = files[0];
    setDataSet(file);
    setDataSetSelected(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (!files || files.length === 0) {
      setSelectedFile(null);
      setFileSelected(false);
      return;
    }
    const file = files[0];
    setSelectedFile(file);
    setFileSelected(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const submitUploadFile = async () => {
    setIsLoading(true);
    if (!selectedFile) {
      alert("Please choose a file");
      return;
    }
    console.log('try to upload file');
    const reader = new FileReader();
    reader.readAsText(selectedFile, "UTF-8");

    reader.onload = async (evt) => {
      const fileContent = evt.target.result;

      try {
        const parsedData = JSON.parse(fileContent);
        if (typeof parsedData !== "object" || parsedData === null) {
          throw new Error();
        }

        const formData = new FormData();
        formData.append("fileobject", selectedFile);
        const token = await getValidToken();
        const username = localStorage.getItem('username');
        const instance = axios.create({
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
            'username': username,
          },
        });
        instance
          .post(baseurl.concat("uploadfile/"), formData)
          .then((res) => {
            alert("File upload success");
            window.location.reload();
          })
          .catch((err) => {
            alert("File upload error");
            setIsLoading(false);
          });
      } catch (err) {
        setIsLoading(false);
        alert("The file you chose isn't a valid JSON format.");
      }
    };

    reader.onerror = () => {
      alert("Error during reading file");
    };
  };

  const submitUploadDataSet = async () => {
    setIsLoading(true);
    if (!dataSet) {
      alert("Please choose a Dataset");
      return;
    }
    const token = await getValidToken();
    const username = localStorage.getItem('username');

    const instance = axios.create({
      headers: {
        'Content-Type': 'text/csv',
        'Authorization': 'Bearer ' + token,
        'username': username,
      },
    });

      const formData = new FormData();
      formData.append("fileobject", dataSet);
      console.log(formData);
      instance
        .post(baseurl.concat("uploadDataset"), formData)
        .then((res) => {
          alert("File upload success");
          window.location.reload();
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          alert("File upload error");
          setIsLoading(false);
        });
  };


  const submitDeleteInitData = async () => {
    setIsLoading(true);
    const axiosInstance = await prepareHeaderInstance();
    axiosInstance
      .get(baseurl.concat("deleteInitData"))
      .then((res) => {
        alert("Delete Init Data success");
        window.location.reload();
        setIsLoading(false);  // Set loading to false after the request is successful
      })
      .catch((err) => {
        alert("File Upload Error");
        setIsLoading(false);  // Set loading to false even if there's an error
      });
  };


  const createRows = tableData2.map((row, index) => {
    const newRow = {
      id: index,
      UUID: "UUID",
      Runtime: "runtime",
      DataObject: "dataobject",
      Data: "data",
      Link: "link",
    };

    if (row[0] != null) {
      newRow.UUID = row[0];
    }
    if (row[1] != null) {
      newRow.Runtime = row[1];
    }
    if (row[2] != null) {
      newRow.DataObject = row[2];
    }
    if (row[4] != null) {
      newRow.Data = row[4];
    }
    if (row[5] != null) {
      newRow.Link = row[5];
    }
    return newRow;
  });
const MainView=()=>{
  return(
      <>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <BlueNavigationBar></BlueNavigationBar>

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header title="Upload data" subtitle="Upload your data" />

          <main>
            <div className="grid grid-cols-12 grid-rows-auto gap-10 mt-10 ml-10 mr-10">
              <div className="h-[1000px] col-span-9 row-span-5 justify-center bg-primary-400 p-30 bg-gray-100 rounded-xl">
                <div className="flex h-[900px] justify-center mt-10 ml-10 mr-10">
                  <DataGrid
                    rows={createRows}
                    columns={columns}
                    pageSize={12}
                    rowsPerPageOptions={[12]}
                  />
                </div>
              </div>

              <div className="bg-gray-100 col-span-3 row-span-1 justify-center bg-primary-400 p-30 rounded-xl">
                <div
                  className="mt-10 ml-10 mr-10 mb-10"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  {/* ToDo: Add hover effect */}
                  <Button
                    loadingText="aaaaaa"
                    isFullWidth={true}
                    variation="primary"
                    backgroundColor={fileSelected ? "#4CAF50" : "#228DF1"}
                    as="label"
                    htmlFor="file"
                    ariaLabel="Choose file"
                  >
                    <input
                      id="file"
                      type="file"
                      accept=".json"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                    {fileSelected ? "File selected" : "Choose file"}
                  </Button>
                  {fileSelected ? (
                    <div className="text-sm mt-2 text-gray-500">
                      To upload, please press the button.
                    </div>
                  ) : (
                    <div className="text-sm mt-2 text-gray-500">
                      Drag and drop the file to upload, or click the button to select a file.
                    </div>
                  )}

                  <br />
                  <br />
                  {fileSelected ? (
                  <Button
                    loadingText=""
                    isFullWidth={true}
                    variation="primary"
                    onClick={submitUploadFile}
                    ariaLabel=""
                    backgroundColor={fileSelected ? "#4CAF50" : "#228DF1"}
                  >
                    Upload Data
                  </Button>) : null}

                  <br />
                  <br />

                  <Button
                    loadingText=""
                    isFullWidth={true}
                    variation="primary"
                    backgroundColor="#228DF1"
                    onClick={submitDeleteInitData}
                    ariaLabel=""
                  >
                    Delete init data
                  </Button>
                </div>
              </div>

              <div className="bg-gray-100  col-span-3 row-span-4 justify-center content-center bg-primary-400 p-30 rounded-xl">
                <iframe
                  //src="http://localhost:5000/initdashboard/"
                  src={"https://backend.datasimpl.cloud:5000/initdashboard/"}
                  height="100%"
                  width="100%"
                  title="Swagger"
                ></iframe>
         d       {/* </div> */}
              </div>

              <div className="col-span-3 row-span-2 justify-center bg-primary-400 p-30"></div>
            </div>
          </main>
        </div>
      </div>
    </>

  )
}
  return (
    <>
      <div className="flex ">
        <BlueNavigationBar />

        <div className="flex-1 ">
                  <div  className="mb-[20px]">

          <Header title="Upload data" subtitle="Upload your data" />
          </div>
          <div className="flex  sm:flex-col md:flex-col space-x-6 m-5 justify-center items-center">
            <div className="flex w-2/3 sm:w-[85%] md:w-full my-0">
              <div style={{ height: "850px", width: "100%" }}>
                <DataGrid
                  rows={createRows}
                  columns={columns}
                  pageSize={12}
                  rowsPerPageOptions={[12]}
                />
              </div>
            </div>

            <div className="flex h-[820px] flex-col space-y-6 sm:space-y-3 md:space-y-3 m-0 sm:m-0 ">
              <div className="bg-gray-100 justify-center bg-primary-400 p-10 rounded-xl">
                <div className="" onDrop={handleDrop} onDragOver={handleDragOver}>
                  {
                    isLoading ? (
                      <div className="loader"></div>
                    ) : (
                      <>
                        {dataSetSelected ? null : (
                          <Button
                            loadingText="aaaaaa"
                            isFullWidth={true}
                            variation="primary"
                            backgroundColor={fileSelected ? "#4CAF50" : "#228DF1"}
                            as="label"
                            htmlFor="file"
                            ariaLabel="Choose file"
                            className="text-white"
                          >
                            <input
                              id="file"
                              type="file"
                              accept=".json"
                              style={{ display: "none" }}
                              onChange={handleFileSelect}
                            />
                            {fileSelected ? "File selected" : "Choose file"}
                          </Button>
                        )}

                        <div className="mt-4"></div>

                        {fileSelected ? null : (
                          <Button
                            loadingText="bbbbbbb"
                            isFullWidth={true}
                            variation="primary"
                            backgroundColor={dataSetSelected ? "#4CAF50" : "#228DF1"}
                            as="label"
                            htmlFor="dataSetUpload"
                            ariaLabel="Choose dataset"
                          >
                            <input
                              id="dataSetUpload"
                              type="file"
                              accept=".csv, .json"
                              style={{ display: "none" }}
                              onChange={handleDataSetSelect}
                            />
                            {dataSetSelected ? "Dataset selected" : "Choose dataset"}
                          </Button>
                        )}

                        {fileSelected || dataSetSelected ? (
                          <div className="text-sm mt-2 text-gray-500">
                            To upload, please press the button.
                          </div>
                        ) : (
                          <div className="text-sm mt-2 text-black">
                            Drag and drop the file to upload, or click the button to select a file.
                          </div>
                        )}

                        <br />
                        <br />
                        {fileSelected ? (
                          <Button
                            loadingText=""
                            isFullWidth={true}
                            variation="primary"
                            onClick={submitUploadFile}
                            ariaLabel=""
                            backgroundColor={fileSelected ? "#4CAF50" : "#228DF1"}
                          >
                            Upload Data
                          </Button>
                        ) : null}

                        {dataSetSelected ? (
                          <Button
                            loadingText=""
                            isFullWidth={true}
                            variation="primary"
                            onClick={submitUploadDataSet}
                            ariaLabel=""
                            backgroundColor={dataSetSelected ? "#4CAF50" : "#228DF1"}
                          >
                            Upload Dataset
                          </Button>
                        ) : null}

                        <br />
                        <br />

                        <Button
                          loadingText=""
                          isFullWidth={true}
                          variation="primary"
                          backgroundColor="#228DF1"
                          onClick={submitDeleteInitData}
                          ariaLabel=""
                          className="text-white"
                        >
                          Delete init data
                        </Button>
                      </>
                    )
                  }
                </div>
              </div>

              <div className="bg-gray-100 h-screen  col-span-3 row-span-4 justify-center content-center bg-primary-400  rounded-xl ">
                <iframe
                  //src="http://localhost:5000/initdashboard/"
                  src={"https://backend.datasimpl.cloud:5000/initdashboard/"}
                  height="100%"
                  width="100%"
                  title="Swagger"
                ></iframe>
              </div>
            
            </div>
          </div>
        </div>
      </div>
      {/* {MainView()} */}
    </>
  );
};

export default withAuthenticator(UploadData);
