import React, { useEffect, useState } from "react";
import { Img, Line, Text } from "components";
import { Auth } from "aws-amplify";
import { ButtonSignOut } from "ui-components";
import './Header.css'
const Header = ({ title, subtitle }) => {
  const [inputvalue, setInputvalue] = React.useState("");
const [notificationCount,setNotificationCount]=useState(10)
const [userEmail,setUserEmail]=useState()
const [userName,setUserName]=useState()

  // get user info
  async function getUserInfo() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userInfo = {
        username: user.username,
        name: user.attributes.name,
        email: user.attributes.email,
        phone: user.attributes.phone_number,
      };
      return userInfo;
    } catch (error) {
      console.log("Error getting user info: ", error);
    }
  }
useEffect(()=>{
  getUserInfo()
    .then((userInfo) => {
    
      setUserName(userInfo.name)
      setUserEmail(userInfo.email)
    })
    .catch((error) => {
      console.log("Error: ", error);
    });

},[])

const MainView=()=>{
  return(
        <>
        <div className="flex md:flex-col sm:flex-col flex-row gap-[30px] items-center justify-between w-screen">
        <Text
          className="font-bold leading-[100.00%] text-gray_900 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1"
          variant="body5"
        >
          <span className="text-gray_900 text-[17px] font-poppins font-light mx-10">
            {subtitle}
          </span>
          <span className="text-gray_900 text-[17px] font-poppins">
            {" "}
            <br />
          </span>
          <span className="sm:text-[24px] md:text-[26px] text-blue_500 text-[28px] font-poppins mx-10">
            {title}
          </span>
        </Text>

        <div className="flex flex-row gap-[8px] items-center justify-start w-[auto]">
          <Img
            src="images/img_notification.svg"
            className="h-[24px] w-[24px]"
            alt="notification"
          />
          <Text
            className="bg-blue_500 flex font-medium h-[28px] items-center justify-center rounded-radius50 text-right text-white_A700 w-[28px]"
            variant="body18"
          >
            
            {/* 10 */}
          </Text>

         
        </div>
        <div className="flex flex-row gap-[15px] items-center justify-start py-[10px] w-[auto]">
           <ButtonSignOut backgroundColor="#228DF1"/>
          <Img
            src="images/img_usericon.png"
            className="h-[42px] md:h-[auto] sm:h-[auto] rounded-radius50 w-[42px]"
            alt="UserIcon"
          />
          <div className="flex flex-col gap-[5px] items-start justify-start w-[auto]">
            <Text
              className="font-semibold text-gray_900 text-left w-[auto]"
              variant="body15"
            >
              <div id="user-name"></div>
            </Text>
            <Text
              className="font-light text-blue_500 text-left w-[auto]"
              variant="body16"
            >
              <div id="user-email"></div>
            </Text>
          </div>
          <Img
            src="images/img_interfaceessentialdots.svg"
            className="h-[24px] w-[24px]"
            alt="InterfaceEssentialdots"
          />
        </div>
      </div>
         <Line className="bg-blue_500_6c h-[1px] mt-[30px] w-[100%]" />
    </>
  )
}

const WelcomeView=()=>{
  return(
<div className="flex flex-col justify-center mx-10 ">

          <span className="text-gray_900 text-[33px] text-center font-poppins font-normal ml-10">
            {subtitle}{" "}
              <span className=" text-blue_500 text-[33px] font-poppins font-extrabold">
            {title}
          </span>
          </span>
        


</div>
  )
}
const UserDetailView=()=>{
  return(
    <div className=" flex items-center pt-5 space-x-3 sm:flex-col-reverse  md:flex-col-reverse  sm:space-y-3  md:space-y-3">
    <div className="flex space-x-2 sm:space-y-8  md:space-y-8  items-center  ">
      <div className="flex sm:pt-[35px] md:pt-[35px]">
        <Img
            src="images/img_notification.svg"
            className="h-[34px] w-[34px] cursor-pointer"
            alt="notification"
            onClick={()=>{}}
          />
{notificationCount>0?  <div className="bg-[#1b8ff7] items-center flex justify-center rounded-full h-6 w-6 -ml-[18px] -mt-[9px]">
            <span className="text-white text-[13px]">{notificationCount}</span>
          </div>:null}
          </div>
                  <ButtonSignOut backgroundColor="#228DF1" />
          </div>
      
      
                  <div className="flex space-x-4">
           <Img
            src="images/img_usericon.png"
            className="h-[42px] md:h-[auto] sm:h-[auto] rounded-radius50 w-[42px]"
            alt="UserIcon"
          />
          <div className="flex flex-col space-y-1">
            <span>{userName}</span>
                 <span className="text-[#1b8ff7]">{userEmail}</span>
       
              </div>
                    <Img
            src="images/img_interfaceessentialdots.svg"
            className="h-[24px] w-[24px] cursor-pointer"
            alt="InterfaceEssentialdots"
               onClick={()=>{}}
          />
          </div>
    </div>
  )
}
  return (

  <div style={{   backgroundColor: 'white',  }} className="w-[100%]">
  <div
 //  style={{zIndex:1000, position:'absolute',top:0, width:"100%",backgroundColor:'red'}}
    className="flex justify-between rotateview w-[100%] p-5">
 { WelcomeView()}
 {UserDetailView()}
 </div>
         <Line className="bg-blue_500_6c h-[1px] mt-[30px] w-[100%] my-4" />
  </div>
   
  );
};
export default Header;
