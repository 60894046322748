import React from "react";
import { Img, Line, Text } from "components";
import { Auth } from "aws-amplify";
import { ButtonSignOut } from "ui-components";

const Header = ({ title, subtitle }) => {
  const [inputvalue, setInputvalue] = React.useState("");

  // get user info
  async function getUserInfo() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userInfo = {
        username: user.username,
        name: user.attributes.name,
        email: user.attributes.email,
        phone: user.attributes.phone_number,
      };
      return userInfo;
    } catch (error) {
      console.log("Error getting user info: ", error);
    }
  }

  getUserInfo()
    .then((userInfo) => {
      document.getElementById("user-email").innerHTML = userInfo.email;
      document.getElementById("user-name").innerHTML = userInfo.name;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });

  return (
    <>
      <div className="flex md:flex-col sm:flex-col flex-row gap-[30px] items-center justify-between w-[100%]">
        <Text
          className="font-bold leading-[100.00%] text-gray_900 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1"
          variant="body5"
        >
          <span className="text-gray_900 text-[17px] font-poppins font-light mx-10">
            {subtitle}
          </span>
          <span className="text-gray_900 text-[17px] font-poppins">
            {" "}
            <br />
          </span>
          <span className="sm:text-[24px] md:text-[26px] text-blue_500 text-[28px] font-poppins mx-10">
            {title}
          </span>
        </Text>

        <div className="flex flex-row gap-[8px] items-center justify-start w-[auto]">
          <Img
            src="images/img_notification.svg"
            className="h-[24px] w-[24px]"
            alt="notification"
          />
          <Text
            className="bg-blue_500 flex font-medium h-[28px] items-center justify-center rounded-radius50 text-right text-white_A700 w-[28px]"
            variant="body18"
          >
            
            {/* 10 */}
          </Text>

         
        </div>
        <div className="flex flex-row gap-[15px] items-center justify-start py-[10px] w-[auto]">
           <ButtonSignOut backgroundColor="#228DF1"/>
          <Img
            src="images/img_usericon.png"
            className="h-[42px] md:h-[auto] sm:h-[auto] rounded-radius50 w-[42px]"
            alt="UserIcon"
          />
          <div className="flex flex-col gap-[5px] items-start justify-start w-[auto]">
            <Text
              className="font-semibold text-gray_900 text-left w-[auto]"
              variant="body15"
            >
              <div id="user-name"></div>
            </Text>
            <Text
              className="font-light text-blue_500 text-left w-[auto]"
              variant="body16"
            >
              <div id="user-email"></div>
            </Text>
          </div>
          <Img
            src="images/img_interfaceessentialdots.svg"
            className="h-[24px] w-[24px]"
            alt="InterfaceEssentialdots"
          />
        </div>
      </div>
      <Line className="bg-blue_500_6c h-[1px] mt-[30px] w-[100%]" />
    </>
  );
};
export default Header;
