import React, { useEffect, useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import Header from "../../components/NewHeader/Header";
import { BlueNavigationBar } from "../../components/NewBlueNavigationBarLeft/navigationBox";
import "./swagger-custom-styles.css";
import { prepareHeaderInstance } from "../../components/userAuth";
import { data } from "autoprefixer";

const baseurl = process.env.REACT_APP_BASE_URL;

const Swagger = () => {
  const [token, setToken] = useState(null);
  const url = baseurl.concat("swaggerrouter");
  const [swaggerUrl, setSwaggerUrl] = useState('');

  async function getUserInfo() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userInfo = {
        username: user.username,
        email: user.attributes.email,
        phone: user.attributes.phone_number,
        name: user.attributes.name,
      };
      return userInfo;
    } catch (error) {
      console.log("Error getting user info: ", error);
    }
  }

  useEffect(() => {
    getUserInfo()
      .then((userInfo) => {
        document.getElementById("user-email").innerHTML = userInfo.name;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    const fetchToken = async () => {
      const session = await Auth.currentSession();
      setToken(session.getIdToken().getJwtToken());
    };

    fetchToken();
  }, []);


  useEffect(() => {
    async function loadDataSwagger() {
      try {
        const axiosInstance = await prepareHeaderInstance();
        data = await axiosInstance.get(url);
        if (data.data.message)
          setSwaggerUrl(data.data.message);
        else
          console.log("Error getting swagger infos: ", data);
      } catch (error) {
        console.log("Error getting swagger infos: ", error);
      }
    }

    loadDataSwagger();
  }, []);

  const renderSwaggerIframe = () => {
    return (
      <iframe
        src={swaggerUrl}
        title="Swagger UI"
        style={{ width: '50%', height: '100vh' ,border: 'none'}}
      ></iframe>
    );
  };

  return (
    <>
      <div className="flex">
        <BlueNavigationBar />
        <div className="flex-1">
          <div className="mb-[20px]">
            <Header title="Swagger" subtitle="Upload and download your data" />
          </div>
         
   <div style={{ overflow: 'hidden', height: '100vh' }}>
  <div className="w-full flex">
    <iframe
      src={swaggerUrl}
      title="Swagger UI"
      style={{ width: '50%', height: '100%', border: 'none' }}
    ></iframe>
    
    {token ? renderSwaggerIframe() : <p>Loading...</p>}
  </div>
</div>
        </div>
      </div>
    </>
  );
};

export default withAuthenticator(Swagger, { hideSignUp: true });
