import { Img } from "../Img";
import { Line } from "../Line";
import { Text } from "../Text";
import logo from "../../assets/images/logo.svg";
import React from "react";
import {
  handleNavigateEventMonitoring,
  handleNavigateSupport,
  handleNavigateSecurity,
  handleNavigateDashboard,
  handleNavigateSettings,
  handleNavigateWorkspace,
  handleNavigateUploadData,
  handleNavigatePaymentSettings,
  handleNavigateSwagger,
  handleNavigateDataCatalog,
} from "./navigateHandler";

export function BlueNavigationBar() {
  return (
    <div>
      <aside className="flex flex-col md:hidden sm:hidden w-[333px]">
        <div className="flex flex-col items-center justify-start w-[100%]">
          <div className="bg-gray_900 flex flex-col gap-[23px] justify-start py-[22px] rounded-bl-[0] rounded-br-[0] rounded-tl-[0] rounded-tr-[32px] w-[100%]">
            <div className="h-[38px] md:h-[auto] sm:h-[auto] md:ml-[0] sm:ml-[0] ml-[46px] relative sm:w-[100%] w-[32%]">
              <img src={logo} alt="Logo" height="80px" />
            </div>
            <div className="flex flex-col font-poppins gap-[19px] items-center justify-start mb-[378px] w-[100%]">
              <Line className="bg-blue_500_75 h-[1px] w-[100%]" />
              <div className="flex flex-col gap-[20px] items-center justify-start w-[auto]">
                <div className="flex flex-col gap-[5px] items-start justify-start w-[auto]">
                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <Img
                      src="images/img_mail_blue_500.svg"
                      className="h-[28px] w-[28px]"
                      alt="mail"
                    />
                    <Text
                      className="cursor-pointer font-medium text-left text-white_A700 w-[auto] " // here
                      variant="body13"
                      onClick={() => {
                        handleNavigateWorkspace();
                      }}
                    >
                      Workspace
                    </Text>
                  </div>

                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <Img
                      src="images/img_signal_blue_500.svg"
                      className="h-[28px] w-[28px]"
                      alt="signal One"
                    />
                    <Text
                      className="cursor-pointer font-medium text-left text-white_A700 w-[auto]"
                      variant="body13"
                      onClick={() => {
                        handleNavigateDataCatalog();
                      }}
                    >
                      Data Catalog
                    </Text>
                  </div>
                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <Img
                      src="images/img_signal_blue_500.svg"
                      className="h-[28px] w-[28px]"
                      alt="signal Two"
                    />
                    <Text
                      className="cursor-pointer font-medium text-left text-white_A700 w-[auto]"
                      variant="body13"
                      onClick={() => {
                        handleNavigateUploadData();
                      }}
                    >
                      Upload Data
                    </Text>
                  </div>
                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <Img
                      src="images/img_blockscodetestcheckmark.svg"
                      className="h-[28px] w-[28px]"
                      alt="blockscodetestcheckmark"
                    />
                    <Text
                      className="cursor-pointer font-medium text-left text-white_A700 w-[auto]"
                      variant="body13"
                      onClick={() => {
                        handleNavigateEventMonitoring();
                      }}
                    >
                      Event Monitoring
                    </Text>
                  </div>

                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <Img
                      src="images/img_blockscodetestcheckmark.svg"
                      className="h-[28px] w-[28px]"
                      alt="blockscodetestcheckmark"
                    />
                    <Text
                      className="cursor-pointer font-medium text-left text-white_A700 w-[auto]"
                      variant="body13"
                      onClick={() => {
                        handleNavigateDashboard();
                      }}
                    >
                      Dashboard
                    </Text>
                  </div>

                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <div className="flex flex-row gap-[20px] items-center justify-start w-[auto]">
                      <Img
                        src="images/img_blockscodetestcheckmark.svg"
                        className="h-[28px] w-[28px]"
                        alt="blockscodetestcheckmark"
                      />
                      <Text
                        className="cursor-pointer font-medium text-left text-white_A700 w-[auto]"
                        variant="body13"
                        onClick={() => {
                          handleNavigateSwagger();
                        }}
                      >
                        Swagger
                      </Text>
                    </div>
                  </div>

                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <Img
                      src="images/img_file_blue_500.svg"
                      className="h-[28px] w-[28px]"
                      alt="file One"
                    />
                    <Text
                      className="cursor-pointer font-medium text-left text-white_A700 w-[auto]"
                      variant="body13"
                      onClick={() => {
                        handleNavigateSettings();
                      }}
                    >
                      Settings
                    </Text>
                  </div>
                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <Img
                      src="images/img_group.svg"
                      className="h-[28px] w-[28px]"
                      alt="Group"
                    />
                    <Text
                      className="cursor-pointer font-medium text-left text-white_A700 w-[auto]"
                      variant="body13"
                      onClick={() => {
                        handleNavigateSecurity();
                      }}
                    >
                      Security
                    </Text>
                  </div>
                </div>
                <Line className="bg-blue_500 h-[1px] w-[100%]" />
                <div className="flex flex-col gap-[5px] items-start justify-start w-[auto]">
                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <Img
                      src="images/img_paymentsfinan.svg"
                      className="h-[100%] w-[28px]"
                      alt="PaymentsFinan"
                    />
                    <Text
                      className="cursor-pointer font-medium text-left text-white_A700 w-[auto]"
                      variant="body13"
                      onClick={() => {
                        handleNavigatePaymentSettings();
                      }}
                    >
                      Payment settings
                    </Text>
                  </div>
                  <div className="flex flex-row gap-[20px] h-[64px] md:h-[auto] sm:h-[auto] items-center justify-start px-[20px] w-[290px]">
                    <Img
                      src="images/img_file_blue_500_28x28.svg"
                      className="h-[28px] w-[28px]"
                      alt="file Two"
                    />
                    <Text
                      className="cursor-pointer font-medium text-left text-white_A700 w-[auto]"
                      variant="body13"
                      onClick={() => {
                        handleNavigateSupport();
                      }}
                    >
                      Support
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}
