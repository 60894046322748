import { Collection, withAuthenticator } from "@aws-amplify/ui-react";
import { BlueNavigationBar } from "../../components/NewBlueNavigationBarLeft/navigationBox";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { LogEvent } from "../../ui-components";
import Header from "../../components/NewHeader/Header";
import { prepareHeaderInstance } from "../../components/userAuth";
import { useLocation } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";

const EventmoniPage = () => {
  const newcolumns = [
  { field: "UUID", headerName: "UUID", width: 250 },
  { field: "Runtime", headerName: "Runtime", width: 180 },
  { field: "StatusCode", headerName: "StatusCode", width: 180 },
  { field: "User", headerName: "User", width: 180 },
  { field: "Message", headerName: "Message", width: 600 },
];

  const baseurl = process.env.REACT_APP_BASE_URL;
   const location = useLocation();
  const variableName = new URLSearchParams(location.search).get('showMenu');

 console.log("variableNamevariableNamevariableName",variableName);
 const [isSideMenu,setIsSideMenu]=useState(variableName)
  // get user info
  const [columns, setColumns] = useState(isSideMenu?"repeat(1, minmax(250px, 1fr))":"repeat(2, minmax(250px, 1fr))");


  async function getUserInfo() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return {
        username: user.username,
        email: user.attributes.email,
        phone: user.attributes.phone_number,
      };
    } catch (error) {
      console.log("Error getting user info: ", error);
    }
  }

  getUserInfo()
    .then((userInfo) => {
      document.getElementById("user-email").innerHTML = userInfo.email;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosInstance = await prepareHeaderInstance();
        const response = await axiosInstance.get(baseurl.concat("getLogEvents"));
        setTableData(response.data);
      console.log(">>>>>>>>>Dxsdsdsdsdsdsdsd",response.data)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


   const createRows = tableData.map((row, index) => {
    const newRow = {
      id: index,
      UUID: "UUID",
      Runtime: "runtime",
      StatusCode:"StatusCode",
      User:'User',
      Message:'Message'
    };
console.log(">>>>>>>>>>>>>>>>>>>>row",row)
    if (row.UUID != null) {
      newRow.UUID = row.UUID;
    }
    if (row.Runtime != null) {
      newRow.Runtime = row.Runtime;
    }
    if (row.StatusCode!= null) {
      newRow.StatusCode = row.StatusCode;
    }
    if (row.User != null) {
      newRow.User = row.User;
    }
    if (row.Message != null) {
      newRow.Message = row.Message;
    }
    return newRow;
  });
const MainView=()=>{
  return(
        <>

      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <BlueNavigationBar></BlueNavigationBar>

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header title="Event Monitoring" subtitle="See all your events" />

          <main>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-12 grid-rows-auto gap-20 mt-10 ml-10 mr-10">
              <div className="h-[1000px] col-span-12 row-span-5 justify-center bg-primary-400 p-30 bg-gray-100 ">
                <div className="flex justify-center mt-10">
                  <Collection
                    items={tableData}
                    
                    type="grid"
                    isSearchable={true}
                    isPaginated={true}
                    templateColumns="repeat(3, minmax(250px, 1fr))"
                    itemsPerPage={12}
                    // templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
                    autoFlow="row"
                    gap="20px"
                    wrap="nowrap"
            
                  >
                    {(item) => (
                      <LogEvent
                        eventDate={item.Runtime.slice(0, 16)}
                        eventStatus={item.StatusCode}
                        eventOwner={item.User}
                        eventMessage={item.Message}
                      />
                    )}
                  </Collection>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}



const mediaQuery = window.matchMedia("(max-width: 1470px)");

function handleMediaQueryChange(event) {
  if (event.matches) {
    setColumns("repeat(1, minmax(250px, 1fr))");
  } else {
    setColumns("repeat(2, minmax(250px, 1fr))");
  }
}

useEffect(() => {
  console.log(">>>>isSideMenu",isSideMenu);
  if(!isSideMenu){
  mediaQuery.addEventListener('change', handleMediaQueryChange);

  if (window.innerWidth <= 1070) {
    setColumns("repeat(1, minmax(250px, 1fr))");
  } else {
    setColumns("repeat(2, minmax(250px, 1fr))");
  }

  return () => {
    mediaQuery.removeEventListener('change', handleMediaQueryChange);
  }
  }
  else{
   setColumns( "repeat(1, minmax(250px, 1fr))")
  }
}, [isSideMenu]);


  return (
    <>
      <div 
      className="flex "
      >
    
   
    <BlueNavigationBar setIsSideMenuShow={setIsSideMenu}/>

      <div className="flex-1 ">
     <div  className="mb-[20px]">
          <Header title="Event Monitoring" subtitle="See all your events" />
</div>

    <div className="flex items-center justify-center">
     <div style={{ height: "850px", width: "90%",marginLeft:10, }}>
                <DataGrid
                  rows={createRows}
                  columns={newcolumns}
                  pageSize={12}
                  rowsPerPageOptions={[12]}
                />
              </div>
              </div>
   {/* <div className="flex justify-center mt-10 mx-[10px]  rounded-[34px]">

     
  <Collection
    items={tableData}
      type="grid"
     isSearchable={true}
      isPaginated={true}
     templateColumns={columns}
     itemsPerPage={12}
      autoFlow="row"
      gap="20px"
 
    style={{backgroundColor:'#e5e7eb',justifyItems:'center',  padding:10 ,borderRadius:24}}
     >
    {(item) => (<>

<div className=" bg-white flex flex-col justify-center w-[710px] p-2 h-[150px] border-[4px]">
       <p className="mb-[24px] "> {item.Message}</p>
       <div className="flex justify-between">
       <div className=" flex flex-col ">
          <span className="text-[11px] text-[#6b6b6b] font-red ">Publication date</span>
  <span>{item.Runtime.slice(0, 16)}</span>
  </div>

    <div className=" flex flex-col ">
          <span  className="text-[11px] text-[#6b6b6b]">User</span>
  <span>{item.StatusCode}</span>
  </div>
      <div className=" flex flex-col ">
          <span  className="text-[11px] text-[#6b6b6b] ">Status</span>
  <span>{item.User}</span>
  </div>
  </div> 
     </div>
 

     

      </>
    )}
  </Collection>
</div> */}
      </div>
</div>

{/* {MainView()} */}
</>
  );

};
export default withAuthenticator(EventmoniPage, { hideSignUp: true });