import React from "react";
import PropTypes from "prop-types";
const shapes = {
  RoundedBorder23: "rounded-radius23",
  RoundedBorder29: "rounded-radius295",
  icbRoundedBorder22: "rounded-radius22755"
};
const variants = {
  OutlineBlue500_1:
    "bg-white_A700 border border-blue_500 border-solid text-bluegray_900",
  OutlineBlue500: "border border-blue_500 border-solid text-white_A700",
  FillBlue500: "bg-blue_500 text-white_A700",
  OutlineLightblueA400:
    "border border-light_blue_A400 border-solid text-white_A700",
  OutlineTealA700: "border border-solid border-teal_A700 text-white_A700",
  OutlineIndigoA700: "border border-indigo_A700 border-solid text-white_A700",
  OutlineCyan700: "border border-cyan_700 border-solid text-white_A700",
  OutlineGray9003d: "bg-blue_500 shadow-bs text-white_A700",
  icbFillBlue500: "bg-blue_500"
};
const sizes = {
  sm: "p-[19px] md:p-[9px] sm:px-[15px] sm:py-[7px]",
  md: "md:p-[11px] p-[23px] sm:px-[15px] sm:py-[9px]",
  smIcn: "p-[11px] sm:p-[4px] md:p-[5px]"
};
const Button = ({
                  children,
                  className = "",
                  leftIcon,
                  rightIcon,
                  shape,
                  variant,
                  size,
                  ...restProps
                }) => {
  return (
    <button
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} `}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf([
    "RoundedBorder23",
    "RoundedBorder29",
    "icbRoundedBorder22"
  ]),
  variant: PropTypes.oneOf([
    "OutlineBlue500_1",
    "OutlineBlue500",
    "FillBlue500",
    "OutlineLightblueA400",
    "OutlineTealA700",
    "OutlineIndigoA700",
    "OutlineCyan700",
    "OutlineGray9003d",
    "icbFillBlue500"
  ]),
  size: PropTypes.oneOf(["sm", "md", "smIcn"])
};
Button.defaultProps = {
  className: "",
  shape: "RoundedBorder23",
  variant: "OutlineBlue500_1",
  size: "md"
};
export { Button };
