import React from "react";
import PropTypes from "prop-types";
const shapes = {
  RoundedBorder23: "rounded-radius23",
  RoundedBorder29: "rounded-radius29",
  RoundedBorder13: "rounded-radius13",
  RoundedBorder16: "rounded-radius16",
  RoundedBorder8: "rounded-radius8",
  icbRoundedBorder16: "rounded-radius16",
  icbRoundedBorder22: "rounded-radius22",
  icbCircleBorder12: "rounded-radius12"
};
const variants = {
  OutlineBlue500_1:
    "bg-white_A700 border border-blue_500 border-solid text-bluegray_900",
  OutlineBlue500: "border border-blue_500 border-solid text-white_A700",
  FillBlue500: "bg-blue_500 text-white_A700",
  OutlineLightblueA400:
    "border border-light_blue_A400 border-solid text-white_A700",
  OutlineTealA700: "border border-solid border-teal_A700 text-white_A700",
  OutlineIndigoA700: "border border-indigo_A700 border-solid text-white_A700",
  OutlineCyan700: "border border-cyan_700 border-solid text-white_A700",
  OutlineGray9003d: "bg-blue_500 shadow-bs text-white_A700",
  icbFillBlue500: "bg-blue_500",
  icbOutlineBluegray50:
    "bg-gray_53 border border-bluegray_50 border-solid shadow-bs1"
};
const sizes = {
  sm: "pr-[5px] py-[5px]",
  md: "pl-[6px] py-[6px]",
  lg: "pl-[9px] py-[9px]",
  xl: "p-[14px]",
  "2xl": "p-[19px]",
  "3xl": "p-[24px] sm:px-[20px]",
  smIcn: "p-[2px]",
  mdIcn: "p-[11px]",
  lgIcn: "p-[16px]"
};
const Button = ({
                  children,
                  className = "",
                  leftIcon,
                  rightIcon,
                  shape,
                  variant,
                  size,
                  ...restProps
                }) => {
  return (
    <button
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} `}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf([
    "RoundedBorder23",
    "RoundedBorder29",
    "icbRoundedBorder22",
    "icbCircleBorder12"
  ]),
  variant: PropTypes.oneOf([
    "OutlineBlue500_1",
    "OutlineBlue500",
    "FillBlue500",
    "OutlineLightblueA400",
    "OutlineTealA700",
    "OutlineIndigoA700",
    "OutlineCyan700",
    "OutlineGray9003d",
    "icbFillBlue500",
    "icbOutlineBluegray50"
  ]),
  size: PropTypes.oneOf([
    "sm",
    "md",
    "lg",
    "xl",
    "2xl",
    "3xl",
    "smIcn",
    "mdIcn",
    "lgIcn"
  ])
};
Button.defaultProps = {
  className: "",
  shape: "RoundedBorder5",
  variant: "OutlineBluegray50",
  size: "xl"
};
export { Button };