import React from 'react'

export default function TextInput(props) {
  return (
    <div className='flex bg-[#f8f8f9] w-[50%] rounded-full p-2 group-hover:bg-white hover:border-2 hover:border-[#379df8]'>
    <input
        type="text"
        className="bg-[#f8f8f9] text-gray-800 p-2 w-[90%] border-0 focus:bg-white  group-hover:bg-white "
        placeholder={props.placeHolder}
        onChange={(e)=>props.getValue(e.target.value)}
        value={props.value}
      />
  {props.image?  <img src={props.image}/>:null}
    </div>
  )
}
