import { data } from "autoprefixer";
import React, { useEffect, useState } from "react";
import { Img } from "../Img";
import { prepareHeaderInstance } from "../userAuth";



const baseurl = process.env.REACT_APP_BASE_URL;


async function getInitData() {
  const axiosInstance = await prepareHeaderInstance();
  data = await axiosInstance.get(baseurl.concat("getInitDatasSchema"));
  return data;
}

async function getNameDataObject(fileData, dataSteward, minorVersion, majorVersion) {
  const axiosInstance = await prepareHeaderInstance();
  let objInfo = {
    "dataSteward": dataSteward,
    "minor-version": minorVersion,
    "major-version": majorVersion
  };
  //delete first char from fileData
  fileData = fileData.substring(1);
  objInfo = JSON.stringify(objInfo);
  objInfo = objInfo.replace('}', ",");
  objInfo = objInfo.replace(',', "");
  fileData = objInfo.concat(fileData);
  data = await axiosInstance.post(baseurl.concat("getNameDataObject"), fileData);
  return data.data;
}

export function CreateSchemaInputBox(t_dataSteward) {
  const [minorVersion, setMinorVersion] = useState('');
  const [majorVersion, setMajorVersion] = useState('');
  const [dataSteward, setDataSteward] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [dataObjectName, setDataObjectName] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  function handleSchemaInput(input, dataSteward, minorVersion, majorVersion, dataObjectName) {
    try {
      JSON.parse(input);
      submitSchemaData(input, dataSteward, minorVersion, majorVersion, dataObjectName)
        .catch((error) => {
          console.log(error);
          alert('Fehler beim Hochladen', error);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      alert('Ungültige JSON-Eingabe', error);
      setIsLoading(false);
    }
  }

  async function submitSchemaData(fileData, dataSteward, minorVersion, majorVersion, dataObjectName) {
    // go through all keys in fileData and append them to formDat
    console.log("File Upload start!!!!");
    setIsLoading(true);
    let objInfo = {
      "dataObject": dataObjectName,
      "dataSteward": dataSteward,
      "minor-version": minorVersion,
      "major-version": majorVersion
    };
    //delete first char from fileData
    fileData = fileData.substring(1);
    objInfo = JSON.stringify(objInfo);
    objInfo = objInfo.replace('}', ",");
    fileData = objInfo.concat(fileData);
    const axiosInstance = await prepareHeaderInstance();
    axiosInstance
      .post(baseurl.concat("uploadSchema"), fileData)
      .then((res) => {
        alert("File Upload success");
        console.log(res);
        setIsLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 400)
          alert("Schema Upload Error");
        if (err.response.status === 409)
          alert("Schema already exists");
        setIsLoading(false);
      });
  }


  const handleDataStewardChange = (event) => {
    setDataSteward(event.target.value);
  };

  const handleDataObjectNameChange = (event) => {
    setDataObjectName(event.target.value);
  }

  const handleMinorVersionChange = (event) => {
    setMinorVersion(event.target.value);
  };

  const handleMajorVersionChange = (event) => {
    setMajorVersion(event.target.value);
  };
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await getInitData();
      const data = response.data;
      console.log(">>>>>>>>>>>dsddsddsdssdsdsdsdds>>data<<<<<<<<",data)
      setMinorVersion(data['minor-version']);
      setMajorVersion(data['major-version']);
      setDataObjectName(data['data-object-name']);
      delete data['minor-version'];
      delete data['major-version'];
      delete data['data-object-name'];
      setInputValue(JSON.stringify(data, null, 2));
      setIsLoading(false);

    }
    fetchData();
  }, []);

  if (dataSteward === '' && t_dataSteward.t_dataSteward !== '') {
    setDataSteward(t_dataSteward.t_dataSteward);
  }

  return (
    <>
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div className="flex flex-col gap-6 mt-8 w-full  ">

          <div className="flex flex-wrap space-x-4">
            <div className="textbox-container">
              <label htmlFor="data-steward-input" className="text-lg font-semibold block mb-1">
                Data Steward:
              </label>
              <input
                type="text"
                id="data-steward-input"
                name="DataSteward"
                className="w-full p-2 border border-gray-300 rounded"
                value={dataSteward}
                onChange={handleDataStewardChange}
              />
              <label htmlFor="data-object-name-input" className="text-lg font-semibold block mb-1">
                Data Object:
              </label>
              <input
                type="text"
                id="data-object-name-input"
                name="DataObject"
                className="w-full p-2 border border-gray-300 rounded"
                value={dataObjectName}
                onChange={handleDataObjectNameChange}
              />
            </div>
            <Img
              src="images/refresh-icon.svg"
              className="cursor-pointer h-8 w-8 mt-2 self-end ml-10"
              alt="rename"
              title="Rename dataObject"
              onClick={async () => {
                setIsLoading(true);
                const name = await getNameDataObject(inputValue, dataSteward, minorVersion, majorVersion);
                if (name !== '')
                  setDataObjectName(name);
                setIsLoading(false);
              }}
            />
            <div className="textbox-container">
              <label htmlFor="minor-version-input" className="text-lg font-semibold block mb-1">
                Minor Version:
              </label>
              <input
                type="text"
                id="minor-version-input"
                name="minorVersion"
                className="w-full p-2 border border-gray-300 rounded"
                value={minorVersion}
                onChange={handleMinorVersionChange}
              />
              <label htmlFor="major-version-input" className="text-lg font-semibold block mb-1">
                Major Version:
              </label>
              <input
                type="text"
                id="major-version-input"
                name="majorVersion"
                className="w-full p-2 border border-gray-300 rounded"
                value={majorVersion}
                onChange={handleMajorVersionChange}
              />
            </div>
            <Img
              src="images/img_upload_blue_500.svg"
              className="cursor-pointer h-8 w-8 mt-2 self-end ml-10"
              alt="upload"
              title="Upload schema"
              onClick={() => {
                const input = document.getElementById('json-input');
                setInputValue(input.value);
                handleSchemaInput(inputValue, dataSteward, minorVersion, majorVersion, dataObjectName);
              }}
            />
          </div>


          <div className="flex flex-col gap-2">
            <label htmlFor="json-input" className="text-lg font-semibold">
              Schema Generation:
            </label>
            <textarea
              id="json-input"
              className="w-full h-96 p-2 border border-gray-300 rounded"
              maxLength="500"
              value={inputValue}
              onChange={() => {
                const input = document.getElementById('json-input');
                setInputValue(input.value);
              }}
            />
            
          </div>

        </div>
      )}
    </>
  );
}