import React from "react";
import { Button, Img, List, Text } from "components";
const LatestnewsOnePage = () => {
  return (<>
    <div
      className="bg-white_A700 flex flex-col font-montserrat md:gap-[40px] sm:gap-[40px] gap-[90px] items-center justify-end mx-[auto] pt-[50px] w-[100%]"
    >
      <div
        className="flex flex-col items-start justify-start max-w-[1280px] mx-[auto] md:px-[20px] sm:px-[20px] w-[100%]"
      >
        <header
          className="flex items-center justify-center md:ml-[0] sm:ml-[0] ml-[2px] w-[100%]"
        >
          <div
            className="flex flex-1 md:flex-col sm:flex-col flex-row md:gap-[20px] sm:gap-[20px] items-center justify-center w-[100%]"
          >
            <div
              className="h-[49px] md:h-[auto] sm:h-[auto] relative md:w-[100%] sm:w-[100%] w-[11%]"
            >
              <div
                className="absolute flex flex-col h-[max-content] inset-y-[0] items-center justify-start my-[auto] right-[0] w-[80%]"
              >
                <div
                  className="flex flex-row gap-[5px] items-start justify-between w-[100%]"
                >
                  <div
                    className="flex flex-col justify-start mb-[2px] w-[auto]"
                  >
                    <ul
                      className="flex flex-row sm:hidden items-start justify-start md:w-[100%] sm:w-[100%] w-[50%] common-row-list"
                    >
                      <li className="sm:w-[100%] sm:my-[10px] w-[25%]"><Img
                        src=
                          "images/img_vector.svg"
                        className="h-[11px]"
                        alt="Vector"
                      /></li>
                      <li className="mt-[3px] mb-[1px] ml-[1px] sm:w-[100%] sm:my-[10px] w-[25%]"><Img
                        src=
                          "images/img_contrast.svg"
                        className="h-[8px]"
                        alt="contrast"
                      /></li>
                      <li className="ml-[1px] sm:w-[100%] sm:my-[10px] w-[10%] my-[1px]"><Img
                        src=
                          "images/img_vector_blue_500.svg"
                        className="h-[10px]"
                        alt="Vector One"
                      /></li>
                      <li className="mt-[3px] mb-[1px] ml-[1px] sm:w-[100%] sm:my-[10px] w-[25%]"><Img
                        src=
                          "images/img_contrast.svg"
                        className="h-[8px]"
                        alt="contrast One"
                      /></li>
                    </ul>
                    <div
                      className="flex flex-col items-start justify-start md:ml-[0] sm:ml-[0] ml-[10px] md:w-[100%] sm:w-[100%] w-[83%]"
                    >
                      <div
                        className="flex flex-row gap-[6px] items-start justify-between w-[100%]"
                      >
                        <div
                          className="bg-blue_500 h-[25px] w-[auto]"
                        ></div>
                        <Img
                          src=
                            "images/img_lock.svg"
                          className="h-[27px] w-[auto]"
                          alt="lock"
                        /></div>
                      <div
                        className="flex flex-row gap-[19px] items-center justify-between md:w-[100%] sm:w-[100%] w-[95%]"
                      ><Text
                        className="text-blue_500 text-left w-[auto]"
                        variant="body2"
                      >MAKE</Text><Text
                        className="text-blue_500 text-left w-[auto]"
                        variant="body2"
                      >ATA</Text></div>
                    </div>
                  </div>
                  <div
                    className="md:h-[40px] sm:h-[40px] h-[45px] mt-[3px] relative w-[auto]"
                  ><Text
                    className="absolute bottom-[4%] right-[11%] text-blue_500 text-left w-[auto]"
                    variant="body2"
                  >SIMPLE</Text>
                    <div
                      className="absolute bg-blue_500 h-[35px] right-[0] top-[0] w-[16%]"
                    ></div>
                    <Img
                      src=
                        "images/img_contrast_blue_500.svg"
                      className="absolute bottom-[0] h-[37px] left-[0] w-[auto]"
                      alt="contrast Two"
                    /></div>
                </div>
              </div>
              <div
                className="absolute bottom-[6%] h-[40px] md:h-[auto] sm:h-[auto] left-[0] w-[27%]"
              ><Img
                src=
                  "images/img_vector_14x24.png"
                className="absolute h-[14px] inset-y-[0] my-[auto] object-cover right-[0] w-[auto]"
                alt="Vector Four"
              /><Img
                src=
                  "images/img_vector_40x22.png"
                className="absolute h-[40px] inset-y-[0] left-[0] my-[auto] object-cover w-[auto]"
                alt="Vector Five"
              /></div>
            </div>
            <div
              className="flex md:flex-1 sm:flex-1 md:flex-col sm:flex-col flex-row font-poppins gap-[45px] items-start justify-start md:ml-[0] sm:ml-[0] ml-[130px] md:w-[100%] sm:w-[100%] w-[auto]"
            ><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Our ambition</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Problem</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Benefits</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Business Case</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Trust</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Career</Text></div>
            <div
              className="flex flex-row font-poppins gap-[20px] items-center justify-start md:ml-[0] sm:ml-[0] ml-[85px] w-[auto]"
            ><Img
              src=
                "images/img_location.svg"
              className="h-[32px] w-[32px]"
              alt="location"
            /><Button
              className="cursor-pointer font-medium min-w-[170px] text-[17px] text-bluegray_902 text-center tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              shape="RoundedBorder23"
              size="2xl"
              variant="OutlineBlue500"
            >Get started</Button></div>
          </div>
        </header>
        <div
          className="flex flex-col gap-[10px] items-start justify-start mt-[70px] sm:w-[100%] w-[auto]"
        ><Text
          className="font-bold font-poppins text-gray_900 text-left tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
          as="h5"
          variant="h5"
        >Latest news</Text><Text
          className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[548px] text-gray_901 text-left"
          variant="body13"
        >Is in the early founding phase to become the data platform to deliver data as a service without lead time and
          implementation.</Text></div>
        <List
          className="flex-col font-rubik gap-[40px] grid items-center mt-[60px] w-[100%]"
          orientation="vertical"
        >
          <div
            className="flex flex-1 flex-col items-center justify-start w-[100%]"
          >
            <div
              className="gap-[40px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 items-center justify-between w-[100%]"
            >
              <div
                className="bg-white_A700 border border-cyan_51 border-solid flex flex-1 flex-col gap-[20px] items-center justify-start rounded-radius31 w-[100%]"
              ><Img
                src=
                  "images/img_rectangle4560.png"
                className="h-[245px] sm:h-[auto] object-cover rounded-bl-[0] rounded-br-[0] rounded-tl-[31px] rounded-tr-[31px] w-[100%]"
                alt="Rectangle4560"
              />
                <div
                  className="flex flex-col gap-[15px] items-start justify-start mb-[18px] w-[auto]"
                >
                  <div
                    className="bg-blue_50 flex flex-row gap-[5.87px] items-center justify-center px-[13.5px] py-[7.04px] rounded-radius15 w-[auto]"
                  ><Img
                    src=
                      "images/img_signal.svg"
                    className="h-[14px] w-[14px]"
                    alt="signal"
                  /><Text
                    className="font-normal not-italic text-blue_500 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                    variant="body18"
                  >Admin</Text><Img
                    src=
                      "images/img_clock.svg"
                    className="h-[14px] w-[14px]"
                    alt="clock"
                  /><Text
                    className="font-normal not-italic text-blue_500 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                    variant="body18"
                  >April 14, 2022</Text></div>
                  <div
                    className="flex flex-col gap-[11.74px] items-start justify-start w-[auto]"
                  ><Text
                    className="font-bold font-poppins leading-[140.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[232px] text-gray_901 text-left tracking-ls0341 md:tracking-ls11 sm:tracking-ls11"
                    variant="body13"
                  >CeFi Vs Defi: Which is Better? Should They Work Together?</Text><Text
                    className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[240px] text-gray_901 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11"
                    variant="body18"
                  >CeFi Vs DeFi: Should they work together? This way, more people will be able to access the crypto
                    market, driving crypto adoption further, says Austin Kimm, Director of Strategy and Investments at
                    Choise.com.</Text><Button
                    className="cursor-pointer font-medium font-poppins min-w-[98px] text-[9.98px] text-center text-white_A700 tracking-ls019954149246215822 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    shape="RoundedBorder13"
                    variant="FillBlue500"
                  >Read more</Button></div>
                </div>
              </div>
              <div
                className="bg-white_A700 border border-cyan_51 border-solid flex flex-1 flex-col gap-[20px] items-center justify-start rounded-radius32 w-[100%]"
              ><Img
                src=
                  "images/img_rectangle4560_245x288.png"
                className="h-[245px] sm:h-[auto] object-cover rounded-bl-[0] rounded-br-[0] rounded-tl-[31px] rounded-tr-[31px] w-[100%]"
                alt="Rectangle4560 One"
              />
                <div
                  className="flex flex-col gap-[15px] items-start justify-start mb-[18px] w-[auto]"
                >
                  <div
                    className="bg-blue_50 flex flex-row gap-[5.87px] items-center justify-center px-[13.5px] py-[7.04px] rounded-radius15 w-[auto]"
                  ><Img
                    src=
                      "images/img_signal.svg"
                    className="h-[14px] w-[14px]"
                    alt="signal One"
                  /><Text
                    className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                    variant="body18"
                  >Admin</Text><Img
                    src=
                      "images/img_clock.svg"
                    className="h-[14px] w-[14px]"
                    alt="clock One"
                  /><Text
                    className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                    variant="body18"
                  >April 14, 2022</Text></div>
                  <div
                    className="flex flex-col gap-[11.74px] items-start justify-start w-[auto]"
                  ><Text
                    className="font-bold font-poppins leading-[140.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[245px] text-gray_901 text-left tracking-ls0341 md:tracking-ls11 sm:tracking-ls11"
                    variant="body13"
                  >Interview with Vladimir Gorbunov about Boost your converion</Text><Text
                    className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[245px] text-gray_901 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11"
                    variant="body18"
                  >CeFi Vs DeFi: Should they work together? This way, more people will be able to access the crypto
                    market, driving crypto adoption further, says Austin Kimm, Director of Strategy and Investments at
                    Choise.com.</Text><Button
                    className="cursor-pointer font-medium font-poppins min-w-[98px] text-[9.98px] text-center text-white_A700 tracking-ls019954149246215822 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    shape="RoundedBorder13"
                    variant="FillBlue500"
                  >Read more</Button></div>
                </div>
              </div>
              <div
                className="bg-white_A700 border border-cyan_51 border-solid flex flex-1 flex-col gap-[20px] items-center justify-start rounded-radius32 w-[100%]"
              ><Img
                src=
                  "images/img_rectangle4560_1.png"
                className="h-[245px] sm:h-[auto] object-cover rounded-bl-[0] rounded-br-[0] rounded-tl-[31px] rounded-tr-[31px] w-[100%]"
                alt="Rectangle4560 Two"
              />
                <div
                  className="flex flex-col gap-[15px] items-start justify-start mb-[18px] w-[auto]"
                >
                  <div
                    className="bg-blue_50 flex flex-row gap-[5.87px] items-center justify-center px-[13.5px] py-[7.04px] rounded-radius15 w-[auto]"
                  ><Img
                    src=
                      "images/img_signal.svg"
                    className="h-[14px] w-[14px]"
                    alt="signal Two"
                  /><Text
                    className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                    variant="body18"
                  >Admin</Text><Img
                    src=
                      "images/img_clock.svg"
                    className="h-[14px] w-[14px]"
                    alt="clock Two"
                  /><Text
                    className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                    variant="body18"
                  >April 14, 2022</Text></div>
                  <div
                    className="flex flex-col gap-[11.74px] items-start justify-start w-[auto]"
                  ><Text
                    className="font-bold font-poppins leading-[140.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[232px] text-gray_901 text-left tracking-ls0341 md:tracking-ls11 sm:tracking-ls11"
                    variant="body13"
                  >CeFi Vs Defi: Which is Better? Should They Work Together?</Text><Text
                    className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[241px] text-gray_901 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11"
                    variant="body18"
                  >CeFi Vs DeFi: Should they work together? This way, more people will be able to access the crypto
                    market, driving crypto adoption further, says Austin Kimm, Director of Strategy and Investments at
                    Choise.com.</Text><Button
                    className="cursor-pointer font-medium font-poppins min-w-[98px] text-[9.98px] text-center text-white_A700 tracking-ls019954149246215822 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    shape="RoundedBorder13"
                    variant="FillBlue500"
                  >Read more</Button></div>
                </div>
              </div>
              <div
                className="bg-white_A700 border border-cyan_51 border-solid flex flex-1 flex-col gap-[20px] items-center justify-start rounded-radius32 w-[100%]"
              ><Img
                src=
                  "images/img_rectangle4560_2.png"
                className="h-[245px] sm:h-[auto] object-cover rounded-bl-[0] rounded-br-[0] rounded-tl-[31px] rounded-tr-[31px] w-[100%]"
                alt="Rectangle4560 Three"
              />
                <div
                  className="flex flex-col gap-[15px] items-start justify-start mb-[18px] w-[auto]"
                >
                  <div
                    className="bg-blue_50 flex flex-row gap-[5.87px] items-center justify-center px-[13.5px] py-[7.04px] rounded-radius15 w-[auto]"
                  ><Img
                    src=
                      "images/img_signal.svg"
                    className="h-[14px] w-[14px]"
                    alt="signal Three"
                  /><Text
                    className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                    variant="body18"
                  >Admin</Text><Img
                    src=
                      "images/img_clock.svg"
                    className="h-[14px] w-[14px]"
                    alt="clock Three"
                  /><Text
                    className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                    variant="body18"
                  >April 14, 2022</Text></div>
                  <div
                    className="flex flex-col gap-[11.74px] items-start justify-start w-[auto]"
                  ><Text
                    className="font-bold font-poppins leading-[140.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[232px] text-gray_901 text-left tracking-ls0341 md:tracking-ls11 sm:tracking-ls11"
                    variant="body13"
                  >CeFi Vs Defi: Which is Better? Should They Work Together?</Text><Text
                    className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[241px] text-gray_901 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11"
                    variant="body18"
                  >CeFi Vs DeFi: Should they work together? This way, more people will be able to access the crypto
                    market, driving crypto adoption further, says Austin Kimm, Director of Strategy and Investments at
                    Choise.com.</Text><Button
                    className="cursor-pointer font-medium font-poppins min-w-[98px] text-[9.98px] text-center text-white_A700 tracking-ls019954149246215822 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    shape="RoundedBorder13"
                    variant="FillBlue500"
                  >Read more</Button></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="md:flex-1 sm:flex-1 gap-[40px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 items-start justify-start md:w-[100%] sm:w-[100%] w-[auto]"
          >
            <div
              className="bg-white_A700 border border-cyan_51 border-solid flex flex-col gap-[20px] items-center justify-start rounded-radius31 w-[100%]"
            ><Img
              src=
                "images/img_rectangle4560_3.png"
              className="h-[245px] sm:h-[auto] object-cover rounded-bl-[0] rounded-br-[0] rounded-tl-[31px] rounded-tr-[31px] w-[100%]"
              alt="Rectangle4560 One"
            />
              <div
                className="flex flex-col gap-[15px] items-start justify-start mb-[18px] w-[auto]"
              >
                <div
                  className="bg-blue_50 flex flex-row gap-[5.87px] items-center justify-center px-[13.5px] py-[7.04px] rounded-radius15 w-[auto]"
                ><Img
                  src=
                    "images/img_signal.svg"
                  className="h-[14px] w-[14px]"
                  alt="signal One"
                /><Text
                  className="font-normal not-italic text-blue_500 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                  variant="body18"
                >Admin</Text><Img
                  src=
                    "images/img_clock.svg"
                  className="h-[14px] w-[14px]"
                  alt="clock One"
                /><Text
                  className="font-normal not-italic text-blue_500 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                  variant="body18"
                >April 14, 2022</Text></div>
                <div
                  className="flex flex-col gap-[11.74px] items-start justify-start w-[auto]"
                ><Text
                  className="font-bold font-poppins leading-[140.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[232px] text-gray_901 text-left tracking-ls0341 md:tracking-ls11 sm:tracking-ls11"
                  variant="body13"
                >CeFi Vs Defi: Which is Better? Should They Work Together?</Text><Text
                  className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[249px] text-gray_901 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11"
                  variant="body18"
                >CeFi Vs DeFi: Should they work together? This way, more people will be able to access the crypto
                  market, driving crypto adoption further, says Austin Kimm, Director of Strategy and Investments at
                  Choise.com.</Text><Button
                  className="cursor-pointer font-medium font-poppins min-w-[98px] text-[9.98px] text-center text-white_A700 tracking-ls019954149246215822 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  shape="RoundedBorder13"
                  variant="FillBlue500"
                >Read more</Button></div>
              </div>
            </div>
            <div
              className="bg-white_A700 border border-cyan_51 border-solid flex flex-col gap-[20px] items-center justify-start rounded-radius32 w-[100%]"
            ><Img
              src=
                "images/img_rectangle4560_4.png"
              className="h-[245px] sm:h-[auto] object-cover rounded-bl-[0] rounded-br-[0] rounded-tl-[31px] rounded-tr-[31px] w-[100%]"
              alt="Rectangle4560 One One"
            />
              <div
                className="flex flex-col gap-[15px] items-start justify-start mb-[18px] w-[auto]"
              >
                <div
                  className="bg-blue_50 flex flex-row gap-[5.87px] items-center justify-center px-[13.5px] py-[7.04px] rounded-radius15 w-[auto]"
                ><Img
                  src=
                    "images/img_signal.svg"
                  className="h-[14px] w-[14px]"
                  alt="signal One One"
                /><Text
                  className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                  variant="body18"
                >Admin</Text><Img
                  src=
                    "images/img_clock.svg"
                  className="h-[14px] w-[14px]"
                  alt="clock One One"
                /><Text
                  className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                  variant="body18"
                >April 14, 2022</Text></div>
                <div
                  className="flex flex-col gap-[11.74px] items-start justify-start w-[auto]"
                ><Text
                  className="font-bold font-poppins leading-[140.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[232px] text-gray_901 text-left tracking-ls0341 md:tracking-ls11 sm:tracking-ls11"
                  variant="body13"
                >CeFi Vs Defi: Which is Better? Should They Work Together?</Text><Text
                  className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[243px] text-gray_901 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11"
                  variant="body18"
                >CeFi Vs DeFi: Should they work together? This way, more people will be able to access the crypto
                  market, driving crypto adoption further, says Austin Kimm, Director of Strategy and Investments at
                  Choise.com.</Text><Button
                  className="cursor-pointer font-medium font-poppins min-w-[98px] text-[9.98px] text-center text-white_A700 tracking-ls019954149246215822 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  shape="RoundedBorder13"
                  variant="FillBlue500"
                >Read more</Button></div>
              </div>
            </div>
            <div
              className="bg-white_A700 border border-cyan_51 border-solid flex flex-col gap-[20px] items-center justify-start rounded-radius32 w-[100%]"
            ><Img
              src=
                "images/img_rectangle4560_5.png"
              className="h-[245px] sm:h-[auto] object-cover rounded-bl-[0] rounded-br-[0] rounded-tl-[31px] rounded-tr-[31px] w-[100%]"
              alt="Rectangle4560 Two One"
            />
              <div
                className="flex flex-col gap-[15px] items-start justify-start mb-[18px] w-[auto]"
              >
                <div
                  className="bg-blue_50 flex flex-row gap-[5.87px] items-center justify-center px-[13.5px] py-[7.04px] rounded-radius15 w-[auto]"
                ><Img
                  src=
                    "images/img_signal.svg"
                  className="h-[14px] w-[14px]"
                  alt="signal Two One"
                /><Text
                  className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                  variant="body18"
                >Admin</Text><Img
                  src=
                    "images/img_clock.svg"
                  className="h-[14px] w-[14px]"
                  alt="clock Two One"
                /><Text
                  className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                  variant="body18"
                >April 14, 2022</Text></div>
                <div
                  className="flex flex-col gap-[11.74px] items-start justify-start w-[auto]"
                ><Text
                  className="font-bold font-poppins leading-[140.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[246px] text-gray_901 text-left tracking-ls0341 md:tracking-ls11 sm:tracking-ls11"
                  variant="body13"
                >Interview with Vladimir Gorbunov about Boost your converion</Text><Text
                  className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[246px] text-gray_901 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11"
                  variant="body18"
                >CeFi Vs DeFi: Should they work together? This way, more people will be able to access the crypto
                  market, driving crypto adoption further, says Austin Kimm, Director of Strategy and Investments at
                  Choise.com.</Text><Button
                  className="cursor-pointer font-medium font-poppins min-w-[98px] text-[9.98px] text-center text-white_A700 tracking-ls019954149246215822 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  shape="RoundedBorder13"
                  variant="FillBlue500"
                >Read more</Button></div>
              </div>
            </div>
            <div
              className="bg-white_A700 border border-cyan_51 border-solid flex flex-col gap-[20px] items-center justify-start rounded-radius32 w-[100%]"
            ><Img
              src=
                "images/img_rectangle4560_6.png"
              className="h-[245px] sm:h-[auto] object-cover rounded-bl-[0] rounded-br-[0] rounded-tl-[31px] rounded-tr-[31px] w-[100%]"
              alt="Rectangle4560 Three One"
            />
              <div
                className="flex flex-col gap-[15px] items-start justify-start mb-[18px] w-[auto]"
              >
                <div
                  className="bg-blue_50 flex flex-row gap-[5.87px] items-center justify-center px-[13.5px] py-[7.04px] rounded-radius15 w-[auto]"
                ><Img
                  src=
                    "images/img_signal.svg"
                  className="h-[14px] w-[14px]"
                  alt="signal Three One"
                /><Text
                  className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                  variant="body18"
                >Admin</Text><Img
                  src=
                    "images/img_clock.svg"
                  className="h-[14px] w-[14px]"
                  alt="clock Three One"
                /><Text
                  className="font-normal not-italic text-left text-light_blue_A700 tracking-ls0241 md:tracking-ls11 sm:tracking-ls11 w-[auto]"
                  variant="body18"
                >April 14, 2022</Text></div>
                <div
                  className="flex flex-col gap-[11.74px] items-start justify-start w-[auto]"
                ><Text
                  className="font-bold font-poppins leading-[140.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[232px] text-gray_901 text-left tracking-ls0341 md:tracking-ls11 sm:tracking-ls11"
                  variant="body13"
                >CeFi Vs Defi: Which is Better? Should They Work Together?</Text><Text
                  className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[250px] text-gray_901 text-left tracking-ls0241 md:tracking-ls11 sm:tracking-ls11"
                  variant="body18"
                >CeFi Vs DeFi: Should they work together? This way, more people will be able to access the crypto
                  market, driving crypto adoption further, says Austin Kimm, Director of Strategy and Investments at
                  Choise.com.</Text><Button
                  className="cursor-pointer font-medium font-poppins min-w-[98px] text-[9.98px] text-center text-white_A700 tracking-ls019954149246215822 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                  shape="RoundedBorder13"
                  variant="FillBlue500"
                >Read more</Button></div>
              </div>
            </div>
          </div>
        </List></div>
      <footer
        className="bg-gray_900 flex font-poppins items-center justify-center w-[100%]"
      >
        <div
          className="flex flex-col items-center justify-center mb-[47px] mt-[50px] mx-[auto] w-[67%]"
        >
          <div
            className="flex flex-col gap-[50px] items-center justify-center w-[100%]"
          >
            <div
              className="flex md:flex-col sm:flex-col flex-row md:gap-[20px] sm:gap-[20px] items-center justify-start w-[100%]"
            >
              <div
                className="font-montserrat h-[49px] md:h-[auto] sm:h-[auto] relative md:w-[100%] sm:w-[100%] w-[11%]"
              >
                <div
                  className="absolute flex flex-col h-[max-content] inset-y-[0] items-center justify-start my-[auto] right-[0] w-[80%]"
                >
                  <div
                    className="flex flex-row gap-[5px] items-start justify-between w-[100%]"
                  >
                    <div
                      className="flex flex-col justify-start mb-[2px] w-[auto]"
                    >
                      <div
                        className="flex flex-row items-start justify-start md:w-[100%] sm:w-[100%] w-[50%]"
                      ><Img
                        src=
                          "images/img_vector.svg"
                        className="h-[11px] w-[auto]"
                        alt="Vector Six"
                      /><Img
                        src=
                          "images/img_contrast.svg"
                        className="h-[8px] mt-[3px] w-[8px]"
                        alt="contrast Three"
                      /><Img
                        src=
                          "images/img_vector_blue_500.svg"
                        className="h-[10px] w-[auto]"
                        alt="Vector Seven"
                      /><Img
                        src=
                          "images/img_contrast.svg"
                        className="h-[8px] mt-[3px] w-[8px]"
                        alt="contrast Four"
                      /></div>
                      <div
                        className="flex flex-col items-start justify-start md:ml-[0] sm:ml-[0] ml-[10px] md:w-[100%] sm:w-[100%] w-[83%]"
                      >
                        <div
                          className="flex flex-row gap-[6px] items-start justify-between w-[100%]"
                        >
                          <div
                            className="bg-blue_500 h-[25px] w-[auto]"
                          ></div>
                          <Img
                            src=
                              "images/img_lock.svg"
                            className="h-[27px] w-[auto]"
                            alt="lock One"
                          /></div>
                        <div
                          className="flex flex-row gap-[19px] items-center justify-between md:w-[100%] sm:w-[100%] w-[95%]"
                        ><Text
                          className="text-blue_500 text-left w-[auto]"
                          variant="body2"
                        >MAKE</Text><Text
                          className="text-blue_500 text-left w-[auto]"
                          variant="body2"
                        >ATA</Text></div>
                      </div>
                    </div>
                    <div
                      className="md:h-[40px] sm:h-[40px] h-[45px] mt-[3px] relative w-[auto]"
                    ><Text
                      className="absolute bottom-[4%] right-[11%] text-blue_500 text-left w-[auto]"
                      variant="body2"
                    >SIMPLE</Text>
                      <div
                        className="absolute bg-blue_500 h-[35px] right-[0] top-[0] w-[16%]"
                      ></div>
                      <Img
                        src=
                          "images/img_contrast_blue_500.svg"
                        className="absolute bottom-[0] h-[37px] left-[0] w-[auto]"
                        alt="contrast Five"
                      /></div>
                  </div>
                </div>
                <div
                  className="absolute bottom-[6%] h-[40px] md:h-[auto] sm:h-[auto] left-[0] w-[27%]"
                ><Img
                  src=
                    "images/img_vector_14x24.png"
                  className="absolute h-[14px] inset-y-[0] my-[auto] object-cover right-[0] w-[auto]"
                  alt="Vector Ten"
                /><Img
                  src=
                    "images/img_vector_40x22.png"
                  className="absolute h-[40px] inset-y-[0] left-[0] my-[auto] object-cover w-[auto]"
                  alt="Vector Eleven"
                /></div>
              </div>
              <div
                className="flex md:flex-1 sm:flex-1 md:flex-col sm:flex-col flex-row font-poppins gap-[45px] items-start justify-start md:ml-[0] sm:ml-[0] ml-[114px] md:w-[100%] sm:w-[100%] w-[auto]"
              ><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Our ambition</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Problem</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Benefits</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Business Case</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Trust</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Career</Text></div>
              <Button
                className="cursor-pointer font-medium font-poppins min-w-[231px] md:ml-[0] sm:ml-[0] ml-[85px] text-[15px] text-center text-white_A700 tracking-ls03 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                shape="RoundedBorder23"
                size="3xl"
                variant="OutlineGray9003d"
              >Get free assessment</Button></div>
            <div
              className="flex sm:flex-col flex-row md:gap-[40px] sm:gap-[40px] items-center justify-between w-[100%]"
            ><Text
              className="font-normal not-italic text-left text-white_A700 w-[auto]"
              variant="body15"
            ><span className="text-white_A700 text-[15px] font-poppins">© 2022. </span><span
              className="text-white_A700 text-[15px] font-inter font-semibold">Data simpl</span><span
              className="text-white_A700 text-[15px] font-poppins">. All righ</span><span
              className="text-white_A700 text-[15px] font-poppins">t</span><span
              className="text-white_A700 text-[15px] font-poppins">s reserved.</span></Text>
              <div
                className="flex flex-row gap-[40px] items-start justify-start w-[auto]"
              ><Text
                className="font-normal not-italic text-left text-white_A700 w-[auto]"
                variant="body15"
              >Privacy policy</Text><Text
                className="font-normal not-italic text-left text-white_A700 w-[auto]"
                variant="body15"
              >Terms & Conditions</Text></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </>);
};
export default LatestnewsOnePage;
