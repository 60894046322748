import React from "react";
import { useState, useEffect } from "react";
import { withAuthenticator, Collection } from "@aws-amplify/ui-react";
import { BlueNavigationBar } from "../../components/NewBlueNavigationBarLeft/navigationBox";
import { CreateSchemaInputBox } from "../../components/DataCatalog/schemaUploadBox";


import Header from "../../components/NewHeader/Header";
import { DataObject } from "../../ui-components";
import { getUserInfo, prepareHeaderInstance } from "../../components/userAuth";
import './DataCatalog.css'

const baseurl =  process.env.REACT_APP_BASE_URL;


const DataCatalog = () => {

  const [dataProducts, setdataProducts] = useState([]);
  const [dataStewards, setdataStewards] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosInstance = await prepareHeaderInstance();
        const response = await axiosInstance.get(baseurl.concat("dataCatalog"));

        const allMetadata = response.data.map(item => item.metadata);
        setdataProducts(allMetadata);

        const userInfo = await getUserInfo();
        setdataStewards(userInfo.name);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
const MainView=()=>{
  return(
       <div className="flex ">
        {/* Sidebar */}
        <BlueNavigationBar></BlueNavigationBar>

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header
            title="Data catalog"
            subtitle="Get an overview of your Data Products"
          />

          <main>
          <div className="grid grid-cols-12 grid-rows-auto gap-10 mt-10 ml-10 mr-10">
              <div className="h-[1000px] col-span-3 row-span-2 md:justify-center bg-primary-400 p-30 bg-gray-100 rounded-xl">
                <div className="flex h-[900px] justify-center mt-10 mx-10">
                  <Collection
                    items={dataProducts}
                    type="list"
                    direction="column"
                    gap="20px"
                    wrap="nowrap"
                  >
                    {(item) => (
                      <DataObject
                        DataSteward={item.dataSteward}
                        DataObject={item.dataproduct}
                        PublicationDate={item.publicationDate}
                        MinorVersion={item.minorVersion}
                        MajorVersion={item.majorVersion}
                      />
                    )}
                  </Collection>
                </div>
              </div>

              <div className="bg-gray-100 col-span-9 md:col-span-9 row-span-2 md:justify-center bg-primary-400 p-30 rounded-xl">
                <div className="mt-10 mx-10 mb-10">
                  <CreateSchemaInputBox
                    t_dataSteward={dataStewards}>
                  </CreateSchemaInputBox>
                </div>
              </div>

      
            </div>
          </main>
        </div>
      </div>
  )
}

  return (
    <>
      <div 
      className="flex "
      >
    
   
     <BlueNavigationBar/>

      <div className="flex-1 ">
        <div  className="mb-[20px]">
      <Header
            title="Data catalog"
            subtitle="Get an overview of your Data Products"
          />
          </div>
          <div className="flex  justify-evenly m-10 sm:flex-col md:flex-col sm:space-y-10 md:space-y-10 sm:m-10 md:m-10 flex-layout">
<div className={`inline-flex  bg-[#f3f4f6] ${dataProducts.length>0?'p-[10px]':'p-[120px]'} sm:p-[0px] md:p-[10px] justify-center rounded-[34px]`}>
   
       <Collection
                    items={dataProducts}
                    type="list"
                    direction="column"
                    gap="20px"
                    wrap="nowrap"
                  >
                    {(item) => (
                      <DataObject
                        DataSteward={item.dataSteward}
                        DataObject={item.dataproduct}
                        PublicationDate={item.publicationDate}
                        MinorVersion={item.minorVersion}
                        MajorVersion={item.majorVersion}
                      />
                    )}
                  </Collection>
</div>
<div className="inline-flex bg-[#f3f4f6] w-full p-10 justify-center items-center  sm:p-[20px]  rounded-[34px] mx-10 ">
     <CreateSchemaInputBox
                    t_dataSteward={dataStewards}>
                  </CreateSchemaInputBox>
</div>
      </div>
      </div>
</div>


 {/* {MainView()} */}
    </>
  );
};
export default withAuthenticator(DataCatalog, { hideSignUp: true });
