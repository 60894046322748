export function handleNavigateDashboard(state) {
  window.location.href = `/dashboard?showMenu=${state}`;
}

export function handleNavigateDataCatalog(state) {
  window.location.href = `/datacatalog?showMenu=${state}`;
}

export function handleNavigateUploadData(state) {
  window.location.href = `/uploaddata?showMenu=${state}`;
}

export function handleNavigateEventMonitoring(state) {
  window.location.href = `/eventmoni?showMenu=${state}`;
}

export function handleNavigateWorkspace(state) {
  window.location.href = `/workspace?showMenu=${state}`;
}

export function handleNavigateSwagger(state) {
  window.location.href = `/swagger?showMenu=${state}`;
}

export function handleNavigateSettings(state) {
  window.location.href = `/settings?showMenu=${state}`;
}

export function handleNavigateSecurity(state) {
  window.location.href = `/security?showMenu=${state}`;
}

export function handleNavigatePaymentSettings(state) {
  window.location.href = `/financial?showMenu=${state}`;
}

export function handleNavigateSupport(state) {
  window.location.href = `/support?showMenu=${state}`;
}

export function handleNavigateOverviewDashboard(state) {
  window.location.href = `/overviewDash?showMenu=${state}`;
}
