import React from 'react'

export default function BusinessDetailComponent(props) {
  return (
    <div className='flex-col  space-y-9 mt-9 w-9/12 '>
      <p className='text-[#1A8FF7] text-[30px] font-bold font-rubik'>{props.title}</p>
      <p className='text-[15px] font-light text-[#101821] font-rubik'>{props.detail}</p>
   {!props.hideline? <div className='h-0.5  w-full bg-[#ddeffe]'/>:null}
    </div>
  )
}
