import React, { useEffect, useState } from "react";
import { Treemap as RTreemap, ResponsiveContainer, Tooltip } from "recharts";
import { parseFunc, formatNumber, palette } from "../Functions/utils";
import { ErrorBoundary } from "../ErrorBoundary";

export function TreemapChart(props) {
  const [containerWidth, setContainerWidth] = useState(0);

  const myGroupingFunction = React.useMemo(() => {
    return parseFunc(props.config.javascriptFunction, (data) => data);
  }, [props.config]);

  const data = React.useMemo(() => {
    if (typeof myGroupingFunction === "function")
      return myGroupingFunction(props.data);
    return null;
  }, [myGroupingFunction, props.config, props.data]);

  useEffect(() => {
    const updateContainerWidth = () => {
      const container = document.getElementById("chart-container");
      if (container) {
        setContainerWidth(container.offsetWidth);
      }
    };

    // Update container width initially and on window resize
    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);

  if (!data) return null;

  return (
    <ErrorBoundary>
      <div
        id="chart-container"
        className="  w-[100%] h-[100%]"
        // Add other styles as needed
      >
        <ResponsiveContainer width={containerWidth} height={200}>
          <RTreemap
            data={data.map((d) => ({ name: d.x, value: d.y }))}
            stroke="var(--textColor)"
            // fill={palette[0]}
          >
            <Tooltip
              formatter={(d, name, item) => [
                formatNumber(item.payload.value),
                item.payload.name,
              ]}
            />
          </RTreemap>
        </ResponsiveContainer>
      </div>
    </ErrorBoundary>
  );
}

;
