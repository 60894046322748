import { ErrorMessage } from "../../components/ErrorMessage";
import React from "react";
import PropTypes from "prop-types";
const variants = { FillGray51: "bg-gray_51 border-0" };
const shapes = { RoundedBorder32: "rounded-radius32" };
const sizes = {
  sm: "sm:pb-[13px] md:pb-[18px] pb-[35px] sm:pt-[11px] md:pt-[14px] pt-[29px] md:px-[14px] sm:px-[15px] px-[29px]"
};
const TextArea = React.forwardRef(
  (
    {
      className,
      name,
      placeholder,
      children,
      shape,
      variant,
      size,
      errors,
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <textarea
          ref={ref}
          className={`${className} ${shapes[shape] || ""} ${
            variants[variant] || ""
          } ${sizes[size] || ""}`}
          name={name}
          placeholder={placeholder}
          {...restProps}
        />
        {!!errors && <ErrorMessage errors={errors} />}
        {children}
      </>
    );
  }
);
TextArea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  shape: PropTypes.oneOf(["RoundedBorder32"]),
  variant: PropTypes.oneOf(["FillGray51"]),
  size: PropTypes.oneOf(["sm"])
};
TextArea.defaultProps = {
  className: "",
  name: "",
  placeholder: "",
  shape: "RoundedBorder32",
  variant: "FillGray51",
  size: "sm"
};
export { TextArea };
