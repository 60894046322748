import React from 'react'

import PropTypes from 'prop-types'

import './navigation-links.css'

const NavigationLinks = (props) => {
  return (
    <nav className={`navigation-links-nav ${props.rootClassName} `}>
      <span className="navigation-links-text">{props.text}</span>
      <span className="navigation-links-text1">{props.text3}</span>
      <span className="navigation-links-text2">{props.text4}</span>
      <span className="navigation-links-text3">{props.text1}</span>
      <span className="navigation-links-text4">{props.text2}</span>
    </nav>
  )
}

NavigationLinks.defaultProps = {
  text4: '03 Data',
  text3: '02 Connectivity',
  text: '01 Basic',
  text2: '05 Summary',
  rootClassName: '',
  text1: '04 Financials',
}

NavigationLinks.propTypes = {
  text4: PropTypes.string,
  text3: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
  rootClassName: PropTypes.string,
  text1: PropTypes.string,
}

export default NavigationLinks
