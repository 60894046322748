import React from "react";
const variantClasses = {
  h1: "font-bold md:text-[48px] sm:text-[48px] text-[90px]",
  h2: "text-[9px]",
  h3: "text-[8px]",
  h4: "font-light text-[7.13px]",
  h5: "md:text-[48px] sm:text-[48px] text-[60px]",
  h6: "font-bold text-[6.57px]",
  body1: "font-normal text-[6.27px]",
  body2: "font-medium text-[6.15px]",
  body3: "font-medium sm:text-[38px] md:text-[44px] text-[52px]",
  body4: "font-normal text-[5.53px]",
  body5: "sm:text-[36px] md:text-[38px] text-[40px]",
  body6: "font-medium text-[4.75px]",
  body7: "sm:text-[28px] md:text-[30px] text-[32px]",
  body8: "sm:text-[24px] md:text-[26px] text-[28px]",
  body9: "sm:text-[20px] md:text-[22px] text-[24px]",
  body10: "text-[20px]",
  body11: "font-medium sm:text-[1px] text-[2.38px]",
  body12: "font-medium text-[18px]",
  body13: "text-[17px]",
  body14: "text-[16px]",
  body15: "text-[15px]",
  body16: "text-[14px]",
  body17: "font-medium text-[13px]",
  body18: "text-[12px]",
  body19: "text-[11px]",
  body20: "text-[10px]"
};
const Text = ({ children, className, variant, as, ...restProps }) => {
  const Component = as || "span";
  return (
    <Component
      className={`${className} ${variantClasses[variant]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};
export { Text };
