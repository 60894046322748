import React, { useState } from "react";
import { prepareHeaderInstance } from "../userAuth";

const baseurl = process.env.REACT_APP_BASE_URL;


export default function DashBoardButton(props) {
  const [iframeUrl, setIframeUrl] = useState('');

  const fetchData = async () => {
      try {
        if (!props.title) {
          console.log("no title");
          return;
        }
        const axiosInstance = await prepareHeaderInstance();
        const response = await axiosInstance.get(baseurl.concat("createDashboard/", props.title));
        console.log(response.data.message);
        props.setIframeUrl(response.data.message)
      } catch (error) {
        console.error('Error:', error);
      }
    }

  const FirstRow = () => {
    return (
      <div className="flex items-start   justify-between border-b-2  border-b-[#ddeedc]">
        <div className="bg-[#6b38de] h-6 w-20 mx-2 mb-1"></div>
        <div className="bg-[#6b38de] h-6 w-20 mx-2 mb-1"></div>
        <div className="bg-[#6b38de] h-6 w-20 mx-2 mb-1"></div>
        <div className="bg-[#6b38de] h-3 w-20 self-end mx-2 mb-1"></div>
      </div>
        )
    }

    const SecondRow=()=>{
        return(
        <div className='flex items-start  justify-between border-b-2  border-b-[#ddeedc]'>
                <div className='bg-[#6b38de] h-6 w-10 self-end  mx-1 mb-1'></div>
                <div className='bg-[#6b38de] h-6 w-10 self-end  mx-1 mb-1'></div>
                <div className='bg-[#6b38de] h-4 w-10 self-end mx-1 mb-1'></div>
                <div className='bg-[#6b38de] h-5 w-10 self-end mx-1 mb-1'></div>
                <div className='bg-[#6b38de] h-3 w-10 self-end mx-1 mb-1'></div>
                <div className='bg-[#6b38de] h-2 w-10 self-end mx-1 mb-1'></div>
                <div className='bg-[#6b38de] h-2 w-10 self-end mx-1 mb-1'></div>
                <div className='bg-[#6b38de] h-2 w-10 self-end mx-1 mb-1'></div>
                <div className='bg-[#6b38de] h-2 w-10 self-end mx-1 mb-1'></div>
        </div>
        )
    }
const ThirdRow=()=>{
    return(
                <div className='flex items-end   justify-between border-b-2 mx-1 border-b-[#ddeedc]'>
                  <div className='bg-[#6b38de] h-2 w-20  mb-1'></div>
                  <div className='bg-[#6b38de] h-2 w-20  mb-1'></div>
                  <div className='bg-[#6b38de] h-2 w-20  mb-1'></div>
                 <div className='bg-[#6b38de] h-3 w-20 self-end  mb-1'></div>
        </div>
    )
}
const ForthRow=()=>{
    return(
                <div className='flex items-end   justify-between border-b-2 mx-1 border-b-[#ddeedc]'>
                  <div className='bg-[#6b38de] h-2 w-20  mb-1'></div>
                  <div className='bg-[#6b38de] h-2 w-20  mb-1'></div>
                  <div className='bg-[#6b38de] h-2 w-20  mb-1'></div>
                 <div className='bg-[#6b38de] h-3 w-20 self-end  mb-1'></div>
        </div>
    )
}
const FifthRow=()=>{
    return(
                <div className='flex items-end   justify-between border-b-2 mx-1 border-b-[#ddeedc]'>
                  <div className='bg-[#6b38de] h-2 w-20  mb-1'></div>
                  <div className='bg-[#6b38de] h-2 w-20  mb-1'></div>
                  <div className='bg-[#6b38de] h-2 w-20  mb-1'></div>
                 <div className='bg-[#6b38de] h-3 w-20 self-end  mb-1'></div>
        </div>
    )
}

return (
    <div className="flex flex-col items-center cursor-pointer" onClick={fetchData}>
      <div className="bg-white border-2 border-[#dceddc] py-1 ">
        {FirstRow()}
        {SecondRow()}
        {ThirdRow()}
        {ForthRow()}
        {FifthRow()}
        <div className="flex items-end   justify-between border-b-2 p-2 border-b-[#ddeedc]" />
        <div className="flex items-end   justify-between border-b-2 p-2 border-b-[#ddeedc]" />
        <div className="flex items-end   justify-between border-b-2 p-2 border-b-[#ddeedc]" />
      </div>
      <span className="mt-4 text-[24px]">{props.title}</span>
    </div>
  );

}
