import { Button, Heading, SelectField, PasswordField, TextField, Divider, PhoneNumberField,useTheme } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import { Column, Row, Stack, Text } from "components2";
import logo from "../../assets/images/logo.svg";
import './FinancialPage.css'
const inputStyle = {
  backgroundColor: '#F8F8F9',
  borderRadius: '10px',
  border: '0px solid',
};


const NewAccountPage = () => {
    const { tokens } = useTheme();
const HeaderView=()=>{
  return(
  <header className="sticky top-0 z-50 bg-gray_900 sm:w-[100%] w-[100%]">
        <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%] sm:flex-col md:flex-col p-5">
                <Stack className="bg-cover bg-no-repeat ">
                  <img src={logo} alt="Logo"className="h-[60px] "   />
                </Stack>
      
          <Row className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-start justify-center sm:mx-[0] sm:px-[0] sm:w-[100%] md:mx-[0] md:px-[0] md:w-[100%] w-[56%]">
            <Text className="font-medium mb-[1px] text-white_A700 w-[auto] bold cursor-pointer "
              variant="body6">
               Basic
            </Text>
            <Text className="font-small cursor-pointer mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto] "
              variant="body6"
            >
              Connectivity
            </Text>
            <Text className="font-small cursor-pointer mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]  "
              variant="body6">
              Data
            </Text>
            <Text className="font-small  cursor-pointer mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto] "
              variant="body6"
            >
             Financial
            </Text>
            <Text className="font-small cursor-pointer mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto] "
              variant="body6"
            >
             Summary
            </Text>
          </Row>
        </Row>
      </header>
)
}
const PaymentView=()=>{
  return(
          <Column className="p-10">

        <div className="">
          <p className='text-[17px] text-black font-bold'
          >
            Payment methods
          </p>

          <div className="mt-4 ml-0 w-[50%] ">
            <SelectField
            >
              {/* inputStyles={inputStyle} */}
              <option value="CreditCard">Credit Card</option>
              <option value="Invoice">Invoice</option>
              <option value="Prepayment">Prepayment</option>
              <option value="Other">Other</option>
            </SelectField>
          </div>

          <div className="my-4">
            <p className='pb-4'>
              Credit Card
            </p>
         <p>Empower everyone on your team to do their best work with the help of human-centric solutions that connect people, processes and data
          </p>
          </div>
          <div className="flex space-x-4 items-center justify-start sm:flex-col sm:space-x-0 sm:space-y-4">

   <div className='sm:w-[100%]'>
            <TextField
              placeholder="Max Mustermann"
              label={<strong>Card holder name</strong>}
              errorMessage="There is an error"
              inputStyles={inputStyle}
            />

</div>
   <div className='sm:w-[100%]'>
   
              <TextField

                placeholder="1111222233334444"
                inputStyles={inputStyle}
                label={<strong  >Credit Card Number</strong>}
                errorMessage="There is an error"
                  eyeIconStyles={{
  color: 'red', // Change the color to your preferred color
  fontSize: '20px', // Change the font size as needed
  // Add more styles as needed
  backgroundColor:'red'
}}
              />

         
</div>
  <div className='sm:w-[100%]'>
              {/* <PasswordField

                label={<strong>CVV</strong>}
                name="password"
                level={6}
                inputStyles={inputStyle}
         

              /> */}
                      <PasswordField
            label={<strong>CVV</strong>}
        //padding="xl"

inputStyles={{   backgroundColor: '#F8F8F9',}}
       // border={`1px solid ${tokens.colors.brand.primary[60]}`}
      />
     </div>

          </div>
          <Divider className="flex mt-4 sm:w-full w-2/3"
            orientation="horizontal" /> </div>

     <div className="mt-4">
          <Heading
            width="30vw"
            level={6}
            
            className="text-left"
          >
            Billing information
          </Heading>

          <div className="flex mt-4 sm:w-full sm:flex-col sm:space-x-0 space-x-3 sm:space-y-4">


            <TextField

              placeholder="Max Mustermann"
              label={<strong>Company name</strong>}
              errorMessage="There is an error"
              inputStyles={inputStyle}
            />


            <div className="">
              <TextField
                label={<strong>Country</strong>}
                errorMessage="There is an error"
                inputStyles={inputStyle}
              />
            </div>
            <div className="">

              <TextField
                label={<strong>Street</strong>}
                inputStyles={inputStyle}
            
                    
                
              />
            </div>
          </div>


          <div 
          className="flex mt-4 sm:w-full sm:flex-col sm:space-x-0 space-x-3 sm:space-y-4"
          >
            <TextField

              label={<strong>City</strong>}
              inputStyles={inputStyle}
              errorMessage="There is an error"
            />
            <div className="ml-4">
              <TextField
                label={<strong>Zip Code</strong>}
                inputStyles={inputStyle}
              />
            </div>
            <div className="ml-4">

              {/* <PhoneNumberField
                label={<strong>Phone number</strong>}
                inputStyles={inputStyle}
              /> */}
              <PhoneNumberField
        defaultDialCode="+1"
        label={
        <strong>Phone number</strong>
        }
        //padding="xl"

inputStyles={{   backgroundColor: '#F8F8F9',}}
       // border={`1px solid ${tokens.colors.brand.primary[60]}`}
      />
            </div>
          </div>

          <Divider className="flex mt-4 mr-200 w-2/3"
            orientation="horizontal" />
        </div>
           <div className="flex mt-4 ">
          <Link to="/newaccount">
            <Button
              variation="secondary"
              id="back"
              type="button"
              className="mr-2"
            >
              Back
            </Button>
          </Link>

          <Link to="/workspace">
            <Button
              variation="secondary"
              id="next"
              type="button"
            >
              Next
            </Button>
          </Link>
        </div>
      </Column>
  )
}
  return (
    

    <Column className="bg-white_A700 flex flex-col font-montserrat ">
{HeaderView()}
{PaymentView()}

    </Column>
  );

};
export default NewAccountPage;