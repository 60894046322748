import React, { useState } from "react";
import styles from "./style.module.scss";
import { DropdownFilter } from "./DropdownFilter";
import { PerformanceIndicator } from "./PerformanceIndicator";
import { LineChart } from "./Charts/LineChart";
import { BarChart } from "./Charts/BarChart";
import { PieChart } from "./Charts/PieChart";
import { TreemapChart } from "./Charts/TreemapChart";

export function Dashboard(props) {
  const data = JSON.parse(props.data);
  const [dashboardData,setdashboardData] =useState( JSON.parse(props.dashboard).dashboard);

  // State hooks
  const [filters, setFilters] = React.useState({});
  const [selectedCharts, setSelectedCharts] = React.useState([]);
  // Memo hooks
  const filteredData = React.useMemo(() => {
    if (Object.keys(filters).length) {
      return Object.keys(filters).reduce((result, key) => {
        if (filters[key]) return result.filter((row) => row[key] === filters[key]);
        return result;
      }, data);
    }
    return data;
  }, [filters, data]);

  // Callback hooks
  const handleFilterChange = React.useCallback((filter) => {
    return (value) => {
      setFilters((filters) => ({ ...filters, [filter]: value }));
    };
  }, []);

  const handleChartClick = React.useCallback((index) => {
    setSelectedCharts((prevSelectedCharts) => {
      const newSelectedCharts = [...prevSelectedCharts];
      newSelectedCharts[index] = !newSelectedCharts[index];
      return newSelectedCharts;
    });
  }, []);

  // Effect hooks
  React.useEffect(() => {
    if (props.onSelectedChartsChange) {
      props.onSelectedChartsChange(selectedCharts);
    }
  }, [selectedCharts]);

  React.useEffect(() => {
    if (dashboardData && dashboardData.charts) {
      setSelectedCharts(new Array(dashboardData.charts.length).fill(true));
    }
  }, [dashboardData]);

  // Conditional Rendering
  if (!dashboardData || !dashboardData.charts) {
    return null;
  }

  return (
    <div className={styles.dashboardContainer}>
  <div className={styles.filtersRow}>
        {dashboardData.filters.map((filter, index) => (
          <DropdownFilter
            key={`${filter.column}-${index}`}
            config={filter}
            data={data}
            onChange={handleFilterChange(filter.column)}
            value={filters[filter.column]}
          />
        ))}
      </div>
      <hr />
       <div className={styles.kpiRow}>
        {dashboardData.kpis.map((kpi, index) => (
          <PerformanceIndicator
            key={`${kpi.title}-${index}`}
            config={kpi}
            data={filteredData}
          />
        ))}
      </div>
     {dashboardData.charts.map((chart, index) => (
        <div
          key={`${chart.title}-${index}`}
          className={`${styles.chartCard} ${styles[chart.chartType]} ${selectedCharts[index] ? styles.selected : ""}`}
          onClick={() => handleChartClick(index)}
        >
          <div className={styles.chartCardTitle}>{chart.title}</div>
          {chart.chartType === "lineChart" && <LineChart config={chart} data={filteredData} />}
          {chart.chartType === "barChart" && <BarChart config={chart} data={filteredData} />}
          {chart.chartType === "pieChart" && <PieChart config={chart} data={filteredData} />}
          {chart.chartType === "treemapChart" && <TreemapChart config={chart} data={filteredData} />}
        </div>
      ))} 
    </div>
  );
}
