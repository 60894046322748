import React from "react";
import { useEffect, useState } from 'react';
import { Img, Line, Text } from "components";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { BlueNavigationBar } from "../../components/BlueNavigationBarLeft/navigationBox";
import { CreateSchemaInputBox } from "../../components/DataCatalog/schemaUploadBox";
import { getUserInfo } from "../../components/userAuth";


const CreateSchemaPage = (dataSteward) => {


  useEffect(() => {
    async function fetchData() {
      const userInfo = await getUserInfo();
      document.getElementById('user-email').innerHTML = userInfo.email;
      document.getElementById('user-name').innerHTML = userInfo.name;
    }
    fetchData();
  }, []); // leeres Array sorgt dafür, dass useEffect nur einmal ausgeführt wird


  return (<>
    <div
      className="bg-white_A700 flex  font-sfprodisplay items-center justify-end mx-[auto] w-[100%]"
    >
      <div
        className="flex flex-col items-center justify-end w-[100%]"
      >
        <div
          className="flex md:flex-col sm:flex-col flex-row font-montserrat md:gap-[0px] sm:gap-[50px] items-start justify-between mx-[auto] md:px-[20px] sm:px-[20px] w-[100%]"
        >
          <BlueNavigationBar></BlueNavigationBar>
          <div
            className="flex flex-1 flex-col font-poppins items-center justify-start md:mt-[0] sm:mt-[0] mt-[29px] w-[100%]"
          >
            <div
              className="flex md:flex-col sm:flex-col flex-row gap-[30px] items-center justify-between w-[100%]"
            >
              <Text
              className="font-bold leading-[100.00%] text-gray_900 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1"
              variant="body5"
            >
              <span className="text-gray_900 text-[17px] font-poppins font-light mx-10">Welcome to</span><span
              className="text-gray_900 text-[17px] font-poppins"> <br /></span><span
              className="sm:text-[24px] md:text-[26px] text-blue_500 text-[28px] font-poppins mx-10">Data simpl</span></Text>

              <div
                className="flex flex-row gap-[8px] items-center justify-start w-[auto]"
              ><Img
                src=
                  "images/img_notification.svg"
                className="h-[24px] w-[24px]"
                alt="notification"
              />
              <Text
                className="bg-blue_500 flex font-medium h-[28px] items-center justify-center rounded-radius50 text-right text-white_A700 w-[28px]"
                variant="body18"
              >10</Text>

              </div>
              <div
                className="flex flex-row gap-[15px] items-center justify-start py-[10px] w-[auto]"
              ><Img
                src=
                  "images/img_usericon.png"
                className="h-[42px] md:h-[auto] sm:h-[auto] rounded-radius50 w-[42px]"
                alt="UserIcon"
              />
                <div
                  className="flex flex-col gap-[5px] items-start justify-start w-[auto]"
                >
                  <Text
                  className="font-semibold text-gray_900 text-left w-[auto]"
                  variant="body15"
                ></Text><div id="user-name"></div><Text
                  className="font-light text-blue_500 text-left w-[auto]"
                  variant="body16"
                ><div id="user-email"></div></Text>
                </div>
                <Img
                  src=
                    "images/img_interfaceessentialdots.svg"
                  className="h-[24px] w-[24px]"
                  alt="InterfaceEssentialdots"
                /></div>
            </div>
            <Line className="bg-blue_500_6c h-[1px] mt-[30px] w-[100%]" />
            <CreateSchemaInputBox
            t_dataSteward={dataSteward.name}></CreateSchemaInputBox>

          </div>
        </div>
      </div>
    </div>
  </>);
};
export default withAuthenticator(CreateSchemaPage, {  hideSignUp: true});
