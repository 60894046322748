import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



export default function SingleBarChartView() {
   const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
     // text: 'Chart.js Bar Chart',
    },
     legend: {
        display: false, // Hide the legend
      },
  },
};

const labels = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const [dataSets,setDataSets]=useState(labels.map(() => faker.datatype.number({ min: 0, max: 10000 })))
const highestValue = Math.max(...dataSets);
console.log("The highest value in dataSets is: " + highestValue,dataSets,dataSets.filter((item)=>item==highestValue)[0]);
const data = {
  labels,
  datasets: [
    {
       label: 'UserActivity',

      data:dataSets,
   backgroundColor: dataSets.map((item) =>
          item === highestValue ? 'rgb(27, 143, 248)' : 'rgb(220, 234, 254)'
        ),
          },
  ],
};
  return  <div style={{  width: '500px', height: '290px'}}>
    <div className='flex'>
      <div className='flex flex-col '>
<span className='text-[#c9c9c9] '>User Activity</span>
<span className='font-bold'>{highestValue}</span>
</div>
    </div>
      <Bar options={options} data={data} />
    </div>;
}
