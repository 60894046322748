import React from 'react'

export default function DashBoardComponent(props) {
  return (
    <div className='flex flex-col p-2  m-2 rounded-lg w-[250px]  bg-white  sm:h-[5%] sm:w-[90%] '>
    <h1 className='text-[#1e5abc] font-bold text-[24px]'>{props.header}</h1>
      <span className='text-[#1e5abc] font-normal text-[18px]'>{props.detail}</span>
<div className='flex justify-between items-center'> 
      <a href={`${props.link}`} className='text-[#1e5abc] font-normal text-[18px]'>Link</a>
      <img
      src={props.image}
      className='w-[30px] h-[30px]'
      />
      </div> 
    </div>
  )
}
