import React from 'react'

export default function SpecialComponent(props) {
  return (
  <div className="bg-[#081533] p-[20px] flex flex-col w-[500px] space-y-4 sm:w-full" >
<div className={`flex border-2 border-[${props.color}] items-center justify-center w-8 h-8 rounded-full ${props.circleStyle}`}>
  <p className="text-white">{props.count}</p>
</div>
<p className={`text-white text-[18px] font-bold ${props.titleStyle}`}>{props.title}</p>
<p className="text-white text-[8px]">{props.titleText}</p>
</div>

  )
}
