import React from 'react';
import { Container } from '../../components2/Dashboard/Container';
import { PanelContent } from '../../components2/Dashboard/PanelContent';
import { Dashboard } from '../../components2/Dashboard/Dashboard';
import { ButtonsRow } from '../../components2/Dashboard/ButtonsRow';
import { Button } from '../../components2/Dashboard/Button';
import { Loader } from '../../components2/Dashboard/Loader';


const DashboardPageComponent = ({ dashboardConfig, dataObj }) => {
  const [view, setView] = React.useState("dashboard");
const [data, setData] = React.useState();

const [dashboard, setDashboard] = React.useState();
const [isDashboardLoaded, setIsDashboardLoaded] = React.useState(false);

React.useEffect(() => {
    if (dashboardConfig && dataObj) {
      setDashboard(dashboardConfig);
      setData(dataObj);
      setIsDashboardLoaded(true);
      console.log("setup dashboard");
    }
  }, [dashboardConfig, dataObj]);




  const [selectedCharts, setSelectedCharts] = React.useState([]);
const handleSaveDashboard = React.useCallback(() => {
  if (!dashboard) { return ; }
  for (let i = 0; i < selectedCharts.length; i++) {
    dashboard.charts[i].saved = selectedCharts[i];
  }
}, [dashboard, selectedCharts]);




const handleSelectedChartsChange = React.useCallback((selectedCharts) => {
  setSelectedCharts(selectedCharts);
}, []);
return (
  <>
    <Container>
      <PanelContent>
        <ButtonsRow>
          <Button
            className="save-dashboard"
            onClick={handleSaveDashboard}
          >
            Save Dashboard
          </Button>
        </ButtonsRow>
        {view === "dashboard" ? (
          isDashboardLoaded && dashboard && data ? (
            <Dashboard data={data} dashboard={dashboard} onSelectedChartsChange={handleSelectedChartsChange}
            />
          ) : (
            <div style={{ position: 'relative', minHeight: '200px' }}>
              <Loader />
            </div>
          )
        ) : null}
      </PanelContent>
    </Container>
  </>
);
};

export default DashboardPageComponent;
