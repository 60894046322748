// example code https://github.com/cruip/tailwind-dashboard-template/tree/main/src/partials
import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { BlueNavigationBar } from "../../components/BlueNavigationBarLeft/navigationBox";
import { Auth } from "aws-amplify";
import Header from "../../components/Header/Header";

import '@aws-amplify/ui-react/styles.css';
import { Button } from "@aws-amplify/ui-react";

// const baseurl = "https://cdoqzeuiu3.execute-api.eu-central-1.amazonaws.com/";

const columns = [
  { field: "UUID", headerName: "UUID", width: 250 },
  { field: "Runtime", headerName: "Runtime", width: 180 },
  { field: "DataObject", headerName: "DataObject", width: 100 },
  { field: "Data", headerName: "Data", width: 600 },
  { field: "Link", headerName: "Link", width: 600 },
];

const Template = () => {
  const [inputvalue, setInputvalue] = React.useState("");

  // get user info
  async function getUserInfo() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userInfo = {
        username: user.username,
        email: user.attributes.email,
        phone: user.attributes.phone_number,
      };
      return userInfo;
    } catch (error) {
      console.log("Error getting user info: ", error);
    }
  }

  getUserInfo()
    .then((userInfo) => {
      document.getElementById("user-email").innerHTML = userInfo.email;
    })
    .catch((error) => {
      console.log("Error: ", error);
    });







  return (
    <>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <BlueNavigationBar></BlueNavigationBar>

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header />

          <main>
            <div class="grid grid-cols-12 grid-rows-auto gap-20 mt-10 ml-10 mr-10">
              <div class="h-[1000px] col-span-9 row-span-5 justify-center bg-primary-400 p-30 bg-gray-100 ">

              <Button
                    loadingText=""
                    isFullWidth={true}
                    // variation="secondary"
                    backgroundColor="#228DF1"
                    
                    // onClick={submitDeleteInitData}
                    ariaLabel=""
                  >
                    Delete init data
                  </Button>

              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default withAuthenticator(Template, { hideSignUp: true });
