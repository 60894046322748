import React from "react";
import logo from "../../assets/images/logo.svg";
import { Button, Column, Img, Row, Stack, Text } from "components2";
const Impressum = () => {
  function handleNavigate() {
    window.location.href = "/impressum";
  }

  function handleNavigateWorkspace() {
    window.location.href = "/datacatalog";
  }

  function handleNavigateAmbition() {
    window.location.href = "/#ambition";
  }

  function handleNavigateProblem() {
    window.location.href = "/#problem";
  }

  function handleNavigateBenefits() {
    window.location.href = "/#benefits";
  }

  function handleNavigateCase() {
    window.location.href = "/#case";
  }

  function handleNavigateTrust() {
    window.location.href = "/#trust";
  }

  function handleNavigateCareer() {
    window.location.href = "/#career";
  }

  function handleNavigateStarted() {
    window.location.href = "/#started";
  }

  function handleEmailCareer() {
    window.location.href = "mailto:career@datasimpl.cloud";
  }

  function handleEmailSales() {
    window.location.href = "mailto:sales@datasimpl.cloud";
  }
  return (
    <>
      <Column className="bg-white_A700 flex flex-col font-montserrat  justify-start mx-[auto] w-[100%]">
        <Column
          className="bg-cover bg-no-repeat flex flex-col items-end justify-start sm:p-[15px] md:p-[25px] p-[30px] w-[100%]"
          style={{ backgroundImage: "url('images/img_group4.svg')" }}
        >
          <Column
            className="flex flex-col justify-start max-w-[1536px] sm:mb-[46px] md:mb-[59px] ml-[auto] md:mr-[6px] mr-[auto] sm:mx-[0] sm:pl-[15px] sm:pr-[15px] sm:px-[0] w-[100%]">
            {/* <Sticky> */}
            <header className="sm:w-[100%] w-[84%]">
              <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%]">
                <Stack className="h-[80px] relative w-[15%]">
                  <Column className="absolute flex flex-col items-center justify-start w-[100%]">
                    <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center w-[100%]">
                      <Stack
                        className="bg-cover bg-no-repeat h-[60px] ml-[1px] sm:p-[1px] md:p-[20px] p-[5px] relative w-[75%]"
                      >
                        <img src={logo} alt="Logo" height="40px" />
                      </Stack>
                    </Row>
                  </Column>
                </Stack>
                <Row
                  className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-start justify-center sm:mx-[0] sm:px-[0] sm:w-[100%] w-[56%]">
                  <Text
                    className="font-medium mb-[1px] text-white_A700 w-[auto]"
                    variant="body6"
                    onClick={() => {
                      handleNavigateAmbition();
                    }}
                  >
                    Our ambition
                  </Text>
                  <Text
                    className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                    variant="body6"
                    onClick={() => {
                      handleNavigateProblem();
                    }}
                  >
                    Problem
                  </Text>
                  <Text
                    className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                    variant="body6"
                    onClick={() => {
                      handleNavigateBenefits();
                    }}
                  >
                    Benefits
                  </Text>
                  <Text
                    className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                    variant="body6"
                    onClick={() => {
                      handleNavigateCase();
                    }}
                  >
                    Business Case
                  </Text>
                  <Text
                    className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                    variant="body6"
                    onClick={() => {
                      handleNavigateTrust();
                    }}
                  >
                    Trust
                  </Text>
                  <Text
                    className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                    variant="body6"
                    onClick={() => {
                      handleNavigateCareer();
                    }}
                  >
                    Career
                  </Text>
                </Row>
                <Row
                  className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-center justify-between sm:mx-[0] sm:px-[0] sm:w-[100%] w-[18%]">
                  <Img
                    src="images/img_location.svg"
                    className="sm:h-[13px] md:h-[17px] h-[32px] max-w-[100%] sm:w-[12px] md:w-[16px] w-[32px]"
                    alt="location"
                    onClick={() => {
                      handleNavigateWorkspace();
                    }}
                  />
                  <Button
                    className="cursor-pointer font-medium min-w-[77%] text-[17px] text-center text-white_A700 tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                    size="sm"
                    variant="OutlineBlue500"
                    onClick={() => {
                      handleNavigateStarted();
                    }}
                  >
                    Get started
                  </Button>
                </Row>
              </Row>
            </header>
            {/* </Sticky> */}
          </Column>
        </Column>
        <Column
          className="flex flex-col  font-rubik justify-start max-w-[1280px] mx-20 mt-[6px] sm:mt-[50px] md:mt-[65px] sm:pl-[15px] sm:pr-[15px] w-[100%] ">
          <Column className="flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] ">
            <Text
              className="font-bold font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              as="h2"
              variant="h2"
            >
              <div id="ambition">
                Impressum
              </div>
            </Text>
            <Text
              className="font-light font-rubik mt-[16px] sm:mt-[6px] md:mt-[8px] text-gray_901 w-[auto]"
              variant="body6"
            >
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
              Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
              Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
              10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
              hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
              Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
              werden wir diese Inhalte umgehend entfernen.
              Haftung für Links
            </Text>
          </Column>
        </Column>
        <Column
          className="flex flex-col font-rubik justify-start max-w-[1280px] mx-20 mt-[60px] sm:mt-[63px] md:mt-[82px] sm:pl-[15px] sm:pr-[15px] w-[100%]">
          <Column className="flex flex-col justify-start sm:mx-[0] sm:px-[0] sm:w-[100%] ">
            <Text
              className="font-bold font-poppins text-gray_900 tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              as="h2"
              variant="h2"
            >
              <div id="problem">
                Registereintrag
              </div>
            </Text>
            <Text
              className="font-light font-rubik mt-[16px] sm:mt-[6px] md:mt-[8px] text-gray_901 w-[auto]"
              variant="body6"
            >
              Eintragung im Registergericht: Mannheim <br />
              Registernummer: HRB 745395 <br />
              <br />
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
              Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
              Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
              beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
              von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </Text>
          </Column>
        </Column>
        <footer className="bg-gray_900 font-poppins mt-[160px] sm:mt-[63px] md:mt-[82px] w-[100%]">
          <Column
            className="flex flex-col justify-start max-w-[1271px] sm:mb-[18px] md:mb-[24px] mb-[47px] md:ml-[166px] ml-[auto] md:mr-[168px] mr-[auto] sm:mt-[19px] md:mt-[25px] mt-[50px] sm:mx-[0] sm:pl-[15px] sm:pr-[15px] sm:px-[0] w-[100%]">
            <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-between w-[100%]">
              <Stack className="font-montserrat h-[65px] relative w-[13%]">
                <Column className="absolute flex flex-col items-center justify-start w-[100%]">
                  <Row className="flex flex-row md:flex-wrap sm:flex-wrap items-center justify-end w-[100%]">
                    <Stack
                      className="bg-cover bg-no-repeat h-[60px] ml-[1px] sm:p-[1px] md:p-[20px] p-[5px] relative w-[75%]"
                    >
                      <img src={logo} alt="Logo" height="40px" />
                    </Stack>
                  </Row>
                </Column>
              </Stack>
              <Row
                className="flex flex-row md:flex-wrap sm:flex-wrap font-poppins items-start justify-center sm:mx-[0] sm:px-[0] sm:w-[100%] w-[56%]">
                <Text
                  className="font-medium mb-[1px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateAmbition();
                  }}
                >
                  Our ambition
                </Text>
                <Text
                  className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateProblem();
                  }}
                >
                  Problem
                </Text>
                <Text
                  className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateBenefits();
                  }}
                >
                  Benefits
                </Text>
                <Text
                  className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateCase();
                  }}
                >
                  Business Case
                </Text>
                <Text
                  className="font-medium sm:ml-[17px] md:ml-[23px] ml-[45px] mt-[2px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateTrust();
                  }}
                >
                  Trust
                </Text>
                <Text
                  className="font-medium mb-[1px] sm:ml-[17px] md:ml-[23px] ml-[45px] text-white_A700 w-[auto]"
                  variant="body6"
                  onClick={() => {
                    handleNavigateCareer();
                  }}
                >
                  Career
                </Text>
              </Row>
              <Button
                className="cursor-pointer font-medium font-poppins min-w-[19%] text-[15px] text-center text-white_A700 tracking-ls03 md:tracking-ls1 sm:tracking-ls1 w-[max-content]"
                variant="OutlineGray9003d"
                onClick={() => {
                  handleEmailSales();
                }}
              >
                Get free assessment
              </Button>
            </Row>
            <Row
              className="flex flex-row md:flex-wrap sm:flex-wrap items-center sm:mt-[19px] md:mt-[25px] mt-[50px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[96%]">
              <Text
                className="not-italic text-white_A700 w-[auto]"
                variant="body8"
              >
                <span className="text-white_A700 text-[15px] font-poppins font-normal">
                  © 2022. Data simpl. All righs reserved.
                </span>
              </Text>
              <Row
                className="flex flex-row md:flex-wrap sm:flex-wrap items-start justify-between md:ml-[365px] ml-[709px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[19%]">
                <Text
                  className="not-italic text-white_A700 w-[auto]"
                  variant="body8"
                >
                  Privacy policy
                </Text>
                <Text
                  className="not-italic text-white_A700 w-[auto]"
                  variant="body8"
                  onClick={handleNavigate}
                >
                  Impressum
                </Text>
              </Row>
            </Row>
          </Column>
        </footer>
      </Column>
    </>
  );
};
export default Impressum;
