import { ErrorMessage } from "../../components/ErrorMessage";
import React from "react";
import PropTypes from "prop-types";
const variants = {
  FillGray51: "bg-gray_51",
  OutlineBlue500: "bg-white_A700 border border-blue_500 border-solid",
  OutlineBlue500_1: "border border-blue_500 border-solid shadow-bs1"
};
const shapes = {
  RoundedBorder32: "rounded-radius32",
  RoundedBorder23: "rounded-radius23"
};
const sizes = {
  sm: "md:pb-[10px] pb-[20px] sm:pb-[7px] sm:pt-[10px] md:pt-[13px] pt-[26px] md:px-[10px] sm:px-[15px] px-[20px]",
  md: "md:p-[13px] p-[27px] sm:px-[15px] sm:py-[10px]"
};
const Input = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name,
      placeholder,
      type = "text",
      children,
      errors = [],
      label = "",
      prefix,
      suffix,
      shape,
      variant,
      size,
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <div
          className={`${wrapClassName} ${shapes[shape] || ""} ${
            variants[variant] || ""
          } ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input
            ref={ref}
            className={`${className} bg-transparent border-0`}
            type={type}
            name={name}
            placeholder={placeholder}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  }
);
Input.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.oneOf(["RoundedBorder32", "RoundedBorder23"]),
  variant: PropTypes.oneOf([
    "FillGray51",
    "OutlineBlue500",
    "OutlineBlue500_1"
  ]),
  size: PropTypes.oneOf(["sm", "md"])
};
Input.defaultProps = {
  wrapClassName: "",
  className: "",
  name: "",
  placeholder: "",
  type: "text",
  shape: "RoundedBorder32",
  variant: "FillGray51",
  size: "md"
};
export { Input };
