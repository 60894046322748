import { ErrorMessage } from "../ErrorMessage/index.js";
import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
const selectOptions = [
  { value: "option1", label: "Option1" },
  { value: "option2", label: "Option2" },
  { value: "option3", label: "Option3" }
];
const variants = {
  OutlineIndigo51: "border border-gray-300 border-solid",
  FillGray53: "bg-gray_53"
};
const shapes = {
  RoundedBorder16: "rounded-radius16",
  RoundedBorder32: "rounded-radius32",
  RoundedBorder8: "rounded-radius8"
};
const sizes = {
  sm: "py-[9px]",
  md: "pb-[9px] pt-[12px]",
  lg: "pl-[17px] py-[17px]",
  xl: "pb-[25px] pl-[20px] pt-[29px]"
};
const SelectBox = React.forwardRef(
  (
    {
      children,
      placeholder = "Select",
      className,
      options = selectOptions,
      isSearchable = false,
      placeholderClassName = "",
      isMulti = false,
      onChange,
      value,
      errors = [],
      indicator,
      shape,
      variant,
      size,
      ...restProps
    },
    ref
  ) => {
    const [selectedVal, setSelectedVal] = React.useState(value);
    const handleChange = (data) => {
      setSelectedVal(data);
      if (isMulti) {
        onChange?.(data?.map((d) => d.value) || []);
      } else {
        onChange?.(data?.value);
      }
    };
    return (
      <>
        <Select
          ref={ref}
          options={options}
          className={`${className} ${shapes[shape] || ""} ${
            variants[variant] || ""
          } ${sizes[size] || ""} common-select`}
          placeholder={
            <div className={placeholderClassName}>{placeholder}</div>
          }
          isSearchable={isSearchable}
          isMulti={isMulti}
          components={{
            IndicatorSeparator: () => null,
            ...(indicator && { DropdownIndicator: () => indicator })
          }}
          value={selectedVal}
          onChange={handleChange}
          styles={{
            container: (provided) => ({
              ...provided,
              zIndex: 0
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: "transparent",
              border: "0 !important",
              boxShadow: "0 !important",
              minHeight: "auto",
              "&:hover": {
                border: "0 !important"
              }
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected && "#fafafa",
              backgroundColor: state.isSelected && "#f8f8f9",
              "&:hover": { backgroundColor: "#f8f8f9", color: "#050f2b" }
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "inherit"
            }),
            input: (provided) => ({
              ...provided,
              color: "inherit",
              margin: "0",
              padding: "0"
              // height: "0",
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: "0"
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              paddingTop: "0px",
              paddingBottom: "0px"
            }),
            clearIndicator: (provided) => ({
              ...provided,
              padding: "0"
            }),
            multiValue: (provided) => ({
              ...provided,
              margin: "0"
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              padding: "0"
            }),
            menuPortal: (base) => ({ ...base, zIndex: 999999 }),
            placeholder: (base) => ({
              ...base,
              margin: 0
            })
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={(event) => {
            return event.target.id === "scrollContainer";
          }}
          {...restProps}
        />
        <ErrorMessage errors={errors} />
        {children}
      </>
    );
  }
);
SelectBox.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  isSearchable: PropTypes.bool,
  placeholderClassName: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  shape: PropTypes.oneOf([
    "RoundedBorder16",
    "RoundedBorder32",
    "RoundedBorder8"
  ]),
  variant: PropTypes.oneOf(["OutlineIndigo51", "FillGray53"]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"])
};
SelectBox.defaultProps = {
  placeholder: "Select",
  className: "",
  isSearchable: false,
  placeholderClassName: "",
  isMulti: false,
  value: "",
  shape: "",
  variant: "",
  size: "",
  options: selectOptions,
  onChange: () => {
  }
};
export { SelectBox };
