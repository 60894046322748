import { Img } from "../Img";
import logo from "../../assets/images/logo.svg";
import React, { useEffect, useState } from "react";
import {
  handleNavigateEventMonitoring,
  handleNavigateSupport,
  handleNavigateSecurity,
  handleNavigateDashboard,
  handleNavigateSettings,
  handleNavigateWorkspace,
  handleNavigateUploadData,
  handleNavigatePaymentSettings,
  handleNavigateSwagger,
  handleNavigateDataCatalog,
  handleNavigateOverviewDashboard,
} from "./navigateHandler";
import './navigateBox.css'
export function BlueNavigationBar(props) {
  const searchParams = new URLSearchParams(window.location.search);
  console.log(window.location.search);
const showMenuParam = searchParams.get("showMenu");
const initialShowMenuState = showMenuParam === "true";
// initialShowMenuState
    const [showMenu, setShowMenu] = useState(true);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    // Initial setup
    setScreenHeight(window.innerHeight);

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

    useEffect(()=>{
      if (props && typeof props.setIsSideBar === 'function')
    {
props?.setIsSideBar(initialShowMenuState)
    }

    },[showMenu])
    const toggleMenu = () => {
    setShowMenu(!showMenu);
        if (props && typeof props?.setIsSideMenuShow === 'function')
    {
 props?.setIsSideMenuShow(!showMenu)

    }
   
    if (props && typeof props.setIsSideBar === 'function')
    {
props?.setIsSideBar(!showMenu)


    }

  };

  const [menuItems,setMenuItems]=useState([
  {image:'images/img_mail_blue_500.svg', name:'Workspace', click:()=>handleNavigateWorkspace(true)},
    {image:'images/img_signal_blue_500.svg', name:'Data Catalog', click:()=>handleNavigateDataCatalog(true)},
       {image:'images/upload-file.svg', name:'Upload Data', click:()=> handleNavigateUploadData(true)},
    {image:'images/event-Moniter.svg', name:'Event Monitoring', click:()=> handleNavigateEventMonitoring(true)},
    {image:'images/dashboard_icon.svg', name:'Dashboards', click:()=> handleNavigateDashboard(true)},
    {image:'images/dashboard_icon.svg', name:'Widget Explorer', click:()=> handleNavigateOverviewDashboard(true)},
    {image:'images/img_blockscodetestcheckmark.svg', name:'Swagger', click:()=> handleNavigateSwagger(true)},
    {image:"images/img_file_blue_500.svg", name:'Settings', click:()=> handleNavigateSettings(true)},
    {image:'images/img_group.svg', name:'Security', click:()=> handleNavigateSecurity(true)},
    {image:'images/img_paymentsfinan.svg', name:'Payment settings', click:()=> handleNavigatePaymentSettings(true)},
    {image:'images/img_file_blue_500_28x28.svg', name:'Support', click:()=> handleNavigateSupport(true)},




])

const MainView1=()=>{


  return(

        <>
          <div className="bg-gray_900 flex flex-col gap-[23px] justify-start p-[62px]  rounded-tr-[32px] w-[100%] h-[100%] sm:rounded-tr-[0px] ">
               <img src={'images/close_icon.svg'} alt="Logo" className="h-[30px] w-[30px] cursor-pointer
               " onClick={toggleMenu}/>

              <img src={logo} alt="Logo" className="h-[80px] w-[260px]" />
{
  menuItems.map((item,index)=>{
    return(
<div className="flex items-center  space-x-5 space-y-5">
  <Img
                      src={item.image}
                      className="h-[28px] w-[28px]"
                      alt="mail"
                    />
                 
                    <span   onClick={()=>item.click()} className="cursor-pointer font-medium  text-white_A700 pb-[20px] text-[18px]">
                         {item.name}
                    </span>
                  
</div>

    )
  })
}
 </div>
         </>

  )
}
const MainViewUi=()=>{
    <>
{!showMenu?
 <img src={'images/MenuIcon.svg'} alt="Logo" className="cursor-pointer  h-[40px] w-[50px] absolute top-[20px] left-[10px] xl:hidden" onClick={toggleMenu} />

  :null}
   {showMenu?
     <aside className="flex flex-col absolute z-10 xl:hidden  ">
  { MainView1()}
   </aside>
   :null}
        <aside className="flex flex-col md:hidden sm:hidden  lg:hidden">
  {MainView1()}
  </aside>

  </>
}

  const menuClasses = showMenu ? "bg-gray_900 h-screen p-4   w-[344px] sm:w-full  md:w-full sm:h-[120%] rounded-tr-[32px] sm:rounded-tr-[0px]  md:rounded-tr-[0px] " : "hidden";


  return (
  <>
  <div style={{ }} className="flex  bg-gray_900  bg  ">
 {!showMenu?
 <img src={'images/MenuIcon.svg'} alt="Logo"  className="cursor-pointer fixed h-[40px] w-[50px]  top-[30px] left-[20px] sm:top-[200px]" onClick={toggleMenu} />
  :null}
   {showMenu?<div   className={menuClasses}  >
    {/* images/White_MenuIcon.svg */}
        {/* <img src={'images/White_MenuIcon.svg'} alt="Logo" className=" h-[40px] w-[50px] cursor-pointer
               " onClick={toggleMenu}/> */}

              <img src={logo} alt="Logo" className="h-[80px] w-[180px]" />
   
{
  menuItems.map((item,index)=>{
    return(
<div className="flex items-center  space-x-5 space-y-5 ">
  <Img
                      src={item.image}
                      className="h-[28px] w-[28px]"
                      alt="mail"
                    />
                    <span   onClick={()=>item.click()} className="cursor-pointer font-medium  text-white_A700 pb-[20px] text-[18px]">
                         {item.name}
                    </span>
                       </div>

    )
  })
}
    </div>:null}

    </div>
  </>
  );
}

