import React, { useState, useEffect } from "react";
import { BlueNavigationBar } from "../../components/NewBlueNavigationBarLeft/navigationBox";
import Header from "../../components/NewHeader/Header";
import DashBoardButton from 'components/DashBoardButton/DashBoardButton';
import './NewOverViewDashBoard.css';
import { prepareHeaderInstance } from "../../components/userAuth";

const baseurl = process.env.REACT_APP_BASE_URL;

export default function NewOverViewDashBoard() {
  const [iframeUrl, setIframeUrl] = useState('');
  const [isScreenFocused, setIsScreenFocused] = useState(true);
  const [dataProducts, setDataProducts] = useState([]);
  const [isSideBar, setIsSideBar] = useState(new URLSearchParams(window.location.search).get("showMenu") === "true");

  // Handle window focus
  useEffect(() => {
    const handleFocus = () => setIsScreenFocused(true);
    const handleBlur = () => setIsScreenFocused(false);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  // Handle iframe messages
  useEffect(() => {
    const handleMessage = (event) => {
      console.log("event", event);
      const urlToCheck = iframeUrl.replace('/dashboard', '');
      if (event.origin !== urlToCheck) {
        return;
      }

      if (event.data === 'reloadRequest') {
        window.location.reload();
      }
    };

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [iframeUrl]);

  // Fetch data products
  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosInstance = await prepareHeaderInstance();
        const response = await axiosInstance.get(`${baseurl}dataCatalog`);
        const allMetadata = response.data.map(item => item.metadata);
        setDataProducts(allMetadata);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="flex">
        <BlueNavigationBar setIsSideBar={setIsSideBar} />
        <div className="flex-1">
          <div className="mb-[10px]">
            <Header title="data simpl" subtitle="Welcome to" />
          </div>
          {iframeUrl ? (
            <iframe src={iframeUrl} title="Dashboard" width="100%" height="85%" />
          ) : (
            <div className='flex flex-col p-10'>
              {dataProducts.length > 0 ? (
                <div className={`flex p-10 ${dataProducts.length < 3 ? "justify-center space-x-2" : "justify-between"} flex-wrap Direction`}>
                  {dataProducts.map((item, index) => (
                    <DashBoardButton key={index} title={item.dataproduct} setIframeUrl={setIframeUrl} />
                  ))}
                </div>
              ) : (
                <div className="text-center">There are no dashboards generated.</div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
