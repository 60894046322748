import papa from "papaparse";

const characters = "(),+%$";

function parseNumber(value) {
  value = value || "";

  if (value)
    characters.split("").forEach((char) => {
      value = value.replace(char, "");
    });

  return Number(value);
}

export const palette = ["#6C39DE", "#CF86FC", "#E75B75", "#3EC8BB", "#FFA350"];


export function parseFunc(func, fallback) {
  try {
    if (func.includes("function(data)")) {
      return eval(func.replace("function(data)", "data => "));
    }
    if (func.includes("(data) => ")) {
      return eval(func.replace("(data) =>", "data =>"));
    }
    if (func.includes("data => ")) {
      return eval(func);
    }

    return eval(`data => ${func}`);
  } catch (err) {
    return fallback;
  }
}

const limit = [1000, 1000000, 1000000000, 1000000000000];
const unit = ["K", "M", "B", "T"];

export function formatNumber(value) {
  for (let i = limit.length - 1; i >= 0; i--) {
    const scaled = value / limit[i];
    if (scaled > 1) return `${scaled.toFixed(1)}${unit[i]}`;
  }
  return value.toFixed(1);
}

export function className(...args) {
  return args.filter((x) => x).join(" ");
}


export function isEqual(a, b) {
  // If a and b are both objects
  if (typeof a === 'object' && typeof b === 'object') {
    // If they do not have the same number of keys
    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }

    // Check each key element in a
    for (let key in a) {
      if (!isEqual(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }

  // If a and b are both arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    // If they do not have the same length
    if (a.length !== b.length) {
      return false;
    }

    // Check each element in a
    for (let i = 0; i < a.length; i++) {
      // If they are not equal, then a and b are not equal
      if (!isEqual(a[i], b[i])) {
        return false;
      }
    }
    return true;
  }
  return a === b;
}

export function isDataValid(dataset) {
  try {
    const data = parseData(dataset);
    return !!data.length;
  } catch (err) {
    return false;
  }
}

export function parseData(dataset) {
  const result = papa.parse(dataset, {
    header: true,
  });

  if (!result.data) return [];

  const emptyColumns = result.meta.fields || [];

  if (!emptyColumns.length) return result.data;

  return result.data
    .map((row) => {
      delete row[""];
      return row;
    })
    .slice(0, 1000);
}

export function stringifyData(dataset, delimiter) {
  return papa.unparse(dataset, {
    delimiter: delimiter || "\t",
  });
}

