import { ErrorMessage } from "../ErrorMessage";
import React from "react";
import PropTypes from "prop-types";
const variants = {
  OutlineBlue500: "bg-white_A700 border border-blue_500 border-solid",
  OutlineBluegray507f: "bg-gray_53 border border-bluegray_50_7f border-solid",
  FillBlack9000c: "bg-black_900_0c",
  srcFillGray53: "bg-gray_53",
  srcFillWhiteA700: "bg-white_A700"
};
const shapes = {
  RoundedBorder23: "rounded-radius23",
  RoundedBorder20: "rounded-radius20",
  RoundedBorder6: "rounded-radius6",
  srcRoundedBorder15: "rounded-radius15"
};
const sizes = {
  sm: "py-[6px]",
  md: "pb-[20px] pt-[26px] px-[12px]",
  lg: "pl-[20px] py-[29px]",
  smSrc: "pb-[17px] pr-[14px] pt-[14px]"
};
const Input = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name,
      placeholder,
      type = "text",
      children,
      errors = [],
      label = "",
      prefix,
      suffix,
      shape,
      variant,
      size,
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <div
          className={`${wrapClassName} ${shapes[shape] || ""} ${
            variants[variant] || ""
          } ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input
            ref={ref}
            className={`${className} bg-transparent border-0`}
            type={type}
            name={name}
            placeholder={placeholder}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  }
);
Input.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.oneOf([
    "RoundedBorder23",
    "RoundedBorder20",
    "RoundedBorder6",
    "srcRoundedBorder15"
  ]),
  variant: PropTypes.oneOf([
    "OutlineBlue500",
    "OutlineBluegray507f",
    "FillBlack9000c",
    "srcFillGray53",
    "srcFillWhiteA700"
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg", "smSrc"])
};
Input.defaultProps = {
  wrapClassName: "",
  className: "",
  name: "",
  placeholder: "",
  type: "text",
  shape: "srcRoundedBorder15",
  variant: "srcFillGray53",
  size: "smSrc"
};
export { Input };
