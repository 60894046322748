import React from "react";
import styles from "./style.module.scss";
import { parseFunc, formatNumber } from "./Functions/utils";
import { ErrorBoundary } from "./ErrorBoundary";

export function PerformanceIndicator(props) {
  const myEvalFunction = React.useMemo(() => {
    return parseFunc(props.config.javascriptFunction, (data) => 0);
  }, [props.config]);

  const value = React.useMemo(() => {
    const val = myEvalFunction(props.data);
    if (typeof val === "number") return formatNumber(val);
    return val;
  }, [myEvalFunction, props.data]);

  return (
    <ErrorBoundary>
      <div className={styles.performanceIndicator}>
        <div className={styles.label}>
          {props.config.title.replace("Average", "Avg.")}
        </div>
        <div className={styles.value}>{value}</div>
      </div>
    </ErrorBoundary>
  );
}
