import React from "react";
import Routes from "./Routes";
import {Auth} from 'aws-amplify';
//2.
import awsconfig from "./aws-exports";
//3.
//4.
// Amplify.configure(awsconfig);
Auth.configure(awsconfig);

function App() {
  return <Routes />;
}
export default App;
