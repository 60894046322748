/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon, Text, View } from "@aws-amplify/ui-react";
export default function DataObject(props) {
  const {
    DataSteward,
    MajorVersion,
    MinorVersion,
    PublicationDate,
    DataObject,
    overrides,
    ...rest
  } = props;
  return (
    <View
      width="337px"
      height="189px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "DataObject")}
      {...rest}
    >
      <View
        width="337px"
        height="189px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        border="5px SOLID rgba(239,240,240,1)"
        borderRadius="20px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Rectangle 32")}
      ></View>
      <Icon
        width="284px"
        height="0px"
        viewBox={{ minX: 0, minY: 0, width: 284, height: 1 }}
        paths={[
          {
            d: "M0 0L284 0L284 -3L0 -3L0 0Z",
            stroke: "rgba(34,141,241,1)",
            fillRule: "nonzero",
            strokeWidth: 3,
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="24.87%"
        bottom="75.13%"
        left="7.72%"
        right="8.01%"
        {...getOverrideProps(overrides, "Line 1")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="right"
        display="block"
        direction="column"
        justifyContent="unset"
        width="158px"
        height="30px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="30.69%"
        bottom="53.44%"
        left="45.1%"
        right="8.01%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={DataSteward}
        {...getOverrideProps(overrides, "body34832544")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="right"
        display="block"
        direction="column"
        justifyContent="unset"
        width="158px"
        height="30px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="46.03%"
        bottom="38.1%"
        left="45.1%"
        right="8.01%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={PublicationDate}
        {...getOverrideProps(overrides, "body34872497")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="right"
        display="block"
        direction="column"
        justifyContent="unset"
        width="158px"
        height="30px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="61.38%"
        bottom="22.75%"
        left="45.1%"
        right="8.01%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={MajorVersion}
        {...getOverrideProps(overrides, "body34872498")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="right"
        display="block"
        direction="column"
        justifyContent="unset"
        width="158px"
        height="30px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="76.72%"
        bottom="7.41%"
        left="45.1%"
        right="8.01%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={MinorVersion}
        {...getOverrideProps(overrides, "body34872499")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="166px"
        height="30px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="30.69%"
        bottom="53.44%"
        left="7.72%"
        right="43.03%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Data Steward"
        {...getOverrideProps(overrides, "body34872496")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="166px"
        height="30px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="30.69%"
        bottom="53.44%"
        left="7.72%"
        right="43.03%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Data Steward"
        {...getOverrideProps(overrides, "body34872500")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="166px"
        height="30px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="76.72%"
        bottom="7.41%"
        left="7.72%"
        right="43.03%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Minor Version"
        {...getOverrideProps(overrides, "body34872501")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="166px"
        height="30px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="46.03%"
        bottom="38.1%"
        left="7.72%"
        right="43.03%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Publication Date"
        {...getOverrideProps(overrides, "body34872502")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="166px"
        height="30px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="61.38%"
        bottom="22.75%"
        left="7.72%"
        right="43.03%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Major Version"
        {...getOverrideProps(overrides, "body34872503")}
      ></Text>
      <View
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="6.35%"
        bottom="80.95%"
        left="86.35%"
        right="6.53%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "mail")}
      >
        <Icon
          width="20px"
          height="16px"
          viewBox={{ minX: 0, minY: 0, width: 20, height: 16 }}
          paths={[
            {
              d: "M10 9L9.42654 9.81923C9.77086 10.0603 10.2291 10.0603 10.5735 9.81923L10 9ZM2 1L18 1L18 -1L2 -1L2 1ZM18 1C18.5477 1 19 1.45228 19 2L21 2C21 0.347715 19.6523 -1 18 -1L18 1ZM19 2L19 14L21 14L21 2L19 2ZM19 14C19 14.5477 18.5477 15 18 15L18 17C19.6523 17 21 15.6523 21 14L19 14ZM18 15L2 15L2 17L18 17L18 15ZM2 15C1.45228 15 1 14.5477 1 14L-1 14C-1 15.6523 0.347715 17 2 17L2 15ZM1 14L1 2L-1 2L-1 14L1 14ZM1 2C1 1.45228 1.45228 1 2 1L2 -1C0.347715 -1 -1 0.347715 -1 2L1 2ZM19.4265 1.18077L9.42654 8.18077L10.5735 9.81923L20.5735 2.81923L19.4265 1.18077ZM10.5735 8.18077L0.573462 1.18077L-0.573462 2.81923L9.42654 9.81923L10.5735 8.18077Z",
              stroke: "rgba(27,112,192,1)",
              fillRule: "nonzero",
              strokeLinejoin: "round",
              strokeWidth: 2,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="16.67%"
          bottom="16.67%"
          left="8.33%"
          right="8.33%"
          {...getOverrideProps(overrides, "Icon")}
        ></Icon>
      </View>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="700"
        color="rgba(34,141,241,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="178px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="6.35%"
        bottom="80.95%"
        left="7.72%"
        right="39.47%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={DataObject}
        {...getOverrideProps(overrides, "heading")}
      ></Text>
    </View>
  );
}
