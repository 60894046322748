import React from 'react'

export default function JobsComponent(props) {
  return (
    <div className={props.style}>
        <div className='flex flex-row w-full justify-between'>
      <p className='text-white text-[28px] font-bold font-poppins '>{props.title}</p>
 
      <img
      src={props.showCompetence?'images/upArrow.svg':'images/downWard.svg'}
      className='w-[50px] h-[50px]'
      onClick={props.onPressArrow}
      />
  
    </div>
    <p className='text-white text-[17px] mt-5 font-light font-poppins '>{props.description}</p>
{props.showCompetence?
<div>
       {props.Competenceprofile.map((item)=>{
  return( <div className='my-4 flex-row flex space-x-3 items-center' >
    <img
    src={'images/tick.svg'}
    className='w-[15px] h-[15px]'
    />
             <p className='text-white font-[17px] font-light font-poppins'>
        {item}
         </p>
         </div>)})
         }
         <button onClick={props.onButtonPress} className='bg-[#1b8ff7] p-5 text-white rounded-[20px]'>
Apply Now
         </button>
         </div>
          :null}
    </div>
  )
}
