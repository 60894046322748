import React from "react";
import styles from "./style.module.scss";
import { className } from "./Functions/utils";

export function Button(props) {
  return (
    <button
      className={className(
        styles.button,
        props.outline && styles.outline,
        props.disabled && styles.disabled,
        props.className
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
