import React, { useEffect, useState } from "react";
import './workspace.css'
import Hexagonal from '../components/Hexagonal'
import { prepareHeaderInstance } from "../../../components/userAuth";

const baseurl = process.env.REACT_APP_BASE_URL;
const webUrl = process.env.REACT_APP_OWN_URL;


const Workspace = (props) => {
  const [newReport, setNewReport] = useState([]);
  const [allReports, setAllReports] = useState([]);
  const [notSeenReports, setNotSeenReports] = useState([]);
  const [statusInbound, setStatusInbound] = useState('200');
  const [statusOutbound, setStatusOutbound] = useState('200');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosInstance = await prepareHeaderInstance();
        const response = await axiosInstance.get(baseurl.concat("loadingDashboard"));
 
        if (response.status === 200) {
    
          setNewReport(response.data);
        }
   
      } catch (error) {
        console.error(error);
        setStatusOutbound('400')
        setStatusInbound('400')
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosInstance = await prepareHeaderInstance();
        const response = await axiosInstance.get(baseurl.concat("dataCatalogDashboard"));
        if (response.status === 200) {
          const data = response.data;
          const notSeen = [];
          data.forEach((item) => {
            if (item.seen === 0) {
              notSeen.push(item);
            }
          });
          setNotSeenReports(notSeen);
          setAllReports(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
            setStatusOutbound('400')
        setStatusInbound('400')
      }
    };
    fetchData();
  }, []);




  const DownArrow=()=>{
    return(
      <div>
       <Hexagonal
  backgroundImageStyle={"flex -mt-3 flex-col items-center justify-between h-[30px] w-[33px] bg-auto bg-no-repeat bg-contain  "}
  src={'/images/RectangleInboundBlack.png'}
  upperText={" "}
  lowerText={" "}
  middleImage={"/external/downArrow.svg"}
   middleImageStyle={`w-[12px] h-[14px] mt-0 `}
  noCount={true}

   />
     <Hexagonal
  backgroundImageStyle={"flex  flex-col items-center justify-between h-[30px] w-[33px] bg-auto bg-no-repeat bg-contain  "}
  src={'/images/RectangleInboundBlack.png'}
  upperText={" "}
  lowerText={" "}
 middleImage={"/external/downArrow.svg"}
   middleImageStyle={`w-[12px] h-[14px]  `}
     noCount={true}

   />
     <Hexagonal
  backgroundImageStyle={"flex  flex-col items-center justify-between  h-[30px] w-[33px] bg-auto bg-no-repeat bg-contain  "}
  src={'/images/RectangleInboundBlack.png'}
  upperText={" "}
  lowerText={" "}
 middleImage={"/external/downArrow.svg"}
   middleImageStyle={`w-[12px] h-[14px]  `}
     noCount={true}

   />
   </div>
    )
  }
  const InboundView=()=>{
    return(
      <div className='flex justify-center items-center flex-col '>
        <Hexagonal
          backgroundImageStyle={"flex  flex-col items-center justify-between p-5 h-[184px] w-[204px] bg-auto bg-no-repeat bg-contain  "}
          //'/images/RectangleBlue.png'
          src={statusInbound=='400'?'/images/RedRectangle.png':'/images/RectangleInbound.png'}
          upperText={"INBOUND"}
          lowerText={"API"}
          middleImage={"/external/SettingIcon.svg"}
          middleImageStyle={`w-[45px] h-[45px] mt-[30px]  rotate-45`}
          secondMiddleImage={'/external/loaderArrow.svg'}
          secondMiddleImageStyle={`w-[85px] h-[85px] -mt-[50px] `}
          count={2}
     
        />
        <img
          src='/external/downArrow.svg'
          className='-mt-2'
        />
        <Hexagonal
          backgroundImageStyle={"flex flex-col items-center justify-between p-5 h-[119px] w-[131px] bg-auto bg-no-repeat bg-contain  "}
          src={'/images/RectangleInboundBlack.png'}
          upperText={" "}
          lowerText={" "}
          middleImage={"/external/plugIcon.svg"}
          middleImageStyle={`w-[35px] h-[35px]  `}
          count={10}
          countStyle={"-ml-[90px] "}
        />
        { DownArrow()}
      </div>

    )
  }

const StatusView=()=>{
  return(
     <div className='flex flex-col items-center justify-center space-x-2 sm:mt-4 md:mt-4  '>
    {/* <p className='text-[#1B58BC] font-bold sm:my-4 md:my-4'>STATUS</p> */}
    <div className='flex  flex-row sm:flex-col sm:space-y-3 md:flex-col md:space-y-3'>
        <Hexagonal
  backgroundImageStyle={"flex flex-col items-center justify-between  h-[60px] w-[60px] bg-auto bg-no-repeat bg-contain sm:mx-0 md:mx-0  "}
  src={'/images/RectangleBlue.png'}
  upperText={" "}
  lowerText={" "}
  middleImage={"/external/RightArrow.svg"}
   middleImageStyle={`w-[15px] h-[15px] sm:rotate-90 md:rotate-90 `}
      noCount={true}
   />
       <Hexagonal
  backgroundImageStyle={"flex mx-3  flex-col items-center justify-between  h-[60px] w-[60px] bg-auto bg-no-repeat bg-contain  sm:mx-0 md:mx-0"}
  src={'/images/RectangleBlue.png'}
  upperText={" "}
  lowerText={" "}
  middleImage={"/external/RightArrow.svg"}
   middleImageStyle={`w-[15px] h-[15px] sm:rotate-90 md:rotate-90 `}
      noCount={true}

   />
        <Hexagonal
  backgroundImageStyle={"flex  flex-col items-center justify-between  h-[60px] w-[60px] bg-auto bg-no-repeat bg-contain  sm:mx-0  md:mx-0"}
  src={'/images/RectangleBlue.png'}
  upperText={" "}
  lowerText={" "}
  middleImage={"/external/RightArrow.svg"}
   middleImageStyle={`w-[15px] h-[15px] sm:rotate-90  md:rotate-90 `}
      noCount={true}

   />
   </div>
   </div>)
}
const DataAnalysisView=()=>{
  return(
    <div className='sm:mt-5 md:mt-5 -ml-40 flex flex-col justify-center items-center  sm:ml-0 md:ml-0'>
   <Hexagonal
  backgroundImageStyle={"flex flex-col   items-center justify-between p-5 h-[212px] w-[240px] bg-auto bg-no-repeat bg-contain -ml-[50px] sm:ml-[10px] md:ml-0 "}
  src={'/images/RectangleInbound.png'}
  upperText={" "}
  lowerText={" "}
  middleImage={"/images/AnalysizeIcon.png"}
  middleImageStyle={`w-[106px] h-[105px]`}
  count={newReport.length}
   />
   <div className='flex  items-center justify-center flex-row mt-10 -ml-[28px] sm:ml-[0px] md:ml-[0px] sm:flex-col md:flex-col lg:ml-[200px]'>
<div className='flex space-y-[143px] flex-col ml-[50px] justify-between items-center sm:flex-row md:flex-row sm:space-y-[0px] md:space-y-[0px] sm:w-full md:w-full  sm:space-x-[40px] md:space-x-[40px] sm:py-3 md:py-3  '>
  <p className='text-[#1c58bc] font-bold '>
    <a href={`${webUrl}dashboard`} rel="noopener noreferrer">NEW REPORT</a>
  </p>
  <p className='text-[#1c58bc] font-bold '>
    <a href={`${webUrl}dashboard`} rel="noopener noreferrer">USED REPORT</a>
  </p>


</div>
  <Hexagonal
  backgroundImageStyle={"flex flex-col  items-center justify-between p-5 h-[200px] w-[250px] bg-auto bg-no-repeat bg-contain  "}
  src={'/images/RectangleInboundBlack.png'}
  upperText={" "}
  lowerText={" "}
  middleImage={"/images/MailIcon.png"}
  middleImageStyle={`w-[80px] h-[80px] -mt-[0px] `}
  count={notSeenReports.length}
  countStyle={"-ml-[140px] -mt-[30px] sm:-ml-[160px]"}
  secondCount={allReports.length}
  secondCountStyle={'-ml-[150px] mt-[140px] sm:-mt-[27px]  sm:-mr-[350px] md:-ml-[170px] sm:-ml-[200px]'}
   />
   <div className='flex items-center space-y-2 justify-center flex-col mx-5'>
    <p className='text-[#1c58bc] font-bold '>DATA ANALYSIS</p>
 <Hexagonal
  backgroundImageStyle={"flex flex-col  items-center justify-between p-5 h-[50px] w-[50px] bg-auto bg-no-repeat bg-contain  "}
  src={'/external/BackgroundBlueDownArrow.png'}
  upperText={" "}
  lowerText={" "}
  noCount={true}

   />
     <Hexagonal
  backgroundImageStyle={"flex flex-col  items-center justify-between p-5 h-[50px] w-[50px] bg-auto bg-no-repeat bg-contain  "}
  src={'/external/BackgroundBlueDownArrow.png'}
  upperText={" "}
  lowerText={" "}
  noCount={true}

   />
      <Hexagonal
  backgroundImageStyle={"flex flex-col  items-center justify-between p-5 h-[50px] w-[50px] bg-auto bg-no-repeat bg-contain  "}
  src={'/external/BackgroundBlueDownArrow.png'}
  upperText={" "}
  lowerText={" "}
  noCount={true}

   />
   </div>
   <div className='flex flex-row justify-center space-x-10 items-center sm:flex-col-reverse sm:space-x-0 md:flex-col-reverse md:space-x-0'>
     <Hexagonal
  backgroundImageStyle={"flex flex-col  items-center justify-between p-5 h-[200px] w-[250px] bg-auto bg-no-repeat bg-contain  "}
  src={'/images/RectangleInboundBlack.png'}
  upperText={"  "}
  lowerText={"  "}
  middleImage={"/external/ConditionIcon.png"}
  middleImageStyle={`w-[120px] h-[100px]  `}
count={3}
countStyle={'ml-[0] -mr-[240px] mt-[60px]'}

   />
   <p className='text-[#1c58bc] font-bold '>DATA SCIENCE</p>
</div>
   </div>
</div>
  )
}
const OutBoundView=()=>{
  return(
   <div className=' flex justify-center items-center flex-col md:ml-[15px] sm:ml-[15px] '>
   <Hexagonal
  backgroundImageStyle={"flex flex-col items-center justify-between p-5 h-[184px] w-[204px] bg-auto bg-no-repeat bg-contain  "}
  //src={'/images/RectangleBlue.png'}
  src={statusOutbound=='400'?'/images/RedRectangle.png':'/images/RectangleInbound.png'}
  upperText={"OUTBOUND"}
  lowerText={"API"}
  middleImage={"/external/SettingIcon.svg"}
  middleImageStyle={`w-[45px] h-[45px] mt-[30px]  rotate-45`}
  secondMiddleImage={'/external/loaderArrow.svg'}
  secondMiddleImageStyle={`w-[85px] h-[85px] -mt-[50px] `}
  count={2}
   />
   <img
   src='/external/downArrow.svg'
   className='-mt-2'
   />
    <Hexagonal
  backgroundImageStyle={"flex flex-col items-center justify-between p-5 h-[119px] w-[131px] bg-auto bg-no-repeat bg-contain  "}
  src={'/images/RectangleInboundBlack.png'}
  upperText={" "}
  lowerText={" "}
  middleImage={"/external/plugIcon.svg"}
   middleImageStyle={`w-[35px] h-[35px]  `}
   count={10}
countStyle={"-ml-[90px] "}
   />

{DownArrow()}

   </div>


)
}
  return (
    <div className='flex sm:flex-col md:flex-col items-start sm:py-12 md:py-12  sm:items-center md:items-center '>
{InboundView()}
<div className='lg:-mr-[200px] mt-[150px] sm:mt-0 md:mt-0'>
 {StatusView()}
 </div>
{DataAnalysisView()}

<div className='flex flex-row -ml-[140px] md:flex-col sm:flex-col sm:ml-[0px] md:ml-[0px] lg:-ml-[200px] '>
 {StatusView()}
 <div className='sm:mt-3 md:mt-3'/>
{OutBoundView()}

</div>
     </div>
  )
}

export default Workspace
