   import React, { useState } from 'react'
import './Hexagonal.css'
   export default function Hexagonal(props) {
      const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const handleMouseEnter = (e) => {
    setShowTooltip(true);
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
     return (
<div 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
  className={`${props.backgroundImageStyle} tooltip-container` }
 style={{ backgroundImage: `url(${props.src})`,
     backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat'
}}
 >
        {props.secondCount?  <div className={`absolute -ml-[150px]  w-[37px] h-[37px] flex items-center justify-center rounded-full bg-[#228df1] ${props.secondCountStyle}`}
     style={{ width: `${40 + (props.secondCount / 10000)}px`, height: `${40 + (props.secondCount / 10000)}px` }}>
         <p className={`text-white text-sm `}>{props.secondCount>99999?"999.9K+":props.secondCount}</p> 
         </div>:null}
{!props.noCount ?      <div className={`absolute  -ml-[150px] flex items-center justify-center rounded-full bg-[#228df1] ${props.countStyle}`}
     style={{ width: `${40 + (props.count / 10000)}px`, height: `${40 + (props.count / 10000)}px` }}>
  <p className="text-white text-sm">{props.count > 99999 ? '999.9k+' : props.count?props.count:0}</p>
</div>:null}
 {props.upperText? <p className='text-[#1B58BC]  font-extrabold'>{props.upperText}</p>:null}
      {props.middleImage?<img
         src={props.middleImage}
         className={props?.middleImageStyle} 
         />:null}
      { props.secondMiddleImage?  <img
         src={props.secondMiddleImage}
         className={props.secondMiddleImageStyle}
         />:null  }
               {props.lowerText?<p className='text-[#1B58BC] font-extrabold'>{props.lowerText}</p>:null}
 {showTooltip&&props.upperText.length>1&& (
        <div className="tooltip" 
        >
          <span>{props.upperText}</span>
        </div>
      )}

    </div>

     )
   }
   
   
