import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function BarChartView() {
 const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
     // text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

 const data = {
  labels,
  datasets: [
    {
       label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};


const highestValue=Math.max(...data.datasets[0].data)>Math.max(...data.datasets[1].data)?Math.max(...data.datasets[0].data):Math.max(...data.datasets[1].data)

  return  <div style={{ width: '500px', height: '390px',}} className='mt-[80px] sm:mt-20 md:mt-20'>
              <div className='flex flex-col space-y-3 -mb-10'>
<span className='text-[#c9c9c9] '>Data Throughput Per Day</span>
<span className='font-bold'>{highestValue}</span>
</div>
      <Bar options={options} data={data} />
    </div>;
}
