// example code https://github.com/cruip/tailwind-dashboard-template/tree/main/src/partials
import React, { useEffect, useRef, useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { BlueNavigationBar } from "../../components/NewBlueNavigationBarLeft/navigationBox";
import Header from "../../components/NewHeader/Header";
import Workspace from "../../teleporthq/src/views/workspace";
import './AuthenticatorStyles.css';
import DashBoardComponent from "components/DashBoardComponent/DashBoardComponent";
import ChartView from "components/ChartView/ChartView";
import Sticky from 'react-sticky-el';
const Workspacex = () => {
    const divRef = useRef(null);
   const [divHeight,setDivHeight]=useState(1500)

    useEffect(() => {
    // Access the div element's height after it has been rendered
    if (divRef.current) {
      const divH = divRef.current.offsetHeight;
      console.log("The height of the div is: " + divH + " pixels");
      setDivHeight(divH);
    } else {
      console.log("The element was not found");
    }
  }, []);

   const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update screenWidth when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data = [
    {
      header: "Start your journey",
      detail: "To Start process go to upload data and ingest some new data",
      image: 'images/img_link.svg',
      link: "/uploaddata"
    },
    {
      header: "Create New Data Product",
      detail: "Similar data product teams have started to define a customer data product",
      image: 'images/img_link.svg',
      link: "/datacatalog"
    },
    {
      header: "Monitoring",
      detail: "If you are interessted in your platform, have a look into the monitoring",
      image: 'images/img_link.svg',
      link: "/monitoring"
    },

  ]
  const searchParams = new URLSearchParams(window.location.search);
  console.log(window.location.search);
const showMenuParam = searchParams.get("showMenu");
const initialShowMenuState = showMenuParam === "true";
  const [isSideBar,setIsSideBar]=useState(initialShowMenuState)
  useEffect(()=>{
setIsSideBar(isSideBar)

  },[isSideBar])
  return (
    <>
      <div
        className="flex  "
      >


        <BlueNavigationBar setIsSideBar={setIsSideBar}/>

        <div className="flex-1 ">
{/* <Sticky > */}
<div  className="mb-[20px]">
          <Header title="data simpl" subtitle="Welcome to" />
          </div>
          {/* </Sticky> */}
          <div className={`flex rotateView items-start  sm:flex-col md:flex-col sm:items-center md:items-center m-1  ${'flex-row'}  `}>
            <div style={screenWidth>991?{ height: `${divHeight>500?divHeight:500}px` }:{flex:1,margin:30 }}  className={` bg-[#f3f4f6] flex   mt-5 p-[10px] w-[100%] mx-3  rounded-[24px] justify-center items-center`}>
              <Workspace />

            </div>
            <div ref={divRef}  className={` bg-[#f3f4f6]  mt-5 inline-flex items-center justify-center ${"flex-col  "} rounded-[24px]  `}>
  {data.map((item, index) => (
   
      <DashBoardComponent header={item.header} detail={item.detail} image={item.image} link={item.link} />
  
  ))}
</div>


          </div>
<ChartView/>
        </div>
      </div>

    </>

  );
};
export default withAuthenticator(Workspacex, { hideSignUp: true, });
