import React from "react";
import Dashboard from "./pages/Dashboard/NewOverViewDashBoard.js";
import DataCatalog from "pages/Datacatalog";
import Eventmoni from "pages/300Eventmoni";
import Uploaddata from "pages/200Uploaddata";
import Financial from "pages/FinancialPage";
import Ourteam from "pages/400Ourteam";
import Latestnews from "pages/300Latestnews";
import LatestnewsOne from "pages/300LatestnewsOne";
import Createtem from "pages/200Createtem";
import Homepage from "pages/100Homepage";
import Template from "pages/Template";
import Swagger from "pages/Swagger";
import NewAccountPage from "pages/NewAccount";
import Workspace from "pages/Workspace";
import Test from "pages/Test";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Impressum2 from "pages/Impressum2";
import CreateSchemaPage from "./pages/CreateSchema";
import AllDashboards from "./pages/DashboardOverview";

const ProjectRoutes = () => {
  return <Router>
    <Routes>
      <Route path="/router" element={<Home />} />
      <Route path="/" element={<Homepage />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/createtem" element={<Createtem />} />
      <Route path="/latestnewsone" element={<LatestnewsOne />} />
      <Route path="/latestnews" element={<Latestnews />} />
      <Route path="/ourteam" element={<Ourteam />} />
      <Route path="/datacatalog" element={<DataCatalog />} />
      <Route path="/workspace" element={<Workspace />} />
      <Route path="/uploaddata" element={<Uploaddata />} />
      <Route path="/swagger" element={<Swagger />} />
      <Route path="/template" element={<Template />} />
      <Route path="/eventmoni" element={<Eventmoni />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/impressum" element={<Impressum2 />} />
      <Route path="/newaccount" element={<NewAccountPage/>} />
      <Route path="/overviewDash" element={<AllDashboards/>} />
      <Route path="/financial" element={<Financial />} />
      <Route path="/test" element={<Test />} />
      <Route path="/createschema" element={<CreateSchemaPage />} />
    </Routes>
  </Router>;
};
export default ProjectRoutes;