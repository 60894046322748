import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
ChartJS.register(ArcElement, Tooltip, Legend);

 

export function DoughnutChartView() {
  const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
const highestValue = Math.max(...data.datasets[0].data);
   const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
     // text: 'Chart.js Bar Chart',
    },
        legend: {
        display: false, // Hide the legend
      },
  },
};
  return <div style={{  width: '300px', height: '230px' ,}} className='mt-[-70px] sm:mt-10 md:mt-10'>
          <div className='flex flex-col space-y-3 mb-3 '>
<span className='text-[#c9c9c9] '>Data Object Distribution </span>
<span className='font-bold'>{highestValue}</span>
</div>
    <Doughnut data={data} options={options} />
    </div>
}
