import React from "react";
const variantClasses = {
  h1: "font-bold md:text-[48px] sm:text-[48px] text-[90px]",
  h2: "md:text-[48px] sm:text-[48px] text-[60px]",
  h3: "font-medium sm:text-[38px] md:text-[44px] text-[52px]",
  h4: "font-medium text-[5.07px]",
  h5: "font-bold sm:text-[15px] md:text-[20px] text-[40px]",
  h6: "font-medium text-[4.33px]",
  body1: "font-semibold sm:text-[28px] md:text-[30px] text-[32px]",
  body2: "font-semibold sm:text-[24px] md:text-[26px] text-[28px]",
  body3: "font-semibold sm:text-[20px] md:text-[22px] text-[24px]",
  body4: "text-[20px]",
  body5: "font-medium text-[18px]",
  body6: "text-[17px]",
  body7: "font-semibold text-[16px]",
  body8: "font-normal text-[15px]"
};
const Text = ({ children, className, variant, as, ...restProps }) => {
  const Component = as || "span";
  return (
    <Component
      className={`${className} ${variantClasses[variant]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};
export { Text };
