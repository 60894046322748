import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="dhiwise-navigation">
      <h1>Homepage</h1>
      <p className="headline">
        This project was generated By{" "}
        <a href="https://www.dhiwise.com">Dhiwise</a>. Quickly use below links
        to navigate through all pages.
      </p>
      <ul>
        <li>
          <Link to="/">Homepage</Link>
        </li>
        <li>
          <Link to="/createtem">200Createtem</Link>
        </li>
        <li>
          <Link to="/latestnewsone">300LatestnewsOne</Link>
        </li>
        <li>
          <Link to="/latestnews">300Latestnews</Link>
        </li>
        <li>
          <Link to="/ourteam">400Ourteam</Link>
        </li>
        <li>
          <Link to="/datacatalog">100DataCatalog</Link>
        </li>
        <li>
          <Link to="/datacatalog">200DataCatalog</Link>
        </li>
        <li>
          <Link to="/uploaddata">200Uploaddata</Link>
        </li>
        <li>
          <Link to="/eventmoni">300Eventmoni</Link>
        </li>
        <li>
          <Link to="/datacatalog">400Datacatalog</Link>
        </li>
        <li>
          <Link to="/impressum">Impressum</Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
