
export function handleNavigateDashboard() {
  window.location.href = "/dashboard";
}

export function handleNavigateDataCatalog() {
  window.location.href = "/datacatalog";
}

export function handleNavigateUploadData() {
  window.location.href = "/uploaddata";
}

export function handleNavigateEventMonitoring() {
  window.location.href = "/eventmoni";
}

export function handleNavigateWorkspace() {
  window.location.href = "/workspace";
}

export function handleNavigateSwagger() {
  window.location.href = "/swagger";
}

export function handleNavigateSettings() {
  window.location.href = "/settings";
}

export function handleNavigateSecurity() {
  window.location.href = "/security";
}

export function handleNavigatePaymentSettings() {
  window.location.href = "/financial";
}

export function handleNavigateSupport() {
  window.location.href = "/support";
}