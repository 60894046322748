import axios from "axios";
import { Auth } from "aws-amplify";

export async function prepareHeaderInstance() {
  const token = await getValidToken();
  const username = localStorage.getItem('username');

  const instance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      'username': username,
    },
  });
  return instance;
}

export async function getValidToken() {
  const expiration = localStorage.getItem('token_expiration');
  const now = new Date().getTime();

  if (!expiration || now >= parseInt(expiration, 10)) {
    await getUserInfo();
  }

  return localStorage.getItem('token');
}

export async function getUserInfo() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    let token = user.signInUserSession.idToken.jwtToken;

    localStorage.setItem('token', token);

    const expiration = user.signInUserSession.idToken.payload.exp * 1000;
    localStorage.setItem('token_expiration', expiration);
    return {
      username: user.username,
      email: user.attributes.email,
      phone: user.attributes.phone_number,
      name: user.attributes.name,
    };
  } catch (error) {
    console.log('Error getting user info: ', error);
  }
}
