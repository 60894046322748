import React from "react";
import { Button, Img, List, Text } from "components";
const CreatetemPage = () => {
  return (<>
    <List
      className="bg-white_A700 flex-col font-montserrat md:gap-[40px] sm:gap-[40px] gap-[90px] grid mx-[auto] pt-[50px] w-[100%]"
      orientation="vertical"
    >
      <div
        className="flex flex-col md:gap-[40px] sm:gap-[40px] gap-[90px] items-center justify-start mx-[320px] my-[0] md:px-[20px] sm:px-[20px] md:w-[100%] sm:w-[100%] w-[67%]"
      >
        <header
          className="flex items-center justify-center w-[100%]"
        >
          <div
            className="flex flex-1 md:flex-col sm:flex-col flex-row md:gap-[20px] sm:gap-[20px] items-center justify-center w-[100%]"
          >
            <div
              className="h-[49px] md:h-[auto] sm:h-[auto] relative md:w-[100%] sm:w-[100%] w-[11%]"
            >
              <div
                className="absolute flex flex-col h-[max-content] inset-y-[0] items-center justify-start my-[auto] right-[0] w-[80%]"
              >
                <div
                  className="flex flex-row gap-[5px] items-start justify-between w-[100%]"
                >
                  <div
                    className="flex flex-col justify-start mb-[2px] w-[auto]"
                  >
                    <ul
                      className="flex flex-row sm:hidden items-start justify-start md:w-[100%] sm:w-[100%] w-[50%] common-row-list"
                    >
                      <li className="sm:w-[100%] sm:my-[10px] w-[25%]"><Img
                        src=
                          "images/img_vector.svg"
                        className="h-[11px]"
                        alt="Vector"
                      /></li>
                      <li className="mt-[3px] mb-[1px] ml-[1px] sm:w-[100%] sm:my-[10px] w-[25%]"><Img
                        src=
                          "images/img_contrast.svg"
                        className="h-[8px]"
                        alt="contrast"
                      /></li>
                      <li className="ml-[1px] sm:w-[100%] sm:my-[10px] w-[10%] my-[1px]"><Img
                        src=
                          "images/img_vector_blue_500.svg"
                        className="h-[10px]"
                        alt="Vector One"
                      /></li>
                      <li className="mt-[3px] mb-[1px] ml-[1px] sm:w-[100%] sm:my-[10px] w-[25%]"><Img
                        src=
                          "images/img_contrast.svg"
                        className="h-[8px]"
                        alt="contrast One"
                      /></li>
                    </ul>
                    <div
                      className="flex flex-col items-start justify-start md:ml-[0] sm:ml-[0] ml-[10px] md:w-[100%] sm:w-[100%] w-[83%]"
                    >
                      <div
                        className="flex flex-row gap-[6px] items-start justify-between w-[100%]"
                      >
                        <div
                          className="bg-blue_500 h-[25px] w-[auto]"
                        ></div>
                        <Img
                          src=
                            "images/img_lock.svg"
                          className="h-[27px] w-[auto]"
                          alt="lock"
                        /></div>
                      <div
                        className="flex flex-row gap-[19px] items-center justify-between md:w-[100%] sm:w-[100%] w-[95%]"
                      ><Text
                        className="text-blue_500 text-left w-[auto]"
                        variant="body2"
                      >MAKE</Text><Text
                        className="text-blue_500 text-left w-[auto]"
                        variant="body2"
                      >ATA</Text></div>
                    </div>
                  </div>
                  <div
                    className="md:h-[40px] sm:h-[40px] h-[45px] mt-[3px] relative w-[auto]"
                  ><Text
                    className="absolute bottom-[4%] right-[11%] text-blue_500 text-left w-[auto]"
                    variant="body2"
                  >SIMPLE</Text>
                    <div
                      className="absolute bg-blue_500 h-[35px] right-[0] top-[0] w-[16%]"
                    ></div>
                    <Img
                      src=
                        "images/img_contrast_blue_500.svg"
                      className="absolute bottom-[0] h-[37px] left-[0] w-[auto]"
                      alt="contrast Two"
                    /></div>
                </div>
              </div>
              <div
                className="absolute bottom-[6%] h-[40px] md:h-[auto] sm:h-[auto] left-[0] w-[27%]"
              ><Img
                src=
                  "images/img_vector_14x24.png"
                className="absolute h-[14px] inset-y-[0] my-[auto] object-cover right-[0] w-[auto]"
                alt="Vector Four"
              /><Img
                src=
                  "images/img_vector_40x22.png"
                className="absolute h-[40px] inset-y-[0] left-[0] my-[auto] object-cover w-[auto]"
                alt="Vector Five"
              /></div>
            </div>
            <div
              className="flex md:flex-1 sm:flex-1 md:flex-col sm:flex-col flex-row font-poppins gap-[45px] items-start justify-start md:ml-[0] sm:ml-[0] ml-[130px] md:w-[100%] sm:w-[100%] w-[auto]"
            ><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Our ambition</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Problem</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Benefits</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Business Case</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Trust</Text><Text
              className="font-medium text-bluegray_902 text-left w-[auto]"
              variant="body13"
            >Career</Text></div>
            <div
              className="flex flex-row font-poppins gap-[20px] items-center justify-start md:ml-[0] sm:ml-[0] ml-[85px] w-[auto]"
            ><Img
              src=
                "images/img_location.svg"
              className="h-[32px] w-[32px]"
              alt="location"
            /><Button
              className="cursor-pointer font-medium min-w-[170px] text-[17px] text-bluegray_902 text-center tracking-ls034 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              shape="RoundedBorder23"
              size="2xl"
              variant="OutlineBlue500"
            >Get started</Button></div>
          </div>
        </header>
        <div
          className="flex flex-col gap-[30px] items-start justify-start md:w-[100%] sm:w-[100%] w-[auto]"
        ><Text
          className="font-bold font-poppins text-gray_900 text-left tracking-ls06 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
          as="h5"
          variant="h5"
        >Impressum</Text><Text
          className="font-light font-rubik leading-[170.00%] text-gray_901 text-left"
          variant="body13"
        >Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
          Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1
          TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
          sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen
          zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
          Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.<br />Haftung für Links</Text><Text
          className="font-bold font-poppins text-gray_900 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
          variant="body5"
        >Registereintrag:</Text><Text
          className="font-light font-rubik leading-[170.00%] text-gray_901 text-left"
          variant="body13"
        ><span className="text-gray_901 text-[17px] font-bold">Eintragung im Registergericht:</span><span
          className="text-gray_901 text-[17px]"> Mannheim<br /></span><span
          className="text-gray_901 text-[17px] font-bold">Registernummer:</span><span
          className="text-gray_901 text-[17px]"> HRB 745395</span></Text><Text
          className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[1280px] text-gray_901 text-left"
          variant="body13"
        >Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
          Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
          des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
          Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
          dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
          werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
          aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
          wir derartige Inhalte umgehend entfernen.</Text><Text
          className="font-bold font-poppins text-gray_900 text-left tracking-ls032 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
          variant="body7"
        >Datenschutz</Text><Text
          className="font-light font-rubik leading-[170.00%] md:max-w-[100%] sm:max-w-[100%] max-w-[1280px] text-gray_901 text-left"
          variant="body13"
        >Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren
          Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt
          dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung
          nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
          Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
          durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten
          durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird
          hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</Text></div>
      </div>
      <footer
        className="bg-gray_900 flex font-poppins items-center justify-center my-[0] w-[100%]"
      >
        <div
          className="flex flex-col items-center justify-center w-[67%]"
        >
          <div
            className="flex flex-col gap-[50px] items-center justify-center w-[100%]"
          >
            <div
              className="flex md:flex-col sm:flex-col flex-row md:gap-[20px] sm:gap-[20px] items-center justify-start w-[100%]"
            >
              <div
                className="font-montserrat h-[49px] md:h-[auto] sm:h-[auto] relative md:w-[100%] sm:w-[100%] w-[11%]"
              >
                <div
                  className="absolute flex flex-col h-[max-content] inset-y-[0] items-center justify-start my-[auto] right-[0] w-[80%]"
                >
                  <div
                    className="flex flex-row gap-[5px] items-start justify-between w-[100%]"
                  >
                    <div
                      className="flex flex-col justify-start mb-[2px] w-[auto]"
                    >
                      <div
                        className="flex flex-row items-start justify-start md:w-[100%] sm:w-[100%] w-[50%]"
                      ><Img
                        src=
                          "images/img_vector.svg"
                        className="h-[11px] w-[auto]"
                        alt="Vector One"
                      /><Img
                        src=
                          "images/img_contrast.svg"
                        className="h-[8px] mt-[3px] w-[8px]"
                        alt="contrast One"
                      /><Img
                        src=
                          "images/img_vector_blue_500.svg"
                        className="h-[10px] w-[auto]"
                        alt="Vector One One"
                      /><Img
                        src=
                          "images/img_contrast.svg"
                        className="h-[8px] mt-[3px] w-[8px]"
                        alt="contrast One One"
                      /></div>
                      <div
                        className="flex flex-col items-start justify-start md:ml-[0] sm:ml-[0] ml-[10px] md:w-[100%] sm:w-[100%] w-[83%]"
                      >
                        <div
                          className="flex flex-row gap-[6px] items-start justify-between w-[100%]"
                        >
                          <div
                            className="bg-blue_500 h-[25px] w-[auto]"
                          ></div>
                          <Img
                            src=
                              "images/img_lock.svg"
                            className="h-[27px] w-[auto]"
                            alt="lock One"
                          /></div>
                        <div
                          className="flex flex-row gap-[19px] items-center justify-between md:w-[100%] sm:w-[100%] w-[95%]"
                        ><Text
                          className="text-blue_500 text-left w-[auto]"
                          variant="body2"
                        >MAKE</Text><Text
                          className="text-blue_500 text-left w-[auto]"
                          variant="body2"
                        >ATA</Text></div>
                      </div>
                    </div>
                    <div
                      className="md:h-[40px] sm:h-[40px] h-[45px] mt-[3px] relative w-[auto]"
                    ><Text
                      className="absolute bottom-[4%] right-[11%] text-blue_500 text-left w-[auto]"
                      variant="body2"
                    >SIMPLE</Text>
                      <div
                        className="absolute bg-blue_500 h-[35px] right-[0] top-[0] w-[16%]"
                      ></div>
                      <Img
                        src=
                          "images/img_contrast_blue_500.svg"
                        className="absolute bottom-[0] h-[37px] left-[0] w-[auto]"
                        alt="contrast Two One"
                      /></div>
                  </div>
                </div>
                <div
                  className="absolute bottom-[6%] h-[40px] md:h-[auto] sm:h-[auto] left-[0] w-[27%]"
                ><Img
                  src=
                    "images/img_vector_14x24.png"
                  className="absolute h-[14px] inset-y-[0] my-[auto] object-cover right-[0] w-[auto]"
                  alt="Vector Four One"
                /><Img
                  src=
                    "images/img_vector_40x22.png"
                  className="absolute h-[40px] inset-y-[0] left-[0] my-[auto] object-cover w-[auto]"
                  alt="Vector Five One"
                /></div>
              </div>
              <div
                className="flex md:flex-1 sm:flex-1 md:flex-col sm:flex-col flex-row font-poppins gap-[45px] items-start justify-start md:ml-[0] sm:ml-[0] ml-[114px] md:w-[100%] sm:w-[100%] w-[auto]"
              ><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Our ambition</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Problem</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Benefits</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Business Case</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Trust</Text><Text
                className="font-medium text-left text-white_A700 w-[auto]"
                variant="body13"
              >Career</Text></div>
              <Button
                className="cursor-pointer font-medium font-poppins min-w-[231px] md:ml-[0] sm:ml-[0] ml-[85px] text-[15px] text-center text-white_A700 tracking-ls03 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                shape="RoundedBorder23"
                size="3xl"
                variant="OutlineGray9003d"
              >Get free assessment</Button></div>
            <div
              className="flex sm:flex-col flex-row md:gap-[40px] sm:gap-[40px] items-center justify-between w-[100%]"
            ><Text
              className="font-normal not-italic text-left text-white_A700 w-[auto]"
              variant="body15"
            ><span className="text-white_A700 text-[15px] font-poppins">© 2022. </span><span
              className="text-white_A700 text-[15px] font-inter font-semibold">Data simpl</span><span
              className="text-white_A700 text-[15px] font-poppins">. All righ</span><span
              className="text-white_A700 text-[15px] font-poppins">t</span><span
              className="text-white_A700 text-[15px] font-poppins">s reserved.</span></Text>
              <div
                className="flex flex-row gap-[40px] items-start justify-start w-[auto]"
              ><Text
                className="font-normal not-italic text-left text-white_A700 w-[auto]"
                variant="body15"
              >Privacy policy</Text><Text
                className="font-normal not-italic text-left text-white_A700 w-[auto]"
                variant="body15"
              >Terms & Conditions</Text></div>
            </div>
          </div>
        </div>
      </footer>
    </List>
  </>);
};
export default CreatetemPage;
