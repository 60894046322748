import React, { useState, useEffect } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { BlueNavigationBar } from "../../components/NewBlueNavigationBarLeft/navigationBox";
import Header from "../../components/NewHeader/Header";
import { prepareHeaderInstance } from "../../components/userAuth";
import ErrorModal from "../../components/ErrorModal/errorModal";
import Dashboard from "./Dashboard";

const baseurl = process.env.REACT_APP_BASE_URL;

const AllDashboards = () => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorNoDashboard, setShowErrorNoDashboard] = useState(false);
  const [dataObjects, setDataObjects] = useState([]);

  const fetchData = async () => {
    const url = `${baseurl}overviewDash`;
    try {
      const axiosInstance = await prepareHeaderInstance();
      const response = await axiosInstance.get(url);
      setDataObjects(response.data);
      if (response.data.length === 0) {
        setShowErrorNoDashboard(true);
      }
    } catch (error) {
      console.error('A problem occurred while reading the data:', error);
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="A problem occurred while reading the data. Please reload the page or check back later."
      />
      <ErrorModal
        isOpen={showErrorNoDashboard}
        onClose={() => setShowErrorNoDashboard(false)}
        message="No generated dashboards found. Please check back later."
      />
      <div className="flex h-screen">
        <BlueNavigationBar />
        <div className="flex-1">
          <div className="mb-[20px]">
            <Header title="Data Simpl" subtitle="Welcome to" />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row flex-wrap ml-2">
              {dataObjects.length > 0 ? (
                <div className="ml-2">
                  {dataObjects.map((dataObject, index) => {
                    const dashboardConfig = dataObject.dashboard;
                    const dataObj = dataObject.data;
                    return (
                      dashboardConfig && dataObj && dataObj.length > 0 && (
                        <Dashboard key={index} dashboardConfig={dashboardConfig} dataObj={dataObj} />
                      )
                    );
                  })}
                </div>
              ) : !showErrorNoDashboard && !showErrorModal &&(
                <div className="text-center w-full">No dashboards available.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthenticator(AllDashboards, { hideSignUp: true });
